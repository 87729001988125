import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store/root";

const userState = (state: RootState) => state.user;

const selectUser = createSelector([userState], ({ name, lastName, role, email, status }) => ({
  name,
  lastName,
  role,
  email,
  status,
}));

const selectUserActivityStatus = createSelector([userState], ({ status }) => status);

const selectUserLoading = createSelector([userState], ({ loading }) => loading);

export { selectUser, selectUserActivityStatus, selectUserLoading };
