/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { selectCompany } from "store/company/selectors";
import ChatInterfaceIcons from "./ChatInterfaceIcons";
import ChatInterfaceMessages from "./ChatInterfaceMessages";
import ChatInterfaceInput from "./ChatInterfaceInput";
import useChatInterfaceWebsocket from "./useChatWebsocket";

const ChatInterface = ({
  selectedUser,
  setSelectedUser,
  setKeys,
  userId,
  setChats,
  setActiveChatId,
  activeChatId,
  setUserInfo,
  chats,
  userInfo,
}) => {
  const { t } = useTranslation("omnichannel");
  const requestData = useAppSelector(selectInitData);
  const { settings } = useAppSelector(selectCompany);
  const [messages, setMessages] = useState([]);
  const [agentChat, setAgentChat] = useState(false);
  const [read, setRead] = useState(true);
  const [resolved, setResolved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [muted, setMuted] = useState(false);

  const [elapsedTime, setElapsedTime] = useState("00:00:00");

  const formatElapsedTime = ms => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    let interval;
    if (messages.length > 0 && messages[0].timestamp) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const timeDifference = now - new Date(messages[0].timestamp).getTime();
        setElapsedTime(formatElapsedTime(timeDifference));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [messages]);

  const iconConfig = [{ type: "resolve", disabled: resolved }, { type: "mute" }];

  const handleNewMessage = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newMessage = JSON.parse(stringData);
    newMessage.key_entity = null;

    if (newMessage.backend_user_id === selectedUser && !resolved && newMessage.type !== "admin") {
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setRead(false);
    }
  };

  const handleNewUserChat = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newChat = JSON.parse(stringData);

    const chatWithStatus = {
      ...newChat,
      status: "bot",
    };

    setChats(prevChats => [chatWithStatus, ...prevChats]);
  };

  const handleNewKeyEntity = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newKeyEntity = JSON.parse(stringData);

    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.message_id === newKeyEntity.message_id ? { ...message, key_entity: newKeyEntity.key_entity } : message,
      ),
    );
  };

  const handleNewUserName = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newName = JSON.parse(stringData);

    // eslint-disable-next-line eqeqeq
    if (newName.backend_user_id == selectedUser) {
      setUserInfo(prevState => ({
        ...prevState,
        name: newName.name,
      }));
    }
  };

  const handleNewUserPhone = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newPhone = JSON.parse(stringData);

    // eslint-disable-next-line eqeqeq
    if (newPhone.backend_user_id == selectedUser) {
      setUserInfo(prevState => ({
        ...prevState,
        phone: newPhone.phone,
      }));
    }
  };

  const handleNewUserDescription = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newDescription = JSON.parse(stringData);

    // eslint-disable-next-line eqeqeq
    if (newDescription.backend_user_id == selectedUser) {
      setUserInfo(prevState => ({
        ...prevState,
        description: newDescription.description,
      }));
    }
  };

  const handleNewUserChatStatus = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newStatus = JSON.parse(stringData);

    setChats(prevChats =>
      prevChats.map(chat => {
        if (chat.backend_user_id === newStatus.backend_user_id) {
          return { ...chat, status: newStatus.status };
        }
        return chat;
      }),
    );

    if (newStatus.backend_user_id !== selectedUser) return;

    if (newStatus.status === "bot") {
      setAgentChat(false);
    } else if (newStatus.status === "admin") {
      setAgentChat(true);
    } else if (newStatus.status === "resolved") {
      setAgentChat(false);
      setResolved(true);
    }
  };

  console.log("this is selected user", selectedUser);

  const handleNewUserChatTopic = event => {
    const uint8Array = new Uint8Array(event);
    const stringData = new TextDecoder().decode(uint8Array);
    const newTopic = JSON.parse(stringData);

    setChats(prevChats =>
      prevChats.map(chat => {
        if (chat.user_chat_id === parseInt(newTopic.user_chat_id, 10)) {
          return {
            ...chat,
            main_topic: newTopic.main_topic,
            sub_topic: newTopic.sub_topic,
          };
        }
        return chat;
      }),
    );
  };

  const handlers = {
    new_message: handleNewMessage,
    new_user_chat: handleNewUserChat,
    new_key_entity: handleNewKeyEntity,
    new_user_name: handleNewUserName,
    new_user_phone: handleNewUserPhone,
    new_user_description: handleNewUserDescription,
    new_user_chat_status: handleNewUserChatStatus,
    new_user_chat_topic: handleNewUserChatTopic,
  };

  useChatInterfaceWebsocket(handlers, resolved, selectedUser);

  const setStatus = chat => {
    if (chat.status === "bot") {
      setAgentChat(false);
      setResolved(false);
    } else if (chat.status === "admin") {
      setAgentChat(true);
      setResolved(false);
    } else if (chat.status === "resolved") {
      setAgentChat(false);
      setResolved(true);
    }
  };

  const fetchChats = useCallback(async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "RTNO-COMPANY-ID": requestData.companyId,
          "RTNO-ADMIN-UUID": requestData.userId,
        },
      };
      const response = await axios.get(
        `${host}api/v1/dashboard/fetch_user_chats/?backend_user_id=${selectedUser}`,
        config,
      );

      setChats(response.data.slice(0, -1).map(chat => chat));
      setRead(response.data[0].read);
      setActiveChatId(response.data[0].user_chat_id);
      setStatus(response.data[0]);
      setMuted(response.data[1].muted);
      const responseFromServer = response.data[response.data.length - 1];

      if (!responseFromServer.name) responseFromServer.name = "Имя";

      setUserInfo(responseFromServer);
    } catch (error) {
      console.error("Error fetching chats", error);
    }
  }, [requestData, selectedUser, setActiveChatId, setChats, setUserInfo]);

  const fetchMessages = useCallback(
    async id => {
      try {
        setLoading(true);
        const config = {
          headers: {
            "RTNO-COMPANY-ID": requestData.companyId,
            "RTNO-ADMIN-UUID": requestData.userId,
          },
        };
        const response = await axios.get(`${host}api/v1/dashboard/fetch_user_chat/?user_chat_id=${id}`, config);

        const activeChat = chats.find(chat => chat.user_chat_id === activeChatId);
        if (activeChat) setStatus(activeChat);

        setMessages(response.data);
        setKeys(response.data.filter(msg => msg.type === "user").map(msg => msg.key_entity));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching new chat data:", error);
      }
    },
    [activeChatId, chats, requestData, setKeys],
  );

  useEffect(() => {
    setMessages([]);
    if (selectedUser && requestData.companyId) {
      fetchChats();
    }
  }, [selectedUser, requestData.companyId, fetchChats]);

  useEffect(() => {
    setAgentChat(false);
  }, [selectedUser]);

  useEffect(() => {
    if (activeChatId) {
      fetchMessages(activeChatId);

      const activeChat = chats.find(chat => chat.user_chat_id === activeChatId);
      if (activeChat && activeChat.status !== "resolved") {
        setResolved(false);
      }
    }
  }, [activeChatId, chats, fetchMessages]);

  return (
    <div className="bg-white p-4 h-[90vh] flex flex-col shadow-lg">
      <div className="flex justify-between items-center bg-gray-50 p-3 rounded-lg shadow-inner">
        <div className="flex items-center">
          <button
            onClick={() => setSelectedUser(null)}
            className="rounded-full bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300 p-3 transform transition-all duration-300 hover:shadow-lg hover:rotate-90 mr-4"
          >
            <MdClose size={24} className="text-white" />
          </button>
          {!resolved && (
            <div className="text-sm group relative">
              <span
                className="absolute -top-10 left-0 text-xs 
    opacity-0 transition-all duration-500 
    group-hover:-top-10  group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
              >
                {t("chatInterface.duration")}
              </span>
              <span className="font-semibold">{elapsedTime}</span>
            </div>
          )}
        </div>

        <ChatInterfaceIcons
          config={iconConfig}
          setResolved={setResolved}
          selectedUser={selectedUser}
          setAgentChat={setAgentChat}
          read={read}
          chats={chats}
          activeChatId={activeChatId}
          setRead={setRead}
          resolved={resolved}
          agentChat={agentChat}
          muted={muted}
          setMuted={setMuted}
        />
      </div>
      <div className="mt-1 -mb-3 text-sm font-semibold">
        {t("chatInterface.admin")}: {chats.find(item => item.user_chat_id === activeChatId)?.email || "отсутствует"}
      </div>

      <ChatInterfaceMessages
        loading={loading}
        messages={messages}
        agentChat={agentChat}
        activeChatId={activeChatId}
        userInfo={userInfo}
      />

      {settings.version === 3 && (
        <ChatInterfaceInput
          agentChat={agentChat}
          setAgentChat={setAgentChat}
          selectedUser={selectedUser}
          userId={userId}
          resolved={resolved}
          setMessages={setMessages}
        />
      )}
    </div>
  );
};

export default ChatInterface;
