import axios from "axios";
import { BotEmogis, BotLanguage, BotPersonality } from "entities/config";
import { host } from "../../constants";

// ! This variable is not used in all project.
const initVersion = 3;

type CreateCompanyRequest = {
  companyId: string;
  name: string;
  description: string;
  operatorFirstMessage: string;
  moderationPolitics: number[];
};

const createCompany = async ({
  companyId,
  name,
  description,
  operatorFirstMessage,
  moderationPolitics,
}: CreateCompanyRequest) => {
  const data = {
    postgreSQL_db_name: `${companyId}_db`,
    company_name: companyId,
    prompts: [name, description, BotPersonality.FRIENDLY, BotLanguage.RUSSIAN, BotEmogis.YES],
    operator_first_message: operatorFirstMessage || "Привет, я {NAME}",
    moderation_politics: [moderationPolitics.slice(0, 2), moderationPolitics.slice(2, 4)],
    type_of_memory: initVersion >= 2 ? "with_memory_reduced_output" : "with_memory_summary",
    is_valid: true,
    key_entity: false,
    keywords: [],
    token_limit: 400,
    messages_limit: 1000,
    admin_rate: 10,
    kb_object: {
      Company: {},
    },
    need_phone: true,
    memory_timeout: 1800,
    show_operator_call: true,
    chats_per_hour_current: 800,
    chats_per_hour_quota: 800,
  };

  return axios.post(`${host}api/v1/config/create/`, data);
};

export default createCompany;
