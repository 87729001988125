import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { configApi } from "api";
import { selectIsCompanyValid } from "store/auth/selectors";
import { useAppSelector } from "utils/hooks";
import { Tooltip, Alert } from "components";

const Integrations = () => {
  const { t } = useTranslation("profile");
  const [telegramApiKey, setTelegramApiKey] = useState("");
  const [badKeyAlert, setBadKeyAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);

  const getTelegramApiKey = useCallback(async () => {
    try {
      const { data } = await configApi.getTelegramApiKey();
      setTelegramApiKey(data.tgApiKey);
    } catch (error) {
      console.error("get telegram api key error:", { error });
    }
  }, []);

  const updateTelegramApiKey = async () => {
    const regex = /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/;

    if (!regex.test(telegramApiKey)) {
      setBadKeyAlert(true);
      return;
    }

    try {
      await configApi.updateTelegramApiKey(telegramApiKey);
      setSuccessAlert(true);
    } catch (error) {
      console.error("update telegram api key error:", { error });
    }
  };

  useEffect(() => {
    if (isCompanyValid) {
      getTelegramApiKey();
    }
  }, [getTelegramApiKey, isCompanyValid]);

  return (
    <div className="flex flex-col items-center justify-center bg-white shadow-md rounded-3xl p-6 m-4 w-full max-w-screen-lg">
      <div className="w-full">
        <h2 className="text-left font-semibold text-lg mb-5">{t("integrations.integrations")}</h2>
        <div className="grid grid-cols-2 gap-4">
          <Tooltip message="Telegram API key" description={t("integrations.telegramDescription")} />
          <div className="flex items-center">
            <input
              id="companyName"
              className="appearance-none border text-sm flex-shrink-0 w-4/5 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
              type="text"
              placeholder=""
              value={telegramApiKey}
              onChange={e => setTelegramApiKey(e.target.value)}
            />
            <button
              disabled={!telegramApiKey || !isCompanyValid}
              onClick={updateTelegramApiKey}
              className="w-full disabled:text-gray-400 hover:ml-3 hover:text-other-blue text-main-blue hover:scale-110 hover:-rotate-2 transition-all duration-500 ease-in-out"
            >
              <BsFillArrowRightSquareFill size={25} />
            </button>
          </div>
        </div>
      </div>
      <Alert
        message={t("integrations.wrongKeyAlert")}
        color="bg-red-200"
        alert={badKeyAlert}
        setAlert={setBadKeyAlert}
      />
      <Alert message={t("integrations.successAlert")} alert={successAlert} setAlert={setSuccessAlert} />
    </div>
  );
};

export default Integrations;
