import React from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const Modal = ({ onClose, open, children, onConfirm, topMessage, action }) => {
  const { t } = useTranslation("helper");
  return (
    <div
      className={`z-[1000000] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-sm my-3 overflow-x-auto bg-white rounded-lg shadow-lg ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="flex px-5 py-4 border-b border-gray-200">
        <div className="flex items-center justify-center w-6">
          <svg
            className="w-6 h-6 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <span className="ml-2 text-lg font-bold text-gray-700">{topMessage}</span>
      </div>

      <div className="px-10 py-5 text-gray-600">{children}</div>

      <div className="flex justify-end px-5 py-4 bg-gray-100 border-t border-gray-300">
        <button
          className="px-3 py-2 mr-1 text-sm text-white transition duration-150 bg-red-400 rounded hover:bg-red-600"
          onClick={() => onClose()}
        >
          {t("modal.cancel")}
        </button>
        <button
          className="px-3 py-2 text-sm text-gray-600 transition duration-150 bg-gray-100 rounded hover:text-gray-700"
          onClick={() => onConfirm()}
        >
          {action}
        </button>
      </div>
    </div>
  );
};

export default Modal;
