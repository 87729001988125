/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useMemo, useCallback } from "react";
import Chart from "chart.js";
import { colors1, colors2 } from "./assets/colors";

// eslint-disable-next-line react/prop-types
function DonutChart({ keys, colorNum }) {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const colors = colorNum === 1 ? colors1 : colors2;

  const keysObject = useMemo(() => {
    if (Array.isArray(keys)) {
      return keys.reduce((acc, val) => {
        if (val !== null) {
          acc[val] = acc[val] ? acc[val] + 1 : 1;
        }
        return acc;
      }, {});
    }

    return {};
  }, [keys]);

  const data = useMemo(
    () => ({
      labels: Object.keys(keysObject),
      datasets: [
        {
          data: Object.values(keysObject),
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    }),
    [keysObject],
  );

  // ! Do nothing?

  const handleChartClick = useCallback(
    event => {
      const activePoints = chartRef.current.getElementsAtEvent(event);
      if (activePoints.length > 0) {
        // eslint-disable-next-line no-underscore-dangle
        const clickedElementIndex = activePoints[0]._index;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const clickedElementKey = data.labels[clickedElementIndex];
      }
    },
    [data.labels],
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    data.datasets[0].backgroundColor = colors.slice(0, data.datasets[0].data.length);
    data.datasets[0].hoverBackgroundColor = colors.slice(0, data.datasets[0].data.length);

    const options = {
      cutoutPercentage: 50,
      animation: {
        animateRotate: true,
        animateScale: true,
      },
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 20,
          bottom: 0,
        },
      },
      tooltips: {
        enabled: true,
      },
    };

    const myChart = new Chart(ctx, {
      type: "doughnut",
      data,
      options,
    });
    chartRef.current = myChart;

    canvas.addEventListener("click", handleChartClick);

    return () => {
      myChart.destroy();
      canvas.removeEventListener("click", handleChartClick);
    };
  }, [colors, data, handleChartClick]);

  return <canvas ref={canvasRef} />;
}

export default DonutChart;
