import { createReducer } from "@reduxjs/toolkit";
import { UserActivityStatus, UserRole } from "entities/user";
import { fetchUser, updateUser, setUserActivityStatus } from "./actions";
import { StoreState } from "./types";

const initialState: StoreState = {
  name: "",
  lastName: "",
  email: "",
  role: UserRole.OPERATOR,
  status: UserActivityStatus.ACTIVE,
  loading: false,
};

const userReducer = createReducer(initialState, builder => {
  builder
    .addCase(fetchUser.pending, state => {
      state.loading = true;
    })
    .addCase(fetchUser.fulfilled, (state, action) => {
      const { name, lastName, email, role, status } = action.payload;
      state.name = name;
      state.lastName = lastName;
      state.email = email;
      state.role = role;
      state.status = status;
      state.loading = false;
    })
    .addCase(fetchUser.rejected, state => {
      state.loading = false;
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      const { name, lastName } = action.payload;
      state.name = name;
      state.lastName = lastName;
    })
    .addCase(setUserActivityStatus, (state, action) => {
      state.status = action.payload;
    });
});

export default userReducer;
