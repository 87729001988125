import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { companyApi } from "api";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectInitData, selectIsCompanyValid } from "store/auth/selectors";
import { selectUser } from "store/user/selectors";
import { selectCompany } from "store/company/selectors";
import { updateCompany } from "store/company/actions";
import { validateCompany } from "store/auth/actions";
import { Tooltip, Button } from "components";

const kb_object = {
  Company: {},
};

const CompanyForm = () => {
  const { t } = useTranslation("profile");
  const dispatch = useAppDispatch();
  const [moderationPolitics] = useState([0, 0, 0, 0]);
  // const [keywords] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const { userId, companyId } = useAppSelector(selectInitData);
  const { email } = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);

  const [companyInputValues, setCompanyInputValues] = useState({
    name: company.name,
    description: company.description,
    operatorFirstMessage: company.settings.operatorFirstMessage,
  });

  const createCompany = useCallback(async () => {
    try {
      const response = await companyApi.createCompany({ ...companyInputValues, companyId, moderationPolitics });

      if (response.status === 201) {
        await companyApi.assignAdminToCompany({ userId, email });
        dispatch(validateCompany());
      }
    } catch (error) {
      console.error("create company error", { error });
    }
  }, [companyInputValues, companyId, moderationPolitics, userId, email, dispatch]);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    setSuccess(false);

    try {
      if (isCompanyValid) {
        await dispatch(updateCompany({ ...companyInputValues, companyId, moderationPolitics }));
      } else {
        await createCompany();
      }
      setSuccess(true);
      window.localStorage.setItem("topics", JSON.stringify(kb_object.Company));
    } catch (error) {
      setSuccess(false);
      console.log("submit company form error", { error });
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttonText = useMemo(() => {
    if (isSubmitting)
      return (
        <div className="flex items-center justify-center space-x-2">
          {t("companyForm.wait")}{" "}
          <div className="loader ease-linear ml-2 rounded-full border-2 border-t-2 border-gray-200 h-4 w-4" />
        </div>
      );

    if (isCompanyValid) return t("companyForm.change");
    return t("companyForm.continue");
  }, [isCompanyValid, isSubmitting, t]);

  return (
    <div className="flex flex-col items-center justify-center bg-white shadow-md rounded-3xl p-6 w-full max-w-screen-lg">
      <div className="w-full">
        <h2 className="text-left font-semibold text-lg mb-5">{t("companyForm.mainSettings")}</h2>
        <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
          <Tooltip message={t("companyForm.companyName")} description="Description 1" />
          <input
            id="companyName"
            className="appearance-none border text-sm rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder={t("companyForm.companyName")}
            value={companyInputValues.name}
            onChange={e => setCompanyInputValues(prev => ({ ...prev, name: e.target.value }))}
          />
          <Tooltip message={t("companyForm.description")} description="Description 2" />
          <textarea
            id="description"
            className="appearance-none border text-sm rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder={t("companyForm.description")}
            value={companyInputValues.description}
            onChange={e => setCompanyInputValues(prev => ({ ...prev, description: e.target.value }))}
          />
          <Tooltip
            message={t("companyForm.operatorFirstMessage")}
            description="В любом месте приветственного сообщения, где требуется вставить имя и фамилию оператора, впишите {NAME}"
          />
          <input
            id="operatorFirstMessage"
            className="appearance-none border text-sm rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder={t("companyForm.operatorFirstMessage")}
            value={companyInputValues.operatorFirstMessage}
            onChange={e => setCompanyInputValues(prev => ({ ...prev, operatorFirstMessage: e.target.value }))}
          />

          <div className="col-span-2 text-center my-4 mt-6">
            <Button disabled={isSubmitting} message={buttonText} type="submit" />
            {success && <div className="mt-4 text-green-500">{t("companyForm.success")}</div>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyForm;
