/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWebSocket } from "contexts/WebsocketProvider";
import { uint8ArrayToJson } from "../../../../utils/utils";

const ActiveChats = ({
  loading,
  fetchChatData,
  filteredChats,
  pageSize,
  setLoading,
  selectedUser,
  setSelectedUser,
  setUserId,
  formatDateToMoscowTime,
}) => {
  const { t } = useTranslation("omnichannel");
  const [activeChatData, setActiveChatData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [hasMoreActiveChats, setHasMoreActiveChats] = useState(false);

  const ws = useWebSocket();
  console.log({ activeChatData });

  const fetchActiveChatData = useCallback(async () => {
    try {
      const allChatData = await fetchChatData("active", 1);
      setActiveChatData(allChatData);
      setHasMoreActiveChats(allChatData.length > pageSize);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching all chat data:", error);
    }
  }, [fetchChatData, pageSize, setLoading]);

  const fetchNextPage = async () => {
    try {
      const nextPageChatData = await fetchChatData("active", activePage + 1);
      setActivePage(prevPage => prevPage + 1);
      setActiveChatData(nextPageChatData);
      setHasMoreActiveChats(nextPageChatData.length > pageSize);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPreviousPage = async () => {
    try {
      const nextPageChatData = await fetchChatData("active", activePage - 1);
      console.log({ nextPageChatData });
      setActivePage(prevPage => prevPage - 1);
      setActiveChatData(nextPageChatData);
      setHasMoreActiveChats(nextPageChatData.length > pageSize);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (ws) {
      const newUserListener = event => {
        const newUser = uint8ArrayToJson(event);

        setActiveChatData(prevData => [
          ...prevData,
          {
            backend_user_id: newUser.backend_user_id,
            frontend_user_id: newUser.frontend_user_id,
            last_message: "",
            phone: newUser.phone,
            main_topic: null,
            sub_topic: null,
            last_message_type: "bot",
            status: "bot",
          },
        ]);
      };

      const newUserChatListener = event => {
        const newChatStatus = uint8ArrayToJson(event);
        const exists = activeChatData.find(item => item.backend_user_id === newChatStatus.backend_user_id);

        if (!exists) {
          setActiveChatData(prevChatData => [
            ...prevChatData,
            {
              backend_user_id: newChatStatus.backend_user_id,
              frontend_user_id: newChatStatus.frontend_user_id,
              last_message: newChatStatus.reason_for_admin,
              phone: newChatStatus.phone,
              main_topic: null,
              sub_topic: null,
              timestamp: Date.now(),
              last_message_type: "bot",
              status: "bot",
            },
          ]);
        }
      };

      const newUserChatStatusListener = event => {
        const newChatStatus = uint8ArrayToJson(event);

        if (newChatStatus.status === "resolved") {
          const newChatData = activeChatData.filter(
            item => item.backend_user_id !== parseInt(newChatStatus.backend_user_id, 10),
          );

          setActiveChatData(newChatData);
        }
      };

      ws.on("new_user", newUserListener);
      ws.on("new_user_chat", newUserChatListener);
      ws.on("new_user_chat_status", newUserChatStatusListener);

      return () => {
        ws.off("new_user", newUserListener);
        ws.off("new_user_chat_status", newUserChatStatusListener);
        ws.off("new_user_chat", newUserChatListener);
      };
    }

    return () => {};
  }, [ws, activeChatData]);

  useEffect(() => {
    fetchActiveChatData();
  }, [fetchActiveChatData]);

  return (
    <div>
      {loading ? (
        <div className="p-3 rounded-lg bg-gray-100 animate-pulse">
          <div className="h-5 bg-gray-200 rounded" />
          <div className="mt-2 h-4 bg-gray-300 rounded w-3/4" />
        </div>
      ) : (
        <div className="flex flex-col">
          {filteredChats([...activeChatData])
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map(chat => (
              <div
                key={chat.frontend_user_id}
                className={`relative flex px-2 py-4 space-x-4 rounded-lg cursor-pointer border border-blue-200 ${
                  selectedUser === chat.backend_user_id ? "bg-gradient-to-r from-blue-100 to-blue-50" : "bg-transparent"
                } hover:bg-gradient-to-r hover:from-blue-100 hover:to-blue-50 transition ease-in-out duration-300 mb-4`}
                onClick={() => {
                  setSelectedUser(chat.backend_user_id);
                  setUserId(chat.frontend_user_id);
                }}
              >
                <div className="grid gap-4 grid-cols-12 w-full items-center">
                  <div className="flex flex-col col-span-4">
                    <div className="ml-4">
                      {chat.phone ? chat.phone : `${t("chatList.client")} ${chat.backend_user_id}`}
                    </div>
                  </div>

                  {!selectedUser && (
                    <div className="text-gray-400 text-sm mx-4 col-span-3 truncate whitespace-nowrap">
                      {formatDateToMoscowTime(chat.timestamp)}
                    </div>
                  )}

                  {!selectedUser && (
                    <div className="flex-end text-gray-600 text-right col-span-5 text-sm">
                      {t("chatList.topic")} {chat.main_topic ? chat.main_topic : "---"}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {activePage > 1 && (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 shadow-md"
          onClick={fetchPreviousPage}
          disabled={false}
        >
          предыдущая
        </button>
      )}
      {hasMoreActiveChats && (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 shadow-md"
          onClick={fetchNextPage}
          disabled={false}
        >
          следующая
        </button>
      )}
    </div>
  );
};

export default ActiveChats;
