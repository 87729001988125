import axios from "utils/axios";

const getBotSettings = async () => {
  const params = {
    object_name_list: ["prompts"],
  };

  return axios.get(`api/v1/config/load/`, { params });
};

export default getBotSettings;
