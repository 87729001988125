/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import {
  AiOutlinePlus,
  AiFillFolder,
  AiFillFolderOpen,
  AiFillFile,
  AiOutlineDelete,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { cleanString } from "utils/utils";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

const SidebarItem = ({
  name,
  value,
  level,
  isOpen,
  onToggle,
  setSelectedFile,
  selectedFile,
  setSelectedFileId,
  path,
  onFetch,
  onDelete,
  onSetDescription,
  setDescription,
}) => {
  const { t } = useTranslation("knowledgeBase");
  const isFile = typeof value !== "object" || value === null;
  const isFolder = value !== null && typeof value === "object";
  const { companyId, userId } = useAppSelector(selectInitData);

  const handleDelete = () => {
    if (level === 3) {
      onDelete(name, level, path, value);
    } else {
      onDelete(name, level, path);
    }
  };

  const fetchLabel = useCallback(
    async (path, name) => {
      try {
        const url = `${host}api/v1/dashboard/information/advanced/get_subdomain_labels/?domain=${
          path.split("-")[2]
        }&subdomain=${name}`;
        const config = {
          headers: {
            "RTNO-COMPANY-ID": companyId,
            "RTNO-ADMIN-UUID": userId,
          },
        };

        const { data } = await axios.get(url, config);

        const labelObject = data.reduce((acc, { label, item_id }) => {
          acc[label] = item_id;
          return acc;
        }, {});

        return labelObject;
      } catch (error) {
        return console.error(error);
      }
    },
    [companyId, userId],
  );

  const handleItemClick = async () => {
    if (isFile) {
      if (name === "_description") {
        setSelectedFile(null);
        setSelectedFileId(null);
        setDescription(path);
      } else {
        setDescription(null);
        setSelectedFile(`${path}-${name}`);
        setSelectedFileId(value);
      }
    }

    if (isFolder) {
      if (level === 2 && !isOpen) {
        const newLabels = await fetchLabel(path, name);
        onFetch(name, newLabels);
      }
      onToggle();
    }
  };

  const handleSetDescription = () => {
    const newDescription = prompt(t("kbSidebar.enterDescription"));
    if (newDescription) {
      onSetDescription(name, newDescription, path, level);
    }
  };

  return (
    <div
      style={{ paddingLeft: `${level * 16}px` }}
      className="mt-1 flex items-center group hover:bg-gray-300 p-1 rounded-md cursor-pointer transition duration-300 ease-in-out text-sm"
      onClick={handleItemClick}
    >
      {isFolder && (
        <BsChevronDown className={`mr-2 cursor-pointer flex-shrink-0 transform ${isOpen ? "-rotate-90" : ""}`} />
      )}
      {isFile &&
        (name === "_description" ? (
          <AiOutlineQuestionCircle
            className={`mr-1 flex-shrink-0 ${selectedFile === `${path}-${name}` && "text-main-blue"}`}
          />
        ) : (
          <AiFillFile className={`mr-1 flex-shrink-0 ${selectedFile === `${path}-${name}` && "text-main-blue"}`} />
        ))}
      {isFolder &&
        (isOpen ? (
          <AiFillFolderOpen className="mr-1 flex-shrink-0" />
        ) : (
          <AiFillFolder className="mr-1 flex-shrink-0" />
        ))}
      <span className="truncate ">{name === "_description" ? t("kbSidebar.description") : name}</span>
      {level !== 0 && (
        <>
          {(level === 1 || level === 2) && name !== "_description" && (
            <AiOutlineQuestionCircle
              onClick={event => {
                event.stopPropagation();
                handleSetDescription();
              }}
              className="ml-auto flex-shrink-0 cursor-pointer opacity-0 group-hover:opacity-100 hover:text-main-blue ease-in-out duration-300 transition"
            />
          )}
          <AiOutlineDelete
            onClick={event => {
              event.stopPropagation();
              handleDelete();
            }}
            className="ml-auto flex-shrink-0 cursor-pointer opacity-0 group-hover:opacity-100 hover:text-main-blue ease-in-out duration-300 transition"
          />
        </>
      )}
      <hr className="" />
    </div>
  );
};

const SidebarDomain = ({
  data,
  level = 0,
  onAdd,
  setSelectedFile,
  selectedFile,
  setSelectedFileId,
  selectedFileId,
  path = "",
  onFetch,
  onDelete,
  onSetDescription,
  setDescription,
}) => {
  const { t } = useTranslation("knowledgeBase");
  const [openItems, setOpenItems] = useState({});

  const toggleItem = itemName => {
    setOpenItems(prev => ({
      ...prev,
      [itemName]: !prev[itemName],
    }));
  };

  const title = useMemo(() => {
    if (level === 0) return t("kbSidebar.addDomain");
    if (level === 1) return t("kbSidebar.addSubdomain");
    return t("kbSidebar.addLabel");
  }, [level, t]);

  if (!data) return null;

  return (
    <div className="">
      {Object.keys(data).map(key => (
        <div key={key}>
          <SidebarItem
            name={key}
            value={data[key]}
            level={level}
            onAdd={name => onAdd(name, level, path)}
            isOpen={openItems[key]}
            onToggle={() => toggleItem(key)}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            setSelectedFileId={setSelectedFileId}
            selectedFileId={selectedFileId}
            path={path}
            onFetch={onFetch}
            onDelete={onDelete}
            onSetDescription={onSetDescription}
            setDescription={setDescription}
          />
          {data[key] && openItems[key] && (
            <>
              <SidebarDomain
                data={data[key]}
                level={level + 1}
                onAdd={onAdd}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                setSelectedFileId={setSelectedFileId}
                selectedFileId={selectedFileId}
                path={`${path}-${key}`}
                onFetch={onFetch}
                onDelete={onDelete}
                onSetDescription={onSetDescription}
                setDescription={setDescription}
              />
              <div
                style={{ paddingLeft: `${(level + 1) * 16}px` }}
                onClick={() => onAdd(key, level, `${path}-${key}`)}
                className="mt-1 flex items-center hover:bg-gray-300 p-1 rounded-md cursor-pointer transition duration-300 ease-in-out text-sm opacity-[50%] hover:opacity-100"
              >
                <button className=" text-xs hover:text-main-blue transition ease-in-out duration-300 bg-blue-200 rounded-full p-0.5 hover:bg-white">
                  <AiOutlinePlus />
                </button>
                <span className="ml-2">
                  {title}
                  ...
                </span>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

const KBSidebar = ({
  selectedFile,
  setSelectedFile,
  selectedFileId,
  setSelectedFileId,
  setDescription,
  data,
  setData,
}) => {
  const { t } = useTranslation("knowledgeBase");
  const { companyId, userId } = useAppSelector(selectInitData);

  const removeItem = (currentData, name, level) => {
    // eslint-disable-next-line prefer-const
    for (let key in currentData) {
      if (key === name && level !== 0) {
        delete currentData[key];
        return true;
      }
      if (typeof currentData[key] === "object" && currentData[key]) {
        if (removeItem(currentData[key], name, level)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleDelete = async (name, level, path, value) => {
    const pathParts = path.split("-");
    const currentDomain = pathParts[2];
    let sendData = {};

    let url;
    if (level === 1) {
      url = `${host}api/v1/dashboard/information/advanced/dsl_structure/`;
      sendData = { high_level_name: "Company", domain: name };
    } else if (level === 2) {
      url = `${host}api/v1/dashboard/information/advanced/dsl_structure/`;
      sendData = {
        high_level_name: "Company",
        domain: currentDomain,
        subdomain: name,
      };
    } else if (level === 3) {
      url = `${host}api/v1/dashboard/information/advanced/dsl_item/`;
      sendData = {
        item_id: value,
      };
    }

    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.delete(url, {
        data: JSON.stringify(sendData),
        headers,
      });
      if (response.status === 200 || response.status === 204) {
        const newData = { ...data };
        removeItem(newData, name, level);
        setData(newData);

        setSelectedFile(null);
        setSelectedFileId(null);
      }
    } catch (error) {
      console.error("Failed to delete:", error);
    }
  };

  const sendAddRequest = async (level, newItemName, currentDomain) => {
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };

    if (level === 2) return;

    const url = `${host}api/v1/dashboard/information/advanced/dsl_structure/`;

    const data =
      level === 0
        ? {
            high_level_name: "Company",
            domain: cleanString(newItemName),
          }
        : {
            high_level_name: "Company",
            domain: currentDomain,
            subdomain: cleanString(newItemName),
          };

    try {
      await axios.put(url, data, { headers });
    } catch (error) {
      console.error("Failed to add new item: ", error);
    }
  };

  const doesNameExist = (data, nameToCheck) => {
    if (!data) return false;

    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(nameToCheck)) return true;

    // eslint-disable-next-line prefer-const
    for (let key in data) {
      if (typeof data[key] === "object") {
        if (doesNameExist(data[key], nameToCheck)) return true;
      }
    }

    return false;
  };

  const addNewItem = (currentData, name, newItemName, level, path) => {
    // eslint-disable-next-line prefer-const
    for (let key in currentData) {
      if (key === name) {
        if (level === 2) {
          currentData[key] = { ...currentData[key], [newItemName]: null };
        } else {
          currentData[key][newItemName] = {};
        }
        return true;
      }
      if (typeof currentData[key] === "object" && currentData[key]) {
        const newPath = `${path}-${key}`;
        if (addNewItem(currentData[key], name, newItemName, level, newPath)) {
          return true;
        }
      }
    }
    return false;
  };

  const addNewLabel = (currentData, name, newLabels) => {
    // eslint-disable-next-line prefer-const
    for (let key in currentData) {
      if (key === name) {
        currentData[key] = { ...currentData[key], ...newLabels };
        return true;
      }
      if (typeof currentData[key] === "object" && currentData[key]) {
        if (addNewLabel(currentData[key], name, newLabels)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleAdd = async (name, level, path) => {
    let newItemType = "kbSidebar.enterDomainName";
    if (level === 2) newItemType = "kbSidebar.enterSubdomainName";
    else if (level === 3) newItemType = "kbSidebar.enterLabelName";

    const currentDomain = path.split("-")[2];

    const newItemName = prompt(t(newItemType));
    if (!newItemName) return;

    if (doesNameExist(data, newItemName)) {
      alert(t("kbSidebar.nameExists"));
      return;
    }

    await sendAddRequest(level, newItemName, currentDomain);

    const newData = { ...data };
    addNewItem(newData, name, newItemName, level, path);
    setData(newData);
  };

  const handleFetch = (name, newLabels) => {
    const newData = { ...data };
    addNewLabel(newData, name, newLabels);
    setData(newData);
  };

  const setDescriptionFunc = (currentData, name, newDescription, level, pathParts) => {
    const cleanedPathParts = pathParts.filter(part => part !== "");

    // eslint-disable-next-line prefer-const
    for (let key in currentData) {
      if (key === name) {
        // eslint-disable-next-line no-underscore-dangle
        currentData[key]._description = newDescription;
        return true;
        // eslint-disable-next-line no-else-return
      } else if (typeof currentData[key] === "object" && currentData[key]) {
        if (setDescriptionFunc(currentData[key], name, newDescription, level, cleanedPathParts.slice(1))) {
          return true;
        }
      }
    }
    return false;
  };

  const sendAddDescriptionRequest = async (name, newDescription, level, path) => {
    console.log(path);
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };

    let url = "";
    let data = {};

    if (level === 1) {
      data = {
        high_level_name: "Company",
        domain: name,
        description: newDescription,
      };

      url = `${host}api/v1/dashboard/information/advanced/set_description_for_domain/`;
    } else if (level === 2) {
      const currentDomain = path.split("-")[2];

      data = {
        high_level_name: "Company",
        domain: currentDomain,
        subdomain: name,
        description: newDescription,
      };

      console.log("this is the data for subdomain: ", data);

      url = `${host}api/v1/dashboard/information/advanced/set_description_for_subdomain/`;
    }

    try {
      await axios.put(url, data, { headers });
    } catch (error) {
      console.error("Failed to add new description: ", error);
    }
  };

  const handleSetDescription = async (name, newDescription, path, level) => {
    await sendAddDescriptionRequest(name, newDescription, level, path);

    setData(prevData => {
      const newData = JSON.parse(JSON.stringify(prevData));
      setDescriptionFunc(newData, name, newDescription, level, path.split("-"));
      return newData;
    });
  };

  return (
    <div className="p-4 h-screen max-h-[80%] max-w-sm w-full shadow overflow-y-auto">
      <SidebarDomain
        data={data}
        onAdd={handleAdd}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        setSelectedFileId={setSelectedFileId}
        selectedFileId={selectedFileId}
        path=""
        onFetch={handleFetch}
        onDelete={handleDelete}
        onSetDescription={handleSetDescription}
        setDescription={setDescription}
      />
    </div>
  );
};

export default KBSidebar;
