import React, { useState } from "react";
import Header from "./Header";
import KeyMetrics from "./KeyMetrics";
import ExportReport from "./ExportReport";

const AnalyticsPage = () => {
  const [range, setRange] = useState(7);
  const [adminData] = useState([]);

  return (
    <div className="mb-20">
      <Header range={range} setRange={setRange} />
      <ExportReport adminData={adminData} />
      <KeyMetrics range={range} />
    </div>
  );
};

export default AnalyticsPage;
