import Cookies from "js-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Company } from "entities/company";
import { UserActivityStatus } from "entities/user";
import { AsyncThunkConfig } from "utils/redux";
import axios from "utils/axios";
import { updateUserActivityStatus } from "../user/actions";

const initUserStatus = UserActivityStatus.BUSY;

const fetchCompany = createAsyncThunk<Company, undefined, AsyncThunkConfig>(
  "company/fetchCompany",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`api/v1/config/load/`, {
        params: {
          object_name_list: ["prompts", "operator_first_message"],
        },
      });

      const { prompts, operatorFirstMessage } = response.data;
      const name = prompts[0];
      const description = prompts[1];
      const settings = {
        initUserStatus,
        version: 3,
        operatorFirstMessage,
      };

      // ! I guess it should be moved to the auth. Maybe we need to get the initialization user status from the token, or we can manage it on the backend. If so, we will not need to set it from the frontend side.

      const userStatus = Cookies.get("status");

      if (!userStatus) {
        dispatch(updateUserActivityStatus(initUserStatus));
      }

      return { name, description, settings };
    } catch (e) {
      const error = new Error("Упс! Произошла ошибка во время загрузки компании. Попробуйте позднее.");
      return rejectWithValue(error);
    }
  },
);

type UpdateCompanyRequest = {
  companyId: string;
  name: string;
  description: string;
  operatorFirstMessage: string;
  moderationPolitics: number[];
};

const updateCompany = createAsyncThunk<Company, UpdateCompanyRequest, AsyncThunkConfig>(
  "company/updateCompany",
  async ({ companyId, name, description, operatorFirstMessage, moderationPolitics }, { rejectWithValue }) => {
    try {
      const data = {
        company_name: companyId,
        prompts: [name, description],
        operator_first_message: operatorFirstMessage,
        moderation_politics: [moderationPolitics.slice(0, 2), moderationPolitics.slice(2, 4)],
      };

      await axios.put(`api/v1/config/update/`, data);

      return {
        name,
        description,
        settings: { operatorFirstMessage, version: 3, initUserStatus: UserActivityStatus.ACTIVE },
      };
    } catch (e) {
      const error = new Error("Упс! Произошла ошибка во время загрузки компании. Попробуйте позднее.");
      return rejectWithValue(error);
    }
  },
);

export { fetchCompany, updateCompany };
