import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import axios from "axios";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

const ChatContext = createContext({
  currentlyPlayingAudioId: null,
  setCurrentlyPlayingAudioId: () => {},
  attachments: {},
  setAttachments: () => {},
});

// eslint-disable-next-line react/prop-types
export const ChatContextProvider = ({ children }) => {
  const { companyId, userId } = useAppSelector(selectInitData);
  const [currentlyPlayingAudioId, setCurrentlyPlayingAudioId] = useState(null);
  const [attachments, setAttachments] = useState({});

  const fetchAttachmentLink = useCallback(
    async objectId => {
      try {
        const response = await axios.get(`${host}api/v1/dashboard/get_link_for_yandex_object/`, {
          params: { object_id: objectId },
          headers: {
            "RTNO-COMPANY-ID": companyId,
            "RTNO-ADMIN-UUID": userId,
          },
        });
        const attachment = response.data;
        setAttachments({ ...attachments, [objectId]: attachment });
      } catch (error) {
        setAttachments({ ...attachments, [objectId]: "error" });
        console.error(`Failed to fetch image link for ${objectId}`, error);
      }
    },
    [attachments, companyId, userId],
  );

  const values = useMemo(
    () => ({
      currentlyPlayingAudioId,
      setCurrentlyPlayingAudioId,
      attachments,
      onLoadAttachment: fetchAttachmentLink,
    }),
    [currentlyPlayingAudioId, attachments, fetchAttachmentLink],
  );

  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => useContext(ChatContext);
