import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AsyncThunkConfig } from "utils/redux";
import { selectInitData } from "../auth/selectors";
import { host } from "../../constants";
import { NestedObject } from "./types";

type FetchKBPayload = {
  object: NestedObject;
};

const fetchKB = createAsyncThunk<FetchKBPayload, undefined, AsyncThunkConfig>(
  "kb/fetchKB",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { userId, companyId } = selectInitData(getState());

      const headers = {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      };

      const params = {
        object_name_list: ["kb_object"],
      };

      const response = await axios.get(`${host}api/v1/config/load/`, {
        params,
        headers,
      });

      const { kb_object } = response.data;

      return { object: kb_object };
    } catch (e) {
      const error = new Error("Упс! Произошла ошибка во время загрузки компании. Попробуйте позднее.");
      return rejectWithValue(error);
    }
  },
);

const setKB = createAction<FetchKBPayload>("kb/setKB");

export { fetchKB, setKB };
