export enum BotPersonality {
  FRIENDLY = "kind and friendly",
  FUNNY = "funny and sarcastic",
  FORMAL = "formal and blunt",
  INTELLECTUAL = "highly intellectual and straightforward",
  RUDE = "rude and arrogant",
}

export enum BotPersonalityRU {
  FRIENDLY = "Дружелюбный",
  FUNNY = "Смешной",
  FORMAL = "Формальный",
  INTELLECTUAL = "Интеллектуальный",
  RUDE = "Грубый",
}

export enum BotLanguageRU {
  ENGLISH = "Русский",
  RUSSIAN = "Английский",
}

export enum BotEmogisRU {
  NO = "Нет",
  YES = "Да",
}

export enum BotEmogis {
  NO = "no",
  YES = "USE A LOT OF EMOJIS EVERYWHERE YOU CAN",
}

export enum BotLanguage {
  ENGLISH = "en",
  RUSSIAN = "ru",
}

export type BotSettings = {
  personality: BotPersonality;
  language: BotLanguage;
  useEmojis: BotEmogis;
};

export type ExtraSettings = {
  needPhone: boolean;
  memoryTimeout: number;
  needResolveVerification: boolean;
  resolveVerificationTimeout: number | null;
  operatorFirstMessage: string;
};
