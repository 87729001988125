/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { TransferChat, UserChats, UserInfo, UserTopic } from "widgets/chat";

const MonitoringChatInfo = ({
  selectedUser,
  chats,
  setActiveChatId,
  activeChatId,
  userInfo,
  setUserInfo,
  teamMembers,
}) => {
  const [openCards, setOpenCards] = useState([4]);

  useEffect(() => {
    setOpenCards([4]);
  }, [selectedUser]);

  const toggleCard = index => {
    if (openCards.includes(index)) {
      setOpenCards(openCards.filter(cardIndex => cardIndex !== index));
    } else {
      setOpenCards([...openCards, index]);
    }
  };

  return (
    <div className="p-4 h-screen overflow-y-auto overflow-x-hidden">
      <div className="flex flex-col space-y-4">
        <UserInfo
          openCards={openCards}
          toggleCard={toggleCard}
          selectedUser={selectedUser}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />

        <UserChats
          openCards={openCards}
          toggleCard={toggleCard}
          chats={chats}
          setActiveChatId={setActiveChatId}
          activeChatId={activeChatId}
        />

        <UserTopic openCards={openCards} toggleCard={toggleCard} activeChatId={activeChatId} chats={chats} />

        <TransferChat
          openCards={openCards}
          toggleCard={toggleCard}
          activeChatId={activeChatId}
          teamMembers={teamMembers}
          selectedUser={selectedUser}
        />
      </div>
    </div>
  );
};

export default MonitoringChatInfo;
