import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { host } from "constants";
import { useWebSocket } from "contexts/WebsocketProvider";
import { uint8ArrayToJson } from "utils/utils";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

// eslint-disable-next-line react/prop-types
const MonitoringBotChats = ({ setSelectedUser }) => {
  const { t } = useTranslation("monitoring");
  const [botChatData, setBotChatData] = useState([]);
  const { companyId, userId } = useAppSelector(selectInitData);

  const ws = useWebSocket();

  const fetchChatData = useCallback(async () => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      },
    };

    const url = `${host}api/v1/dashboard/fetch_users_for_dashboard/?status=bot&page=1`;

    try {
      const response = await axios.get(url, config);

      const newBotChatData = response.data.map(user => ({
        backend_user_id: user.backend_user_id,
        frontend_user_id: user.frontend_user_id,
        last_message: user.last_message,
        read: user.read,
        name: user.name,
        timestamp: user.timestamp,
        last_message_type: user.last_message_type,
        status: user.status,
      }));

      setBotChatData(newBotChatData);
    } catch (e) {
      console.error(e);
    }
  }, [companyId, userId]);

  useEffect(() => {
    if (ws) {
      const newUserListener = event => {
        const newUser = uint8ArrayToJson(event);

        setBotChatData(prevData => [
          ...prevData,
          {
            backend_user_id: newUser.backend_user_id,
            frontend_user_id: newUser.frontend_user_id,
            last_message: "",
            name: newUser.name,
            timestamp: Date.now(),
            last_message_type: "bot",
            status: "bot",
          },
        ]);
      };

      ws.on("new_user", newUserListener);

      return () => {
        ws.off("new_user", newUserListener);
      };
    }

    return () => {};
  }, [ws, botChatData]);

  useEffect(() => {
    fetchChatData();
  }, [fetchChatData]);

  return (
    <div className="flex flex-col mt-5 mx-5 h-full">
      <div className="flex flex-col gap-4">
        {botChatData.map(chat => (
          <div
            key={chat.frontend_user_id}
            onClick={() => setSelectedUser(chat.backend_user_id)}
            className="border border-blue-300 px-2 py-1 rounded-lg grid grid-cols-12 gap-4 relative group cursor-pointer"
          >
            <div className="absolute top-10 left-0 text-xs hidden group-hover:block opacity-0 transition-all duration-500 group-hover:top-10  group-hover:opacity-100 bg-main-blue p-2 rounded text-white z-[100]">
              <span>
                {t("botChats.lastMessage")} {chat.last_message}
              </span>
            </div>
            <span className="font-semibold mr-1 col-span-10">
              {chat.phone ? chat.phone : `${t("sidebar.client")} ${chat.backend_user_id}`}
            </span>
            <span className="">{chat.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonitoringBotChats;
