import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

const TeamBottom = () => {
  const { t } = useTranslation("team");
  const [teamMembers, setTeamMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const { companyId, userId } = useAppSelector(selectInitData);

  const getAdminData = useCallback(async () => {
    try {
      const response = await axios.get(`${host}api/v1/dashboard/analytics/admin_info/`, {
        params: {},
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });

      const fetchedTeamMembers = response.data.filter(member => member.frontend_uuid !== null);

      setTeamMembers(fetchedTeamMembers);

      const invitees = response.data.filter(member => member.frontend_uuid === null && member.email);

      setInvitedMembers(invitees);
    } catch (error) {
      console.error(`Failed to fetch user count`, error);
    }
  }, [companyId, userId]);

  useEffect(() => {
    getAdminData();
  }, [getAdminData]);

  return (
    <div>
      <div>
        {teamMembers.map(user => (
          <div className="flex gap-2 p-3 hover:bg-blue-200 rounded-lg border border-blue-300 mb-2">
            <div>{user.email}</div>
            <div className="text-gray-500">
              {t("role")} {user.role}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-20">
        <span className="p-2 text-md font-semibold ">{t("invited")}</span>
        <div className="mt-5 gap-4 flex flex-col">
          {invitedMembers.map(member => {
            if (!member) return null;
            return (
              <div className="p-2 border border-blue-300 rounded-lg">
                <div>{member.email && member.email}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamBottom;
