import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "utils/hooks";
import { login } from "store/auth/actions";

const SigninPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("signin");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const signin = async (email, password) => {
    dispatch(login({ email, password }));
  };

  const handleForm = async event => {
    event.preventDefault();
    await signin(email, password);
  };

  const handleEmailChange = event => setEmail(event.target.value);
  const handlePasswordChange = event => setPassword(event.target.value);

  return (
    <>
      <style>
        {`.indent {
            text-indent: 0.5rem;
          }`}
      </style>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-sm">
          <h1 className="mx-auto h-10 w-auto text-4xl text-bold">RTNO</h1>
          <h2 className="mt-5 text-center text-2xl font-bold leading-9">{t("welcome")}</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST" onSubmit={handleForm}>
            <div className="mt-2">
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <div className="px-2">
                    <span className="block sm:inline"> {errorMessage}</span>
                  </div>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <span
                      className="absolute top-0 bottom-0 right-0 px-4 py-3 ml-5"
                      onClick={() => setErrorMessage("")}
                    >
                      <svg
                        className="fill-current h-6 w-6 text-red-500"
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <title>Close</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                      </svg>
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6">
                {t("email")}
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={handleEmailChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 indent"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6">
                  {t("password")}
                </label>
                {/* <div className="text-sm">
                  <a
                    href="#"
                    onClick={() => handleResetPassword(email)}
                    className="font-semibold text-main-blue hover:text-other-blue"
                  >
                    Забыли пароль?
                  </a>
                </div> */}
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={handlePasswordChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 indent"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-main-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-other-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("login")}
              </button>
            </div>
          </form>

          <p className="mt-3 text-sm">
            {t("noAccount")}{" "}
            <a href="/signup" className="font-semibold leading-6 text-main-blue hover:text-other-blue">
              {t("register")}
            </a>
          </p>
        </div>
        <div className="md:hidden">{t("madeForDesktop")}</div>
      </div>
    </>
  );
};

export default SigninPage;
