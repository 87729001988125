import React, { useState } from "react";
import { ChatInterface, ChatList, ChatInfo } from "widgets/chat";

const OmnichannelPage = () => {
  const [selectedUser, setSelectedUser] = useState(null); // this is the short id, displayed on the dashboard to show the index of the user and the backend_user_id in backend calls
  const [userId, setUserId] = useState(""); // this is the long id used to establish WS connection with user and the frontend_user_id in backend calls
  const [keys, setKeys] = useState([]);
  const [chats, setChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    description: "",
  });

  return (
    <div className="flex h-screen w-full o">
      <div className={`w-${selectedUser ? "1/4" : "full"}`}>
        <ChatList
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setUserId={setUserId}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
      </div>
      {selectedUser && (
        <div className="w-2/4">
          <ChatInterface
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setKeys={setKeys}
            userId={userId}
            setChats={setChats}
            activeChatId={activeChatId}
            setActiveChatId={setActiveChatId}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
            chats={chats}
          />
        </div>
      )}
      {selectedUser && (
        <div className="w-1/4">
          <ChatInfo
            selectedUser={selectedUser}
            keys={keys}
            chats={chats}
            setUserInfo={setUserInfo}
            setActiveChatId={setActiveChatId}
            activeChatId={activeChatId}
            userInfo={userInfo}
          />
        </div>
      )}
    </div>
  );
};

export default OmnichannelPage;
