/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

const ElapsedTime = ({ timestamp, color = "text-red-600" }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const updateElapsedTime = () => {
      const currentTime = new Date().getTime();
      const chatTime = new Date(timestamp).getTime();
      setElapsedTime(Math.floor((currentTime - chatTime) / 1000));
    };

    updateElapsedTime();
    const interval = setInterval(updateElapsedTime, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timestamp]);

  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return <span className={`text-sm ${color}`}>{formatTime(elapsedTime)}</span>;
};

export default ElapsedTime;
