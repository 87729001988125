/* eslint-disable react/prop-types */
import React from "react";

const TopList = ({ filters, activeFilter }) => (
  <div className="flex w-full">
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {filters.map(filter => (
          <li key={filter.filterName} className="mr-2 cursor-pointer" onClick={filter.onClick}>
            <p
              className={`inline-flex items-center relative justify-center p-4 border-b-2 rounded-t-lg ${
                activeFilter === filter.filterName
                  ? "text-main-blue border-main-blue dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
              }`}
            >
              <filter.icon className="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" />
              {filter.filterName}
            </p>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default TopList;
