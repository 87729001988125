import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectUser } from "store/user/selectors";
import { updateUser } from "store/user/actions";

const UserProfilePage = () => {
  const { t } = useTranslation("adminProfile");
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  console.log({ user });

  const [inputValues, setInputValues] = useState({
    name: user.name,
    lastName: user.lastName,
  });

  const onNameChange = event => {
    setInputValues(prev => ({ ...prev, name: event.target.value }));
  };

  const onLastNameChange = event => {
    setInputValues(prev => ({ ...prev, lastName: event.target.value }));
  };

  const onSubmit = async () => {
    if (!inputValues.lastName || !inputValues.name) {
      alert(t("nameEmptyAlert"));
      return;
    }

    await dispatch(updateUser(inputValues))
      .unwrap()
      .then(() => {
        alert("Name updated successfully.");
      })
      .catch(error => {
        console.error("Error:", error);
        alert(t("somethingWrongAlert"));
      });
  };

  // Todo: add formik

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
      <div className="text-center font-bold text-xl mb-4 bg-gray-100 p-2 rounded">{t("personalCabinet")}</div>
      <div className="mb-4">
        <label className="block mb-2">{t("emailLabel")}</label>
        <span className="text-gray-600">{user.email}</span>
      </div>
      <div className="mb-4">
        <label className="block mb-2">{t("roleLabel")}</label>
        <span className="text-gray-600">{user.role}</span>
      </div>
      <div className="mb-4">
        <label className="block mb-2">{t("firstNameLabel")}</label>
        <input type="text" value={inputValues.name} onChange={onNameChange} className="w-full p-2 border rounded" />
      </div>
      <div className="mb-4">
        <label className="block mb-2">{t("lastNameLabel")}</label>
        <input
          type="text"
          value={inputValues.lastName}
          onChange={onLastNameChange}
          className="w-full p-2 border rounded"
        />
      </div>
      <Button message={t("saveButton")} onClick={onSubmit} />
    </div>
  );
};

export default UserProfilePage;
