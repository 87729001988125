import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { configApi } from "api";
import { useAppSelector } from "utils/hooks";
import { selectInitData, selectIsCompanyValid } from "store/auth/selectors";
import { Alert, Button, Tooltip } from "components";

const ExtraSettings = () => {
  const { t } = useTranslation("profile");
  const { companyId } = useAppSelector(selectInitData);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);

  const [{ needPhone, memoryTimeout, needResolveVerification, resolveVerificationTimeout }, setExtraSettings] =
    useState({
      needPhone: false,
      memoryTimeout: 0,
      needResolveVerification: false,
      resolveVerificationTimeout: 0,
      operatorFirstMessage: "",
    });

  const buttonText = useMemo(() => {
    if (isSubmitting)
      return (
        <div className="flex items-center justify-center space-x-2">
          {t("companyForm.wait")}{" "}
          <div className="loader ease-linear ml-2 rounded-full border-2 border-t-2 border-gray-200 h-4 w-4" />
        </div>
      );

    return isCompanyValid ? t("companyForm.change") : t("companyForm.continue");
  }, [isCompanyValid, isSubmitting, t]);

  const getExtraSettings = useCallback(async () => {
    try {
      const { data } = await configApi.getExtraSettings();
      setExtraSettings({ ...data, resolveVerificationTimeout: data.resolveVerificationTimeout || 0 });
    } catch (error) {
      console.error("get company extra settings error:", { error });
    }
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      await configApi.updateExtraSettings({
        companyName: companyId,
        needPhone,
        memoryTimeout,
        needResolveVerification,
        resolveVerificationTimeout: needResolveVerification ? resolveVerificationTimeout : null,
      });

      setSuccessAlert(true);
    } catch (error) {
      setFailAlert(true);
      console.error("update company extra settings error:", { error });
    } finally {
      setIsSubmitting(false);
    }
  };

  const memoryTimeoutMinutes = useMemo(() => Math.floor(memoryTimeout / 60), [memoryTimeout]);
  const memoryTimeoutSeconds = useMemo(() => memoryTimeout % 60, [memoryTimeout]);

  const onMemoryTimeoutMinutesChange = event => {
    const newMinutes = parseInt(event.target.value, 10);

    if (!Number.isNaN(newMinutes) && newMinutes >= 0) {
      setExtraSettings(prev => ({ ...prev, memoryTimeout: newMinutes * 60 + memoryTimeoutSeconds }));
    }
  };

  const onMemoryTimeoutSecondsChange = event => {
    const newSeconds = parseInt(event.target.value, 10);

    if (!Number.isNaN(newSeconds) && newSeconds >= 0) {
      setExtraSettings(prev => ({ ...prev, memoryTimeout: memoryTimeoutMinutes * 60 + newSeconds }));
    }
  };

  const resolveVerificationTimeoutMinutes = useMemo(
    () => Math.floor(resolveVerificationTimeout / 60),
    [resolveVerificationTimeout],
  );
  const resolveVerificationTimeoutSeconds = useMemo(
    () => resolveVerificationTimeout % 60,
    [resolveVerificationTimeout],
  );

  const onResolveVerificationTimeoutMinutesChange = event => {
    const newMinutes = parseInt(event.target.value, 10);

    if (!Number.isNaN(newMinutes) && newMinutes >= 0) {
      setExtraSettings(prev => ({
        ...prev,
        resolveVerificationTimeout: newMinutes * 60 + resolveVerificationTimeoutSeconds,
      }));
    }
  };

  const onResolveVerificationTimeoutSecondsChange = event => {
    const newSeconds = parseInt(event.target.value, 10);

    if (!Number.isNaN(newSeconds) && newSeconds >= 0 && newSeconds < 60) {
      setExtraSettings(prev => ({
        ...prev,
        resolveVerificationTimeout: resolveVerificationTimeoutMinutes * 60 + newSeconds,
      }));
    }
  };

  useEffect(() => {
    if (isCompanyValid) {
      getExtraSettings();
    }
  }, [isCompanyValid, getExtraSettings]);

  return (
    <div className="flex flex-col items-center justify-center bg-white shadow-md rounded-3xl p-6 m-4 w-full max-w-screen-lg">
      <div className="w-full">
        <h2 className="text-left font-semibold text-lg mb-5">{t("extraSettings.extraSettings")}</h2>
        <div className="grid grid-cols-2 gap-4">
          <Tooltip message={t("extraSettings.needPhone")} description="Description for memory timeout in minutes" />
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={needPhone}
              onChange={() => setExtraSettings(prev => ({ ...prev, needPhone: !prev.needPhone }))}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
          </label>

          <Tooltip message={t("extraSettings.memoryTimeout")} description="Description for memory timeout in minutes" />
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-1">
              <input
                id="memoryTimeoutMinutes"
                type="number"
                placeholder="Минуты"
                min={0}
                max={60}
                value={memoryTimeoutMinutes}
                className="border-1 border-black rounded-lg px-1 py-0.5"
                onChange={onMemoryTimeoutMinutesChange}
              />
              <span>мин</span>
            </div>

            <div className="flex items-center space-x-1">
              <input
                id="memoryTimeoutSeconds"
                type="number"
                placeholder="Секунды"
                min={10}
                max={59}
                value={memoryTimeoutSeconds}
                className="border-1 border-black rounded-lg px-1 py-0.5"
                onChange={onMemoryTimeoutSecondsChange}
              />
              <span>сек</span>
            </div>
          </div>

          <Tooltip
            message={t("extraSettings.needResolveVerification")}
            description="Description for memory timeout in minutes"
          />
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={needResolveVerification}
              onChange={() =>
                setExtraSettings(prev => ({ ...prev, needResolveVerification: !prev.needResolveVerification }))
              }
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
          </label>

          {needResolveVerification && (
            <Tooltip
              message={t("extraSettings.resolveVerification")}
              description="Description for verification timeout in minutes"
            />
          )}
          {needResolveVerification && (
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-1">
                <input
                  id="verificationTimeoutMinutes"
                  type="number"
                  placeholder="Минуты"
                  min={0}
                  max={60}
                  value={resolveVerificationTimeoutMinutes}
                  className="border-1 border-black rounded-lg px-1 py-0.5"
                  onChange={onResolveVerificationTimeoutMinutesChange}
                />
                <span>мин</span>
              </div>

              <div className="flex items-center space-x-1">
                <input
                  id="verifyTimeoutSeconds"
                  type="number"
                  placeholder="Секунды"
                  min={10}
                  max={59}
                  value={resolveVerificationTimeoutSeconds}
                  className="border-1 border-black rounded-lg px-1 py-0.5"
                  onChange={onResolveVerificationTimeoutSecondsChange}
                />
                <span>сек</span>
              </div>
            </div>
          )}

          <div className="col-span-2 text-center my-4 mt-6">
            <Button disabled={isSubmitting || !isCompanyValid} message={buttonText} onClick={handleSubmit} />
          </div>
        </div>
      </div>

      {successAlert && <Alert message="Успешно обновлено!" alert={successAlert} setAlert={setSuccessAlert} />}
      {failAlert && <Alert message="Произошла ошибка!" alert={failAlert} setAlert={setFailAlert} color="bg-red-200" />}
    </div>
  );
};

export default ExtraSettings;
