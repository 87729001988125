/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";

const HistoryChatList = ({ chatData, selectedUser, setSelectedUser }) => {
  const { t } = useTranslation("history");

  return (
    <div className="mt-4 overflow-y-auto mx-2 h-screen">
      {chatData.map(user => (
        <div
          key={user.backend_user_id}
          className={`relative flex px-2 py-4 space-x-4 rounded-lg cursor-pointer border border-blue-200 mb-4 ${
            selectedUser === user.backend_user_id ? "bg-gradient-to-r from-blue-100 to-blue-50" : "bg-transparent"
          } hover:bg-gradient-to-r hover:from-blue-100 hover:to-blue-50 transition ease-in-out duration-300`}
          onClick={() => setSelectedUser(user.backend_user_id)}
        >
          <div className="grid gap-4 grid-cols-10 w-full items-center">
            <p className="col-span-4">{user.phone ? user.phone : `${t("client")} ${user.backend_user_id}`}</p>

            {!selectedUser && (
              <span className="text-gray-500 text-sm col-span-3">{new Date(user.timestamp).toLocaleDateString()}</span>
            )}

            {!selectedUser && <p className="text-gray-600 col-span-3 truncate">{user.last_message}</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryChatList;
