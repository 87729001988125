import { createReducer } from "@reduxjs/toolkit";
import { toggleActiveMenu, setScreenSize } from "./actions";
import { StoreState } from "./types";

const initialState: StoreState = {
  activeMenu: true,
};

const layoutReducer = createReducer(initialState, builder => {
  builder
    .addCase(toggleActiveMenu, (state, action) => {
      state.activeMenu = action.payload;
    })
    .addCase(setScreenSize, (state, action) => {
      state.screenSize = action.payload;
    });
});

export default layoutReducer;
