import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const savedLocale = localStorage.getItem("i18nextLng");
const browserLang = window.navigator.language.substr(0, 2);
const defaultLang = savedLocale || browserLang;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    lng: defaultLang,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    ns: [
      "navbar",
      "sidebar",
      "adminProfile",
      "analytics",
      "badMessages",
      "knowledgeBase",
      "helper",
      "history",
      "monitoring",
      "team",
      "omnichannel",
      "profile",
      "signin",
      "signup",
    ],
  });

export default i18n;
