import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import MainRouter from "router/MainRouter";
import { NotificationProvider, WebSocketProvider } from "./contexts";
import { Notifications } from "./components";
import i18n from "./i18n";
import store from "./store/root";

import "./index.css";
import "./App.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <WebSocketProvider>
        <NotificationProvider>
          <Notifications />
          <MainRouter />
        </NotificationProvider>
      </WebSocketProvider>
    </I18nextProvider>
  </Provider>,
);
