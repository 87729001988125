import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store/root";

const kbState = (state: RootState) => state.kb;

const selectKB = createSelector([kbState], ({ object, topics, mainTopics }) => ({
  object,
  topics,
  mainTopics,
}));
const selectKBLoading = createSelector([kbState], state => state.loading);

export { selectKBLoading, selectKB };
