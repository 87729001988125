import React, { useState } from "react";
import HistoryChatsFilter from "./HistoryChatsFilter";
import HistoryChatsList from "./HistoryChatList";
import HistoryChatsInterface from "./HistoryChatsInterface";
import HistoryChatsInfo from "./HistoryChatsInfo";

const HistoryChats = () => {
  const [chatData, setChatData] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [chats, setChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    description: "",
  });

  return (
    <div className="">
      {!selectedUser && <HistoryChatsFilter setChatData={setChatData} />}
      <div className="flex">
        <div className={selectedUser ? "w-1/4" : "w-full"}>
          <HistoryChatsList chatData={chatData} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
        <div className={selectedUser ? "w-1/2" : "hidden"}>
          <HistoryChatsInterface
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setChats={setChats}
            setActiveChatId={setActiveChatId}
            activeChatId={activeChatId}
            setUserInfo={setUserInfo}
            chats={chats}
            userInfo={userInfo}
          />
        </div>
        <div className={selectedUser ? "w-1/4" : "hidden"}>
          <HistoryChatsInfo
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setChats={setChats}
            setActiveChatId={setActiveChatId}
            activeChatId={activeChatId}
            setUserInfo={setUserInfo}
            chats={chats}
            userInfo={userInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryChats;
