import React, { useCallback, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useTranslation } from "react-i18next";
import { BiBookOpen } from "react-icons/bi";
import { MdAnalytics, MdAssessment, MdDashboard, MdHistory } from "react-icons/md";
import { Navbar } from "components";
import { UserActivityStatus } from "entities/user";
import { useNotification } from "contexts/NotificationContext";
import { useWebSocket } from "contexts/WebsocketProvider";
import { uint8ArrayToJson } from "utils/utils";
import { newChatNotification } from "assets/sounds";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectIsCompanyValid, selectSession } from "store/auth/selectors";
import { logout } from "store/auth/actions";
import { selectCompany } from "store/company/selectors";
import { selectUser } from "store/user/selectors";
import { setUserActivityStatus } from "store/user/actions";
import { selectActiveMenu } from "store/layout/selectors";
import Sidebar from "./Sidebar";

const playNotificationSound = () => {
  const audio = new Audio(newChatNotification);
  audio.play();
};

const PrivateLayout = () => {
  const session = useAppSelector(selectSession);
  const dispatch = useAppDispatch();
  const ws = useWebSocket() as unknown as Socket;
  const { t } = useTranslation();
  // @ts-ignore
  const { addNotification } = useNotification();
  const { role, status } = useAppSelector(selectUser);
  const { settings } = useAppSelector(selectCompany);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);
  const activeMenu = useAppSelector(selectActiveMenu);

  // ! what is wrong with version type? it is string but it is compared with number
  // ! Change hasConfig logic
  const sidebarRoutes = useMemo(
    () =>
      isCompanyValid
        ? [
            {
              to: "/monitoring",
              icon: MdAnalytics,
              label: t("sidebar:label.monitoring"),
              access: ["admin", "supervisor"].includes(role) && settings.version === 3,
            },
            {
              to: "/dashboard",
              icon: MdDashboard,
              label: t("sidebar:label.dashboard"),
              access: true,
            },
            {
              to: "/history",
              icon: MdHistory,
              label: t("sidebar:label.history"),
              access: true,
            },
            {
              to: "/analytics",
              icon: MdAssessment,
              label: t("sidebar:label.analytics"),
              access: ["admin", "supervisor"].includes(role),
            },
            {
              to: "/knowledgebase",
              icon: BiBookOpen,
              label: t("sidebar:label.knowledgeBase"),
              access: ["admin", "supervisor"].includes(role),
            },
          ]
        : [],
    [isCompanyValid, role, settings.version, t],
  );

  const handleLogout = useCallback(async () => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (ws) {
      const newUserChatStatusListener = (event: any) => {
        const newStatus = uint8ArrayToJson(event);

        if (newStatus.email === session?.user.email && newStatus.status === "assigned") {
          addNotification(newStatus.backend_user_id);
          playNotificationSound();
        }
      };

      ws.on("new_user_chat_status", newUserChatStatusListener);

      return () => {
        ws.off("new_user_chat_status", newUserChatStatusListener);
      };
    }

    return () => {};
  }, [addNotification, session, ws]);

  return (
    <>
      {role === "operator" && (
        <div
          className={`fixed inset-0 z-[40] transition ease-in-out duration-300 ${
            status === UserActivityStatus.INACTIVE ? "bg-black opacity-50" : "opacity-0 pointer-events-none"
          }`}
        >
          <button
            className="p-4 border border-red-400 bg-red-200 hover:bg-red-400 text-white z-10"
            onClick={() => dispatch(setUserActivityStatus(UserActivityStatus.ACTIVE))}
          >
            Вернуться в актив
          </button>
        </div>
      )}
      <div className="flex dark:bg-main-dark-bg">
        <div
          className={`fixed sidebar dark:bg-secondary-dark-bg bg-main-bg w-60 transform transition-transform ease-in-out ${
            activeMenu ? "translate-x-0" : "-translate-x-full"
          } duration-300`}
        >
          <Sidebar routes={sidebarRoutes} />
        </div>

        <div
          className={`dark:bg-main-bg bg-main-bg min-h-screen ${
            activeMenu ? "w-[calc(100%-1rem)] md:ml-60 overflow-x-hidden " : "w-full "
          }`}
        >
          <div className="fixed md:static bg-main-bg dark:-bg-main-dark-bg navbar w-full">
            <Navbar handleLogout={handleLogout} />
          </div>

          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateLayout;
