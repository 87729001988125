import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { configApi } from "api";
import { BotEmogis, BotEmogisRU, BotLanguage, BotLanguageRU, BotPersonality, BotPersonalityRU } from "entities/config";
import { useAppSelector } from "utils/hooks";
import { selectInitData, selectIsCompanyValid } from "store/auth/selectors";
import { selectCompany } from "store/company/selectors";
import { Tooltip, Button, Loading } from "components";

const BotSettings = () => {
  const { t } = useTranslation("profile");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const { companyId } = useAppSelector(selectInitData);
  const company = useAppSelector(selectCompany);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);
  const [isLoading, setIsLoading] = useState(false);
  const [{ personality, language, useEmojis }, setBotSettings] = useState({
    personality: BotPersonality.FRIENDLY,
    language: BotLanguage.RUSSIAN,
    useEmojis: BotEmogis.NO,
  });

  const buttonText = useMemo(() => {
    if (isSubmitting) {
      return (
        <div className="flex items-center justify-center space-x-2">
          {t("companyForm.wait")}{" "}
          <div className="loader ease-linear ml-2 rounded-full border-2 border-t-2 border-gray-200 h-4 w-4" />
        </div>
      );
    }
    return isCompanyValid ? t("companyForm.change") : t("companyForm.continue");
  }, [isCompanyValid, isSubmitting, t]);

  const getBotSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await configApi.getBotSettings();
      setBotSettings({
        personality: data.prompts[2],
        language: data.prompts[3],
        useEmojis: data.prompts[4],
      });
    } catch (error) {
      console.error("get bot settings error:", { error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    setSuccess(false);

    try {
      await configApi.updateBotSettings({
        companyId,
        name: company.name,
        description: company.description,
        personality,
        language,
        useEmojis,
      });

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      console.log("update bot settings error:", { error });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isCompanyValid) {
      getBotSettings();
    }
  }, [getBotSettings, isCompanyValid]);

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-col items-center justify-center bg-white shadow-md rounded-3xl p-6 w-full max-w-screen-lg">
      <div className="w-full">
        <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
          <h2 className="text-left font-semibold text-lg mb-5">{t("companyForm.botCharacteristics")}</h2>
          <div />
          <Tooltip message={t("companyForm.aiPersonality")} description="Description 3" />
          <select
            className="appearance-none border text-sm rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="aiPersonality"
            value={personality}
            onChange={e => setBotSettings(prev => ({ ...prev, personality: e.target.value }))}
          >
            {Object.keys(BotPersonality).map(key => (
              <option key={key} value={BotPersonality[key]}>
                {BotPersonalityRU[key]}
              </option>
            ))}
          </select>
          <Tooltip message={t("companyForm.language")} description="Description 4" />
          <select
            className="appearance-none border text-sm rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="language"
            value={language}
            onChange={e => setBotSettings(prev => ({ ...prev, language: e.target.value }))}
          >
            {Object.keys(BotLanguage).map(key => (
              <option key={key} value={BotLanguage[key]}>
                {BotLanguageRU[key]}
              </option>
            ))}
          </select>
          <Tooltip message={t("companyForm.emoji")} description="Description 5" />
          <select
            className="appearance-none border text-sm rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emoji"
            value={useEmojis}
            onChange={e => setBotSettings(prev => ({ ...prev, useEmojis: e.target.value }))}
          >
            {Object.keys(BotEmogis).map(key => (
              <option key={key} value={BotEmogis[key]}>
                {BotEmogisRU[key]}
              </option>
            ))}
          </select>

          <div className="col-span-2 text-center my-4 mt-6">
            <Button disabled={isSubmitting || !isCompanyValid} message={buttonText} type="submit" />
            {success && <div className="mt-4 text-green-500">{t("companyForm.success")}</div>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BotSettings;
