import React from "react";

// eslint-disable-next-line react/prop-types
const Topic = ({ isFirstBotMessage, topic, subtopic }) => (
  <div className=" text-center rounded-lg text-gray-600 text-xs w-full leading-6">
    <span className="mb-10">
      Бот {isFirstBotMessage ? "поставил" : "поменял"} тему на{" "}
      <span className="bg-gray-200 py-0.5 px-1 rounded-xl font-semibold">{topic}</span>
    </span>
    <span>
      <br />и подтему на <span className="bg-gray-200 py-0.5 px-1 rounded-xl font-semibold">{subtopic}</span>
    </span>
  </div>
);

export default Topic;
