import Cookies from "js-cookie";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "api";
import { UpdateUserInfoRequest } from "api/user";
import { User, UserActivityStatus } from "entities/user";
import { supabase } from "supabase/supabase";
import { AsyncThunkConfig } from "utils/redux";
import { selectInitData, selectSession } from "../auth/selectors";
import { selectUser } from "./selectors";

// ! Why we are listening to the user status with ws? Do we managed it in the backend? If so, we need to remove set user status from the update function
// ! Something wrong on the backend with user status

const setUserActivityStatus = createAction("user/setUserActivityStatus", (status: UserActivityStatus) => {
  Cookies.set("status", status);
  return {
    payload: status,
  };
});

const updateUserActivityStatus = createAsyncThunk<undefined, UserActivityStatus, AsyncThunkConfig>(
  "user/updateUserActivityStatus",
  async (status, { rejectWithValue, getState, dispatch }) => {
    try {
      const { userId } = selectInitData(getState());
      await userApi.updateUserStatus({ userId: userId!, status });
      dispatch(setUserActivityStatus(status));

      return undefined;
    } catch (e) {
      const error = new Error("Произошла ошибка при обновлении статуса пользователя.");
      return rejectWithValue(error);
    }
  },
);

const fetchUser = createAsyncThunk<User, undefined, AsyncThunkConfig>(
  "user/fetchUser",
  async (_, { rejectWithValue, getState }) => {
    try {
      const session = selectSession(getState());

      if (!session) {
        return rejectWithValue(new Error("Сессия не найдена"));
      }

      const result = await supabase
        .from("users")
        .select("email, role, first_name, last_name")
        .eq("user_id", session.user.id);

      if (!result.data) {
        return rejectWithValue(new Error("Пользователь не найден"));
      }

      const userData = result.data[0];

      // ! Need to make sure that status exists. This is wrong approach. We need to manage initial status on the backend or get it from the token.
      // ! Or we can load the company and the user sequentially to ensure that the initial status for the user is already set in the cookie.
      const userStatus = (Cookies.get("status") as UserActivityStatus) || UserActivityStatus.ACTIVE;

      return {
        name: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        role: userData.role,
        status: userStatus,
      };
    } catch (e) {
      const error = new Error("Упс! Произошла ошибка во время загрузки данных пользователя. Попробуйте позднее.");
      return rejectWithValue(error);
    }
  },
);

const updateUser = createAsyncThunk<
  Omit<UpdateUserInfoRequest, "userId">,
  Omit<UpdateUserInfoRequest, "userId">,
  AsyncThunkConfig
>("user/updateUser", async ({ name, lastName }, { rejectWithValue, getState }) => {
  try {
    // Todo: remove it after backend refactoring
    const user = selectUser(getState());
    const { userId } = selectInitData(getState());

    const result = await supabase
      .from("users")
      .update({ first_name: name, last_name: lastName })
      .eq("email", user.email);

    if (result.error) {
      return rejectWithValue(new Error("Произошла ошибка при обновлении данных пользователя"));
    }

    await userApi.updateUserInfo({ userId: userId!, name, lastName });

    return {
      name,
      lastName,
    };
  } catch (e) {
    const error = new Error("Произошла ошибка при обновлении данных пользователя.");
    return rejectWithValue(error);
  }
});

export { fetchUser, updateUser, setUserActivityStatus, updateUserActivityStatus };
