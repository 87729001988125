import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useWebSocket } from "contexts/WebsocketProvider";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

// eslint-disable-next-line react/prop-types
const MonitoringChatList = ({ selectedTeamMember, setSelectedUser, selectedUser, selectedTeamMemberEmail }) => {
  const { t } = useTranslation("monitoring");
  const { companyId, userId } = useAppSelector(selectInitData);
  const ws = useWebSocket();
  const [adminChatData, setAdminChatData] = useState([]);

  const fetchAdminChatData = useCallback(async () => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      },
    };

    const url = `${host}api/v1/dashboard/fetch_users_for_dashboard/?status=assigned&page=${1}&frontend_uuid=${selectedTeamMember}`;

    const response = await axios.get(url, config);

    const chatData = response.data.map(user => ({
      backend_user_id: user.backend_user_id,
      frontend_user_id: user.frontend_user_id,
      last_message: user.last_message,
      read: user.read,
      phone: user.phone,
      timestamp: user.timestamp,
      last_message_type: user.last_message_type,
      status: user.status,
    }));

    setAdminChatData(chatData);
  }, [companyId, selectedTeamMember, userId]);

  useEffect(() => {
    fetchAdminChatData();
  }, [fetchAdminChatData, selectedTeamMember]);

  useEffect(() => {
    if (ws) {
      const userChatReassignListener = event => {
        const uint8Array = new Uint8Array(event);
        const stringData = new TextDecoder().decode(uint8Array);
        const newChat = JSON.parse(stringData);

        if (newChat.previous_admin === selectedTeamMember) {
          const newChatData = adminChatData.filter(chat => chat.backend_user_id !== newChat.backend_user_id);
          setAdminChatData(newChatData);
        }
      };

      const newUserChatStatusListener = event => {
        const uint8Array = new Uint8Array(event);
        const stringData = new TextDecoder().decode(uint8Array);
        const newStatus = JSON.parse(stringData);

        if (newStatus.status === "resolved") {
          const newChatData = adminChatData.filter(chat => chat.backend_user_id !== newStatus.backend_user_id);
          setAdminChatData(newChatData);
        }

        if (newStatus.status === "assigned" && newStatus.email === selectedTeamMemberEmail) {
          const isExisting = adminChatData.some(chat => chat.backend_user_id === newStatus.backend_user_id);

          if (!isExisting) {
            fetchAdminChatData();
          }
        }
      };

      ws.on("user_chat_reassign", userChatReassignListener);
      ws.on("new_user_chat_status", newUserChatStatusListener);

      return () => {
        ws.off("user_chat_reassign", userChatReassignListener);
        ws.off("new_user_chat_status", newUserChatStatusListener);
      };
    }

    return () => {};
  }, [adminChatData, fetchAdminChatData, selectedTeamMember, selectedTeamMemberEmail, ws]);

  return (
    <div className="mt-4 ">
      {adminChatData.map(user => (
        <div
          key={user.backend_user_id}
          className={`relative flex px-2 py-4 space-x-4 rounded-lg cursor-pointer border border-blue-200 mb-4 ${
            selectedUser === user.backend_user_id ? "bg-gradient-to-r from-blue-100 to-blue-50" : "bg-transparent"
          } hover:bg-gradient-to-r hover:from-blue-100 hover:to-blue-50 transition ease-in-out duration-300`}
          onClick={() => setSelectedUser(user.backend_user_id)}
        >
          <div className="grid gap-4 grid-cols-10 w-full items-center">
            <p className="col-span-4">{user.phone ? user.phone : `${t("queue.client")} ${user.backend_user_id}`}</p>

            {!selectedUser && (
              <span className="text-gray-500 text-sm col-span-3">{new Date(user.timestamp).toLocaleDateString()}</span>
            )}

            {!selectedUser && <p className="text-gray-600 col-span-3 truncate">{user.last_message}</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MonitoringChatList;
