import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment-timezone";
import { FaPlay, FaStop } from "react-icons/fa";
import { useChatContext } from "../ChatContext";

import "./AudioPlayer.css";

// eslint-disable-next-line react/prop-types
const AudioPlayer = React.memo(({ id, src }) => {
  const { setCurrentlyPlayingAudioId, currentlyPlayingAudioId } = useChatContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const currentTimeRef = useRef(null);
  const currentVolumeRef = useRef(null);

  const audioRef = useRef(new Audio(src));

  const togglePlayPause = () => {
    if (!isLoaded) return;

    if (!isPlaying) {
      setCurrentlyPlayingAudioId(id);
    }

    if (isPlaying) {
      setCurrentlyPlayingAudioId(null);
    }
  };

  const handleVolumeChange = event => {
    const { value } = event.target;
    audioRef.current.volume = parseFloat(value);
  };

  const handleSeek = event => {
    const { value } = event.target;
    audioRef.current.currentTime = parseFloat(value);
  };

  const fromattedDuration = useMemo(() => moment.utc(duration * 1000).format("mm:ss"), [duration]);

  useEffect(() => {
    const currentAudio = audioRef.current;

    const handleLoadedMetadata = () => {
      setIsLoaded(true);
      setDuration(currentAudio.duration);
    };

    const handleTimeUpdate = () => {
      currentTimeRef.current.value = currentAudio.currentTime;
    };

    const handleVolumeChange = () => {
      currentVolumeRef.current.value = currentAudio.volume;
    };

    const handleEnded = () => {
      currentAudio.currentTime = 0;
      setCurrentlyPlayingAudioId(null);
    };

    currentAudio.addEventListener("loadedmetadata", handleLoadedMetadata);
    currentAudio.addEventListener("timeupdate", handleTimeUpdate);
    currentAudio.addEventListener("volumechange", handleVolumeChange);
    currentAudio.addEventListener("ended", handleEnded);

    return () => {
      currentAudio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      currentAudio.removeEventListener("timeupdate", handleTimeUpdate);
      currentAudio.removeEventListener("volumechange", handleVolumeChange);
      currentAudio.removeEventListener("ended", handleEnded);
      currentAudio.pause();
      setCurrentlyPlayingAudioId(null);
    };
  }, [setCurrentlyPlayingAudioId, src]);

  useEffect(() => {
    if (!audioRef.current) return;

    if (currentlyPlayingAudioId === id) {
      setIsPlaying(true);
      audioRef.current.play();
    }

    if (currentlyPlayingAudioId !== id && isPlaying) {
      setIsPlaying(false);
      audioRef.current.pause();
    }
  }, [currentlyPlayingAudioId, id, isPlaying]);

  return (
    <div className="bg-blue-400 p-2 rounded-lg flex gap-2 items-center w-full">
      <button
        onClick={togglePlayPause}
        disabled={!isLoaded}
        className={`rounded-full bg-white p-3 transform transition-all duration-300 ${!isLoaded && "cursor-not-allowed"}`}
      >
        {isPlaying ? <FaStop size={12} className="text-blue-400" /> : <FaPlay size={12} className="text-blue-400" />}
      </button>

      <div className="flex flex-col w-full ">
        <input
          ref={currentTimeRef}
          type="range"
          min="0"
          step={0.01}
          max={duration || 0}
          onChange={handleSeek}
          className={`audioRange audioRange_time mb-1 ${!isLoaded && "rounded-sm bg-white animate-pulse pointer-events-none cursor-not-allowed"}`}
        />

        <div className="flex w-full flex-1 justify-between items-center">
          <input
            ref={currentVolumeRef}
            type="range"
            min="0"
            max="1"
            step="0.01"
            onChange={handleVolumeChange}
            className="audioRange audioRange_volume"
          />

          <span className={`text-white text-xs select-none ${!isLoaded && "rounded-sm animate-pulse"}`}>
            {isLoaded ? fromattedDuration : "--:--"}
          </span>
        </div>
      </div>
    </div>
  );
});

export default AudioPlayer;
