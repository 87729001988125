import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { TbPlugConnectedX } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { MdPerson, MdExitToApp, MdExpandMore, MdOutlinePeople } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import i18n from "i18n";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectSession } from "store/auth/selectors";
import { setScreenSize, toggleActiveMenu } from "store/layout/actions";
import { selectActiveMenu, selectScreenSize } from "store/layout/selectors";
import { selectCompany } from "store/company/selectors";
import { selectUser } from "store/user/selectors";
import Modal from "./Modal";
import StatusPicker from "./StatusPicker";

const LanguagePicker = () => {
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="py-1 flex items-center px-4 justify-right">
      <button
        className={`flex items-center text-gray-700 block py-0.5 text-lg  ${
          i18n.language === "en" ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={() => {
          if (i18n.language !== "en") {
            changeLanguage("en");
          }
        }}
      >
        🇬🇧
      </button>
      <button
        className={`flex items-center text-gray-700 block py-0.5 text-lg ml-2 ${
          i18n.language === "ru" ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={() => {
          if (i18n.language !== "ru") {
            changeLanguage("ru");
          }
        }}
      >
        🇷🇺
      </button>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const Navbar = ({ handleLogout }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("navbar");
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showDisconnect] = useState(false);
  const session = useAppSelector(selectSession);
  const { settings } = useAppSelector(selectCompany);
  const { role } = useAppSelector(selectUser);
  const screenSize = useAppSelector(selectScreenSize);
  const activeMenu = useAppSelector(selectActiveMenu);

  useEffect(() => {
    const handleResize = () => dispatch(setScreenSize(window.innerWidth));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  useEffect(() => {
    if (screenSize <= 900) dispatch(toggleActiveMenu(false));
    else dispatch(toggleActiveMenu(true));
  }, [dispatch, screenSize]);

  return (
    <div className="flex justify-between items-center p-2 md:mx-6">
      <div onClick={() => dispatch(toggleActiveMenu(!activeMenu))}>
        {!activeMenu && <BsFillArrowRightSquareFill size={25} />}
      </div>
      {showDisconnect && session && (
        <div
          onClick={() => window.location.reload()}
          className="text-red-500 cursor-pointer animate-pulse relative group"
        >
          <TbPlugConnectedX size={25} />
        </div>
      )}
      <Modal
        open={modalOpen}
        topMessage={t("logoutPromptTitle")}
        action={t("logout")}
        onClose={() => setModalOpen(false)}
        onConfirm={() => {
          handleLogout();
          setModalOpen(false);
        }}
      >
        {t("logoutPrompt")}
      </Modal>
      <div className="flex items-center mr-3">
        {!session && <LanguagePicker />}
        {location.pathname !== "/signup" && location.pathname !== "/signin" && (
          <>
            {settings.version === 3 && <StatusPicker className="z-[100]" />}

            <div className="relative z-[100] inline-block text-left w-full flex justify-center">
              {session && (
                <div className="flex items-center justify-center">
                  <div
                    className="inline-flex justify-center items-center group gap-x-1.5 rounded-full bg-white px-2 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    <MdPerson className="text-gray-500 mr-2 group-hover:text-main-blue" />
                    {session.user.email}
                    <MdExpandMore className="ml-2 text-gray-500" />
                  </div>
                  {dropdownOpen && (
                    <div className="origin-top-right absolute -top-1 right-0 mt-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <NavLink
                          className="flex items-center text-gray-700 block px-4 py-2 text-sm border-b border-gray-200"
                          to="/profile"
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                          <MdPerson className="mr-2" />
                          {t("profile")}
                        </NavLink>
                        {role === "admin" && (
                          <NavLink
                            className="flex items-center text-gray-700 block px-4 py-2 text-sm border-b border-gray-200"
                            to="/settings"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                          >
                            <FiSettings className="mr-2" />
                            {t("configuration")}
                          </NavLink>
                        )}
                        {role === "admin" && settings.version === 3 && (
                          <NavLink
                            className="flex items-center text-gray-700 block px-4 py-2 text-sm border-b border-gray-200"
                            to="/team"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                          >
                            <MdOutlinePeople className="mr-2" />
                            {t("team")}
                          </NavLink>
                        )}
                        <button
                          className="flex items-center text-red-400 block px-4 py-2 text-sm w-full text-left"
                          onClick={() => {
                            setDropdownOpen(!dropdownOpen);
                            setModalOpen(true);
                          }}
                        >
                          <MdExitToApp className="mr-2" />
                          {t("logout")}
                        </button>
                        <LanguagePicker />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
