import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "utils/hooks";
import { selectCompany } from "store/company/selectors";

type Props = {
  children: JSX.Element;
  allowedVersions: number[];
};

// What is version means?

const RequireVersion = ({ children, allowedVersions }: Props) => {
  const { settings } = useAppSelector(selectCompany);

  return allowedVersions.includes(settings.version) ? children : <Navigate to="/dashboard" />;
};

export default RequireVersion;
