import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  SigninPage,
  SignupPage,
  SignupThroughLinkPage,
  AnalyticsPage,
  HistoryPage,
  KBPage,
  MonitoringPage,
  OmnichannelPage,
  TeamPage,
  UserProfilePage,
  SettingsPage,
  Page404,
} from "pages";
import { MainLayout, PrivateLayout, PublicLayout } from "./layouts";
import RequireAuth from "./RequireAuth";
import RequireVersion from "./RequireVersion";
import RequireRole from "./RequireRole";
import CheckAuth from "./CheckAuth";
import RequireAvailableCompany from "./RequireAvailableCompany";
import InitApp from "./InitApp";

const MainRouter = () => (
  <div>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <CheckAuth>
              <MainLayout />
            </CheckAuth>
          }
        >
          {/* Public Routes */}

          <Route element={<PublicLayout />}>
            <Route index path="signin" element={<SigninPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="register" element={<SignupThroughLinkPage />} />
            <Route index element={<Navigate to="/signin" />} />
          </Route>

          {/* Private Routes */}

          <Route
            element={
              <RequireAuth>
                <InitApp />
              </RequireAuth>
            }
          >
            {/* No company Routes */}

            <Route element={<PrivateLayout />}>
              <Route path="profile" element={<UserProfilePage />} />

              <Route
                path="settings"
                element={
                  <RequireRole allowedRoles={["admin"]}>
                    <SettingsPage />
                  </RequireRole>
                }
              />

              <Route
                path="team"
                element={
                  <RequireRole allowedRoles={["admin"]}>
                    <TeamPage />
                  </RequireRole>
                }
              />

              <Route index element={<Navigate to="/settings" />} />
            </Route>

            {/* Need company Routes */}

            <Route
              element={
                // Todo: remove RequireAvailableCompany after removing the company check
                <RequireAvailableCompany>
                  <PrivateLayout />
                </RequireAvailableCompany>
              }
            >
              <Route path="dashboard" element={<OmnichannelPage />} />
              <Route path="history" element={<HistoryPage />} />

              <Route
                path="analytics"
                element={
                  <RequireRole allowedRoles={["admin", "supervisor"]}>
                    <AnalyticsPage />
                  </RequireRole>
                }
              />

              <Route
                path="knowledgebase"
                element={
                  <RequireRole allowedRoles={["admin", "supervisor"]}>
                    <KBPage />
                  </RequireRole>
                }
              />

              <Route
                path="monitoring"
                element={
                  <RequireVersion allowedVersions={[3]}>
                    <RequireRole allowedRoles={["admin", "supervisor"]}>
                      <MonitoringPage />
                    </RequireRole>
                  </RequireVersion>
                }
              />

              <Route index element={<Navigate to="/dashboard" />} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default MainRouter;
