import React, { useState } from "react";
import axios from "axios";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { selectUser } from "store/user/selectors";
import { selectCompany } from "store/company/selectors";
import { NiceTextArea } from "components";

// eslint-disable-next-line react/prop-types
const ChatInterfaceInput = ({ resolved, agentChat, userId, selectedUser, setAgentChat, setMessages }) => {
  const { t } = useTranslation("omnichannel");
  const [message, setMessage] = useState("");
  const user = useAppSelector(selectUser);
  const requestData = useAppSelector(selectInitData);
  const { settings } = useAppSelector(selectCompany);

  const welcomeText = settings.operatorFirstMessage.replace("{NAME}", `${user.name}`);
  const [frequentAnswers, setFrequentAnswers] = useState([""]);

  const startAgentChat = async () => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": requestData.companyId,
        "RTNO-ADMIN-UUID": requestData.userId,
      },
    };
    const url = `${host}api/v1/dashboard/start_agent_chat/`;
    const data = {
      backend_user_id: selectedUser,
    };

    try {
      await axios.post(url, data, config);
      setAgentChat(true);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const sendAdminMessage = async message => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": requestData.companyId,
        "RTNO-ADMIN-UUID": requestData.userId,
      },
    };
    const url = `${host}api/v1/chat/agent/`;
    const data = {
      frontend_user_id: userId,
      admin_message: message,
    };

    try {
      await axios.post(url, data, config);

      setMessages(prevMessages => [
        ...prevMessages,
        {
          admin_id: null,
          backend_user_id: selectedUser,
          data: message,
          message_id: null,
          timestamp: new Date().toISOString().replace("Z", "+00:00"),
          type: "admin",
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    sendAdminMessage(message);
  };

  const handleFirstMessageSend = () => {
    sendAdminMessage(welcomeText);
  };

  const handleChatIntercept = async () => {
    const success = await startAgentChat();
    if (success) {
      handleFirstMessageSend();
    } else {
      console.error("Failed to start agent chat");
    }
  };

  if (resolved) return <div>{t("chatInterface.isCompleted")}</div>;

  if (agentChat)
    return (
      <NiceTextArea
        stopAgentChat={() => setAgentChat(false)}
        handleSubmit={handleSubmit}
        message={message}
        setMessage={setMessage}
        frequentAnswers={frequentAnswers}
        setFrequentAnswers={setFrequentAnswers}
      />
    );

  return (
    <div className="mt-4 flex items-center justify-center gap-4">
      <button
        onClick={handleChatIntercept}
        className="border-black flex items-center bg-main-blue hover:bg-other-blue py-2 px-6 rounded-full text-white font-semibold shadow-md transform transition-all duration-300 hover:shadow-lg hover:scale-105"
      >
        {t("chatInterface.catch")}
        <AiOutlineArrowRight className="inline-block ml-2" />
      </button>
    </div>
  );
};

export default ChatInterfaceInput;
