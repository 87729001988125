import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { AiOutlineCalendar, AiOutlineTeam } from "react-icons/ai";
import { MdTopic } from "react-icons/md";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { selectKB } from "store/knowledgeBase/selectors";
import { DatePicker } from "components";

// eslint-disable-next-line react/prop-types
const HistoryChatsFilter = ({ setChatData }) => {
  const { t } = useTranslation("history");
  const [showDateModal, setDateModal] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [showMainTopicsModal, setMainTopicsModal] = useState(false);
  const [showSubTopicsModal, setSubTopicsModal] = useState(false);
  const [selectedMainTopic, setSelectedMainTopic] = useState(null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const { mainTopics, topics } = useAppSelector(selectKB);

  const [teamMembers, setTeamMembers] = useState([]);
  const [showAssignedModal, setAssignedModal] = useState(false);
  const [selectedAssignedMember, setSelectedAssignedMember] = useState(null);
  const [selectedClosedMember, setSelectedClosedMember] = useState(null);

  const [showClosedModal, setClosedModal] = useState(false);

  const [rating, setRating] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("");

  const { companyId, userId } = useAppSelector(selectInitData);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const fetchedTeamMembers = useCallback(async () => {
    try {
      const response = await axios.get(`${host}api/v1/dashboard/analytics/admin_info/`, {
        params: {},
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });

      const teamMemberEmails = response.data.map(user => user.email);
      setTeamMembers(teamMemberEmails);
      localStorage.setItem("teamMembers", JSON.stringify(teamMemberEmails));
    } catch (error) {
      console.error(`Failed to fetch user count`, error);
    }
  }, [companyId, userId]);

  const handleSubmit = async event => {
    event.preventDefault();

    const params = {
      from_time: Math.floor(new Date(startDate).getTime()),
      to_time: Math.floor(new Date(endDate).getTime()),
      main_topic: selectedMainTopic,
      sub_topic: selectedSubTopic,
      assigned_to: selectedAssignedMember,
      responsible_for_closure: selectedClosedMember,
      rating: rating === "" ? null : rating,
      ...(phoneNumber && { phone: phoneNumber }),
      page: 1,
    };

    try {
      const response = await axios.get(`${host}api/v1/dashboard/history/user_chat_query/`, {
        params,
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });
      setChatData(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const cachedTeamMembers = localStorage.getItem("teamMembers");

    if (cachedTeamMembers) {
      setTeamMembers(JSON.parse(cachedTeamMembers));
    } else {
      fetchedTeamMembers();
    }
  }, [fetchedTeamMembers]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-4 items-center text-sm">
        <div className="flex items-center border-r pr-4">
          <div className="mr-2">
            {startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()}
          </div>
          <AiOutlineCalendar
            onClick={() => setDateModal(true)}
            className="hover:text-main-blue cursor-pointer transition duration-300 ease-in-out flex-shrink-0"
          />
        </div>
        <Modal
          className="bg-white rounded-xl p-10 mx-auto my-20 shadow-xl max-w-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onRequestClose={() => setDateModal(false)}
          isOpen={showDateModal}
        >
          <div className="max-w-2xl">
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={dates => {
                handleStartDateChange(dates[0]);
                handleEndDateChange(dates[1]);
              }}
            />
          </div>
        </Modal>

        <div className="flex items-center border-r pr-4">
          <div className="mr-2">
            {t("mainTopic")}{" "}
            {selectedMainTopic ? (
              <span className="truncate">{selectedMainTopic}</span>
            ) : (
              <span className="text-gray-500">{t("notSelected")}</span>
            )}
          </div>
          <MdTopic
            onClick={() => setMainTopicsModal(true)}
            className="hover:text-main-blue cursor-pointer transition duration-300 ease-in-out flex-shrink-0"
          />
        </div>
        <Modal
          className="bg-white rounded-xl p-10 mx-auto my-20 shadow-xl max-w-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onRequestClose={() => setMainTopicsModal(false)}
          isOpen={showMainTopicsModal}
        >
          <div className="flex flex-col">
            {mainTopics.map((topic, index) => (
              <button
                key={index}
                className={`${
                  selectedMainTopic === topic ? "bg-green-500" : "bg-blue-500"
                } text-white rounded-lg px-4 py-2 m-2`}
                onClick={() => setSelectedMainTopic(topic)}
              >
                {topic}
              </button>
            ))}
          </div>
        </Modal>

        <div className="flex items-center border-r pr-4">
          <div className="mr-2">
            {t("subTopic")}{" "}
            {selectedSubTopic ? (
              <span className="truncate">{selectedSubTopic}</span>
            ) : (
              <span className="text-gray-500">{t("notSelected")}</span>
            )}
          </div>
          <MdTopic
            onClick={() => setSubTopicsModal(true)}
            className="hover:text-main-blue cursor-pointer transition duration-300 ease-in-out flex-shrink-0"
          />
        </div>
        <Modal
          className="bg-white rounded-xl p-10 mx-auto my-20 shadow-xl max-w-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onRequestClose={() => setSubTopicsModal(false)}
          isOpen={showSubTopicsModal}
        >
          <div className="flex flex-col">
            {selectedMainTopic &&
              Object.keys(topics[selectedMainTopic]).map((topic, index) => (
                <button
                  key={index}
                  className={`${
                    selectedSubTopic === topic ? "bg-green-500" : "bg-blue-500"
                  } text-white rounded-lg px-4 py-2 m-2`}
                  onClick={() => setSelectedSubTopic(topic)}
                >
                  {topic}
                </button>
              ))}
          </div>
        </Modal>

        <div className="flex items-center border-r pr-4">
          <div className="mr-2 max-w-sm inline-block">
            {t("admin")}{" "}
            {selectedAssignedMember ? (
              <span className="w-1/4 truncate">{selectedAssignedMember}</span>
            ) : (
              <span className="text-gray-500">{t("notSelected")}</span>
            )}
          </div>
          <AiOutlineTeam
            onClick={() => setAssignedModal(true)}
            className="hover:text-main-blue cursor-pointer transition duration-300 ease-in-out flex-shrink-0"
          />
        </div>
        <Modal
          className="bg-white rounded-xl p-10 mx-auto my-20 shadow-xl max-w-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onRequestClose={() => setAssignedModal(false)}
          isOpen={showAssignedModal}
        >
          <div className="flex flex-col">
            {teamMembers.map((member, index) => (
              <button
                key={index}
                className={`${
                  selectedAssignedMember === member ? "bg-green-500" : "bg-blue-500"
                } text-white rounded-lg px-4 py-2 m-2`}
                onClick={() => setSelectedAssignedMember(member)}
              >
                {member}
              </button>
            ))}
          </div>
        </Modal>
        <div className="flex items-center border-r pr-4">
          <div className="mr-2 max-w-sm inline-block">
            {t("closedBy")}{" "}
            {selectedClosedMember ? (
              <span className="w-1/4 truncate">{selectedClosedMember}</span>
            ) : (
              <span className="text-gray-500">{t("notSelected")}</span>
            )}
          </div>
          <AiOutlineTeam
            onClick={() => setClosedModal(true)}
            className="hover:text-main-blue cursor-pointer transition duration-300 ease-in-out flex-shrink-0"
          />
        </div>
        <Modal
          className="bg-white rounded-xl p-10 mx-auto my-20 shadow-xl max-w-lg"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onRequestClose={() => setClosedModal(false)}
          isOpen={showClosedModal}
        >
          <div className="flex flex-col">
            {teamMembers.map((member, index) => (
              <button
                key={index}
                className={`${
                  selectedClosedMember === member ? "bg-green-500" : "bg-blue-500"
                } text-white rounded-lg px-4 py-2 m-2`}
                onClick={() => setSelectedClosedMember(member)}
              >
                {member}
              </button>
            ))}
          </div>
        </Modal>
        <div className="flex items-center">
          {t("rating")}
          <select className="bg-white text-black rounded-md p-2 mx-1" onChange={e => setRating(e.target.value)}>
            {["", 1, 2, 3, 4, 5].map(num => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center border-r pr-4">
          <div className="mr-2">
            {t("phone")}
            <input
              type="tel"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder={t("enterPhone")}
              className="bg-white text-black rounded-md p-2 mx-1"
            />
          </div>
        </div>

        <div>
          <BsFillArrowRightSquareFill
            onClick={event => handleSubmit(event)}
            size={35}
            className="cursor-pointer hover:text-main-blue transition duration-300 ease-in-out"
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryChatsFilter;
