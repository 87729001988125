import { ReportProblemModal } from "components";
import React, { memo, useState } from "react";
import { MdReport } from "react-icons/md";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="relative h-screen">
      <Outlet />

      <div onClick={() => setModalOpen(true)} className="fixed bottom-4 right-4">
        <MdReport className="text-2xl z-[1000] cursor-pointer hover:text-main-blue hover:scale-110 transition duration-300 ease-in-out" />
      </div>

      <ReportProblemModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} />
    </div>
  );
};

export default memo(MainLayout);
