import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsKeyFill } from "react-icons/bs";
import { AiOutlineSetting, AiFillRobot } from "react-icons/ai";
import { TopList, ToggleSwitch, Tooltip } from "components";
import { CompanyForm, SecretKeys, Integrations, ExtraSettings, BotSettings } from "./blocks";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState("Основные");
  const { t } = useTranslation("profile");

  const filters = [
    {
      filterName: "Основные",
      icon: AiOutlineSetting,
      onClick: () => setActiveTab("Основные"),
    },
    {
      filterName: "Настройки бота",
      icon: AiFillRobot,
      onClick: () => setActiveTab("Настройки бота"),
    },
    {
      filterName: "Интеграции и ключи",
      icon: BsKeyFill,
      onClick: () => setActiveTab("Интеграции и ключи"),
    },
  ];

  return (
    <div className="flex flex-col p-4">
      <h2 className="text-xl  mb-2 font-bold">{t("headerName")}</h2>
      <TopList filters={filters} activeFilter={activeTab} />
      <hr className="mb-4" />
      <div className="flex flex-col items-center justify-center">
        {activeTab === "Основные" && (
          <>
            <CompanyForm />
            <ExtraSettings />
          </>
        )}
        {activeTab === "Интеграции и ключи" && <Integrations />}
        {activeTab === "Интеграции и ключи" && <SecretKeys />}
        {activeTab === "Настройки бота" && <BotSettings />}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ToggleSwitch />
        <Tooltip
          message="Стоп - кран"
          description="Включение этой опции будет принудительно переправлять все обращения вашей компании на живых
                    операторов в обход ИИ-бота"
        />
      </div>
    </div>
  );
};

export default SettingsPage;
