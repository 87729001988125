/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
import React, { useState } from "react";

function Tooltip({ message, description, color = "gray-700" }) {
  const [hover, setHover] = useState(false);

  return (
    <div className="flex z-[1000]">
      <div
        className="relative flex items-center text-gray-500 cursor-pointer hover:text-gray-600"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p className={`text-${color} text-sm font-bold`}>{message}</p>
        <div className="relative">
          {hover && (
            <div
              className="absolute bottom-0 inline-block w-64 px-4 py-3 mb-10 -ml-32 text-white bg-main-blue rounded-lg"
              style={{
                transition: "ease-out duration-300",
                opacity: "100",
                transform: "scale(0.85)",
              }}
            >
              <span className="inline-block text-sm leading-tight">{description}</span>
              <span
                className="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-main-blue"
                style={{ left: "50%" }}
              />
            </div>
          )}
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Tooltip;
