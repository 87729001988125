import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsPersonFill, BsPeopleFill, BsFillPersonCheckFill } from "react-icons/bs";
import { FaPhone, FaTag } from "react-icons/fa";
import axios from "axios";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { selectUser } from "store/user/selectors";
import { selectCompany } from "store/company/selectors";
import { TopList } from "components";
import AdminChats from "./AdminChats";
import ActiveChats from "./ActiveChats";
import ResolvedChats from "./ResolvedChats";

// eslint-disable-next-line react/prop-types
const ChatList = ({ selectedUser, setSelectedUser, setUserId }) => {
  const { t } = useTranslation("omnichannel");
  const { companyId, userId } = useAppSelector(selectInitData);
  const user = useAppSelector(selectUser);
  const { settings } = useAppSelector(selectCompany);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchMode, setSearchMode] = useState("phone");
  const [loading, setLoading] = useState(true);
  const [chatFilter, setChatFilter] = useState(
    settings.version === 3 ? t("chatList.filters.mine") : t("chatList.filters.active"),
  );

  const filters = [
    ...(settings.version === 3
      ? [
          {
            filterName: t("chatList.filters.mine"),
            icon: BsPersonFill,
            onClick: () => setChatFilter(t("chatList.filters.mine")),
          },
        ]
      : []),
    ...(user.role !== "operator"
      ? [
          {
            filterName: t("chatList.filters.active"),
            icon: BsPeopleFill,
            onClick: () => setChatFilter(t("chatList.filters.active")),
          },
        ]
      : []),
    ...(settings.version === 3
      ? [
          {
            filterName: t("chatList.filters.resolved"),
            icon: BsFillPersonCheckFill,
            onClick: () => setChatFilter(t("chatList.filters.resolved")),
          },
        ]
      : []),
  ];

  // function to fetch different chats
  const fetchChatData = async (status, page) => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      },
    };

    let url = `${host}api/v1/dashboard/fetch_users_for_dashboard/?status=${status}&page=${page}`;
    // eslint-disable-next-line eqeqeq
    if (status === "assigned" || (status == "resolved" && user.role == "operator")) {
      url += `&frontend_uuid=${userId}`;
    }
    const response = await axios.get(url, config);

    console.log({ chatData: response });

    return response.data.map(user => ({
      backend_user_id: user.backend_user_id,
      frontend_user_id: user.frontend_user_id,
      last_message: user.last_message,
      read: user.read,
      phone: user.phone,
      main_topic: user.main_topic,
      sub_topic: user.sub_topic,
      timestamp: user.timestamp,
      last_message_type: user.last_message_type,
      status: user.status,
    }));
  };

  const pageSize = 19;

  // Function to make the time in each chat look normal
  const formatDateToMoscowTime = timestamp => {
    const moscowOffset = 0;
    const date = new Date(Date.parse(timestamp) + moscowOffset);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("ru-RU", options);
  };

  const filteredChats = chats => {
    if (searchMode === "topic" && searchTerm !== "") {
      return chats.filter(chat => chat.main_topic && chat.main_topic.includes(searchTerm));
    }
    if (searchMode === "phone" && searchTerm !== "") {
      return chats.filter(chat => chat.phone && chat.phone.includes(searchTerm));
    }
    return chats;
  };

  return (
    <div className="p-4 h-screen overflow-y-auto">
      <div className="text-xl font-bold mb-2">{t("chatList.headerName")}</div>
      <div className="flex items-center justify-between">
        <TopList filters={filters} activeFilter={chatFilter} />
        {!selectedUser && (
          <div className="flex items-center gap-2 mr-10">
            <input
              type="text"
              placeholder={t(`chatList.searchBy.${searchMode}`)}
              onChange={e => setSearchTerm(e.target.value)}
              className="w-full p-2 rounded border ml-2"
            />
            <div
              onClick={() => setSearchMode("topic")}
              className={`cursor-pointer ${searchMode === "topic" ? "text-blue-500" : "text-gray-400"}`}
            >
              <FaTag size={20} />
            </div>
            <div
              onClick={() => setSearchMode("phone")}
              className={`cursor-pointer ${searchMode === "phone" ? "text-blue-500" : "text-gray-400"}`}
            >
              <FaPhone size={20} />
            </div>
          </div>
        )}
      </div>

      <hr className="mb-4" />

      <div className="flex flex-col space-y-4">
        {chatFilter === t("chatList.filters.mine") && settings.version === 3 && (
          <AdminChats
            loading={loading}
            fetchChatData={fetchChatData}
            filteredChats={filteredChats}
            pageSize={pageSize}
            setLoading={setLoading}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setUserId={setUserId}
            formatDateToMoscowTime={formatDateToMoscowTime}
          />
        )}
        {chatFilter === t("chatList.filters.active") && (
          <ActiveChats
            loading={loading}
            fetchChatData={fetchChatData}
            filteredChats={filteredChats}
            pageSize={pageSize}
            setLoading={setLoading}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setUserId={setUserId}
            formatDateToMoscowTime={formatDateToMoscowTime}
          />
        )}
        {chatFilter === t("chatList.filters.resolved") && (
          <ResolvedChats
            loading={loading}
            fetchChatData={fetchChatData}
            filteredChats={filteredChats}
            pageSize={pageSize}
            setLoading={setLoading}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setUserId={setUserId}
            formatDateToMoscowTime={formatDateToMoscowTime}
          />
        )}
      </div>
    </div>
  );
};

export default ChatList;
