import { BotSettings } from "entities/config";
import axios from "utils/axios";

type UpdateBotSettingsRequest = BotSettings & { name: string; description: string; companyId: string };

const updateBotSettings = async ({
  name,
  description,
  personality,
  language,
  useEmojis,
  companyId,
}: UpdateBotSettingsRequest) => {
  const data = {
    companyName: companyId,
    prompts: [name, description, personality, language, useEmojis],
  };

  return axios.put(`api/v1/config/update/`, data);
};

export default updateBotSettings;
