export enum UserRole {
  ADMIN = "admin",
  OPERATOR = "operator",
  SUPERVISOR = "supervisor",
}

export enum UserActivityStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  BUSY = "busy",
  AWAY = "windingDown",
  OFFLINE = "offline",
}

export type User = {
  email: string;
  role: UserRole;
  name: string;
  lastName: string;
  status: UserActivityStatus;
};
