import axios from "utils/axios";

const getExtraSettings = async () => {
  const url = `api/v1/config/load/`;

  const params = {
    object_name_list: [
      "need_phone",
      "memory_timeout",
      "need_resolve_verification",
      "resolve_verification_timeout",
      "operator_first_message",
    ],
  };

  return axios.get(url, { params });
};

export default getExtraSettings;
