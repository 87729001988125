import React from "react";
import { useTranslation } from "react-i18next";
import { useNotification } from "contexts/NotificationContext";

// eslint-disable-next-line react/prop-types
const Notification = ({ data }) => {
  const { t } = useTranslation("helper");
  const { removeNotification } = useNotification();

  const handleClose = () => {
    removeNotification(data);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg mx-auto w-full max-w-md relative mt-10 border border-gray-300">
      <div className="p-4 flex justify-between items-center">
        <span className="text-xs font-semibold text-white px-3 py-1 rounded-lg bg-green-500 mr-3">
          {t("notification.helpNeeded")}
        </span>
        <button
          className="p-2 hover:bg-gray-100 rounded-full transition duration-200 focus:outline-none"
          onClick={handleClose}
        >
          <svg
            width="16"
            height="16"
            fill="currentColor"
            className="text-gray-600 hover:text-gray-800 transition duration-200"
          >
            {/* eslint-disable-next-line react/self-closing-comp */}
            <path d="M4.293 4.293a1 1 0 011.414 0L8 6.586l2.293-2.293a1 1 0 111.414 1.414L9.414 8l2.293 2.293a1 1 0 01-1.414 1.414L8 9.414l-2.293 2.293a1 1 0 01-1.414-1.414L6.586 8 4.293 5.707a1 1 0 010-1.414z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Notification;
