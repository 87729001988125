import Axios, { AxiosResponse } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { getRequestData } from "utils/auth";

const axios = applyCaseMiddleware(
  Axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    responseType: "json",
    baseURL: process.env.REACT_APP_HOST,
  }),
  {
    ignoreHeaders: true,
  },
);

// TODO: remove this middleware after backend refactoring
// use it if your request needs companyId and userId
// not knowledge base requests because of camel case

axios.interceptors.request.use(
  config => {
    const newConfig = { ...config };
    const { companyId, userId } = getRequestData();

    if (companyId && userId) {
      newConfig.headers["RTNO-COMPANY-ID"] = companyId;
      newConfig.headers["RTNO-ADMIN-UUID"] = userId;
    }

    return newConfig;
  },
  error => Promise.reject(error),
);

export type Response<T> = AxiosResponse<T>;
export default axios;
