import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

Modal.setAppElement("#root");

// eslint-disable-next-line react/prop-types
const ReportProblemModal = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation("helper");
  const [imageFile, setImageFile] = useState(null);
  const [report, setReport] = useState("");
  const { companyId, userId } = useAppSelector(selectInitData);

  const handleImageChange = e => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleSend = async () => {
    const formData = new FormData();
    formData.append("report", report);
    formData.append("email", userId);
    if (imageFile) {
      formData.append("image", imageFile);
    }

    try {
      await axios.put(`${host}api/v1/team/send_report/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });

      setReport("");
      setImageFile(null);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Report Problem"
      closeTimeoutMS={500}
      className="max-w-lg mx-auto mt-10 p-6 bg-white rounded-lg shadow-md focus:outline-none transform transition-transform duration-300"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
    >
      <h1 className="text-2xl font-bold mb-4">{t("problemModal.letUsKnow")}</h1>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">{t("problemModal.attachImage")}</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="text-sm border p-2 rounded w-full focus:outline-none focus:border-blue-500 transition duration-150"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">{t("problemModal.describeProblem")}</label>
        <textarea
          rows="4"
          value={report}
          onChange={e => setReport(e.target.value)}
          className="w-full p-3 rounded border focus:outline-none focus:border-blue-500 transition duration-150"
        />
      </div>

      <div className="flex justify-end">
        <button onClick={onRequestClose} className="mr-2 text-gray-700 hover:text-gray-900 transition duration-150">
          {t("problemModal.cancel")}
        </button>
        <button
          onClick={() => {
            handleSend();
            onRequestClose();
          }}
          className="bg-blue-500 text-white px-5 py-2 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-700 transition duration-150"
        >
          {t("problemModal.send")}
        </button>
      </div>
    </Modal>
  );
};

export default ReportProblemModal;
