/* eslint-disable react/prop-types */
import React from "react";
import { FaCaretUp, FaUser, FaCaretDown } from "react-icons/fa";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "utils/hooks";
import { host } from "constants";
import { selectInitData } from "store/auth/selectors";
import { selectCompany } from "store/company/selectors";

const UserInfo = ({ openCards, toggleCard, selectedUser, userInfo, setUserInfo }) => {
  const { t } = useTranslation("omnichannel");
  const { companyId, userId } = useAppSelector(selectInitData);
  const { settings } = useAppSelector(selectCompany);

  const updateUserDetails = async (field, value) => {
    const url = `${host}api/v1/dashboard/input_user_${field}/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    const params = {
      backend_user_id: selectedUser,
      [field]: value,
    };
    try {
      await axios.put(url, {}, { headers, params });
      setUserInfo(prevInfo => ({ ...prevInfo, [field]: value }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleCopyClick = event => {
    event.stopPropagation();
  };

  const transformPhoneToCorrectFormat = phone => {
    let justDigits = phone.replace(/\D/g, "");

    if (justDigits.length === 11) {
      if (justDigits.startsWith("7")) {
        return justDigits;
      }
      justDigits = `7${justDigits.substring(1)}`;
      return justDigits;
    }
    return "Invalid input. Please provide an 11-digit phone number.";
  };

  return (
    <div className="flex flex-col p-3 rounded-lg border border-blue-200 transition ease-in-out duration-250 group">
      {openCards.includes(0) ? (
        <div className="flex flex-col">
          <div className="flex items-center space-x-2 pt-3 mb-3">
            <div onClick={() => toggleCard(0)}>
              <FaCaretUp className="cursor-pointer" />
            </div>
            <FaUser className="flex-shrink-0 group-hover:text-main-blue transition duration-300 ease-in-out" />
            <input
              type="text"
              placeholder={t("chatInfo.info.phone")}
              defaultValue={userInfo.phone}
              className="border border-blue-100 hover:bg-gray-200 focus:bg-blue-100  rounded p-1 bg-transparent focus:outline-none flex-grow transition duration-150 ease-in-out"
              onBlur={e => updateUserDetails("phone", e.target.value)}
            />
          </div>
          <CopyToClipboard text={userInfo.phone}>
            <AiFillCopy
              onClick={handleCopyClick}
              className="cursor-pointer ml-2 hover:text-main-blue transition duration-300 ease-in-out"
            />
          </CopyToClipboard>
          <input
            type="text"
            placeholder={t("chatInfo.info.name")}
            // eslint-disable-next-line react/prop-types
            defaultValue={userInfo.name}
            className="border border-blue-100 hover:bg-gray-200 focus:bg-blue-100 mb-3 rounded p-1 bg-transparent focus:outline-none transition duration-150 ease-in-out"
            onBlur={e => updateUserDetails("name", e.target.value)}
          />
          <textarea
            type="text"
            placeholder={t("chatInfo.info.description")}
            defaultValue={userInfo.description}
            className="border border-blue-100 hover:bg-gray-200 focus:bg-blue-100 mb-3 rounded p-1 bg-transparent focus:outline-none h-20 resize-none transition duration-150 ease-in-out"
            onBlur={e => updateUserDetails("description", e.target.value)}
          />
        </div>
      ) : (
        <div className="flex justify-between items-center py-3 space-x-2 ">
          <div className="flex items-center gap-2">
            <FaCaretDown onClick={() => toggleCard(0)} className="cursor-pointer" />

            <FaUser className="group-hover:text-main-blue transition duration-300 ease-in-out" />
            <div>{userInfo.phone}</div>
            <CopyToClipboard text={userInfo.phone}>
              <AiFillCopy
                onClick={handleCopyClick}
                className="cursor-pointer  hover:text-main-blue transition duration-300 ease-in-out flex-shrink-0"
              />
            </CopyToClipboard>
          </div>
          {userInfo.phone && settings.version === 3 && (
            <BsFillArrowRightSquareFill
              className="cursor-pointer  hover:text-main-blue transition duration-300 ease-in-out flex-shrink-0"
              onClick={() => {
                window.open(
                  `https://adm.berizaryad.ru/admin/orders?utf8=%E2%9C%93&q%5Buser_phone_equals%5D=${transformPhoneToCorrectFormat(
                    userInfo.phone,
                  )}&commit=%D0%A4%D0%B8%D0%BB%D1%8C%D1%82%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C&order=id_desc`,
                  "_blank",
                );
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserInfo;
