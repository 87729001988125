import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

// eslint-disable-next-line react/prop-types
const KeyMetrics = ({ range }) => {
  const { t } = useTranslation("analytics");
  const [botMessageCount, setBotMessageCount] = useState(0);
  const [userMessageCount, setUserMessageCount] = useState(0);
  const [adminMessageCount, setAdminMessageCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [resolvedChatCount, setResolvedChatCount] = useState(0);
  const [totalChatCount, setTotalChatCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { companyId, userId } = useAppSelector(selectInitData);

  const metricsData = [
    {
      title: t("keyMetrics.totalUsers"),
      value: isLoading ? "---" : userCount,
      color: "bg-purple-50",
      icon: "👤",
    },
    {
      title: t("keyMetrics.userQuestions"),
      value: isLoading ? "---" : userMessageCount,
      color: "bg-blue-50",
      icon: "🤔",
    },
    {
      title: t("keyMetrics.botAnswers"),
      value: isLoading ? "---" : botMessageCount,
      color: "bg-green-50",
      icon: "🤖",
    },
    {
      title: t("keyMetrics.totalChats"),
      value: isLoading ? "---" : totalChatCount,
      color: "bg-yellow-50",
      icon: "💬",
    },
    {
      title: t("keyMetrics.adminAnswers"),
      value: isLoading ? "---" : adminMessageCount,
      color: "bg-red-50",
      icon: "👥",
    },
    {
      title: t("keyMetrics.resolvedChats"),
      value: isLoading ? "---" : resolvedChatCount,
      color: "bg-indigo-50",
      icon: "✅",
    },
  ];

  const fetchChatCount = useCallback(
    async type => {
      const oneDay = 24 * 60 * 60 * 1000;
      const now = Date.now();
      const startTime = now - range * oneDay;

      const params = {
        from_time: startTime,
        to_time: now,
      };

      if (type) {
        params.type = type;
      }

      try {
        const response = await axios.get(`${host}api/v1/dashboard/analytics/user_chat_count/`, {
          params,
          headers: {
            "RTNO-COMPANY-ID": companyId,
            "RTNO-ADMIN-UUID": userId,
          },
        });
        return response.data ? response.data : 0;
      } catch (error) {
        return console.error(`Failed to fetch chat count for type ${type}:`, error);
      }
    },
    [companyId, range, userId],
  );

  const fetchMessageCount = useCallback(
    async type => {
      const oneDay = 24 * 60 * 60 * 1000;
      const now = Date.now();
      const startTime = now - range * oneDay;

      try {
        const response = await axios.get(`${host}api/v1/dashboard/analytics/messages_count/`, {
          params: {
            from_time: startTime,
            to_time: now,
            type,
          },
          headers: {
            "RTNO-COMPANY-ID": companyId,
            "RTNO-ADMIN-UUID": userId,
          },
        });
        return response.data ? response.data : 0;
      } catch (error) {
        return console.error(`Failed to fetch message count for type ${type}:`, error);
      }
    },
    [companyId, range, userId],
  );

  const getUsersCount = useCallback(async () => {
    const oneDay = 24 * 60 * 60 * 1000;
    const now = Date.now();
    const startTime = now - range * oneDay;

    try {
      const response = await axios.get(`${host}api/v1/dashboard/analytics/users_count/`, {
        params: {
          from_time: startTime,
          to_time: now,
        },
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });
      setUserCount(response.data ? response.data : 0);
    } catch (error) {
      console.error(`Failed to fetch user count`, error);
    }
  }, [companyId, range, userId]);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      fetchMessageCount("bot").then(data => setBotMessageCount(data)),
      fetchMessageCount("user").then(data => setUserMessageCount(data)),
      fetchMessageCount("supervisor").then(data => setAdminMessageCount(data)),
      fetchChatCount("resolved").then(data => setResolvedChatCount(data)),
      fetchChatCount().then(data => setTotalChatCount(data)),
      getUsersCount(),
    ])
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, [fetchChatCount, fetchMessageCount, getUsersCount, range]);

  return (
    <div>
      <h2 className="text-md ml-4 mb-10 text-gray-800 mt-10 font-semibold">{t("keyMetrics.header")}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6 mx-4">
        {metricsData.map(metric => (
          <div
            key={metric.title}
            className={`p-6 shadow-md rounded-lg transition-transform transform hover:scale-105 ${metric.color}`}
          >
            <h3 className="text-lg font-semibold mb-1 flex items-center">
              <span className="mr-2">{metric.icon}</span>
              {metric.title}
            </h3>
            <p className="text-xl text-gray-800 font-bold">{metric.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyMetrics;
