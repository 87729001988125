/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

// eslint-disable-next-line react/prop-types
const Button = ({ onClick = () => {}, message, disabled, type = "" }) => {
  // Functions to handle adding and removing from list
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleKeyPress = (setter, list) => e => {
    if (e.key === "Enter") {
      const value = e.target.value.trim();
      if (value && !list.includes(value)) {
        setter([...list, value]);
      }
      e.target.value = "";
      e.preventDefault();
    }
  };

  const removeValueFromList = (setter, list, valueToRemove) => {
    setter(list.filter(value => value !== valueToRemove));
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`border rounded-xl px-4 py-2 relative overflow-x-hidden flex items-center justify-center transition-all ease-in-out duration-300 
        ${
          disabled
            ? "border-gray-300 cursor-not-allowed"
            : "border-black cursor-pointer hover:border-blue-500 hover:scale-110 group"
        }`}
    >
      <div
        className={`absolute h-1/5 w-1/5 bg-blue-500 top-0 -right-5  opacity-[0%] rounded-lg ease-in-out duration-500 transition  transition-all  
        ${
          disabled ? "group-hover:opacity-[0%]" : "group-hover:opacity-[100%]"
        } group-hover:right-0 group-hover:w-full group-hover:h-full`}
      />
      <div
        className={`absolute h-1/5 w-1/5 bg-blue-500 bottom-0 -left-5  opacity-[0%] rounded-lg ease-in-out duration-500 transition  transition-all  
        ${
          disabled ? "group-hover:opacity-[0%]" : "group-hover:opacity-[100%]"
        } group-hover:left-0 group-hover:w-full group-hover:h-full`}
      />
      <div
        className={`z-[100] flex flex-col justify-center 
        ${
          disabled ? "text-gray-300" : "group-hover:text-white"
        } transition-all ease-in-out duration-500 group-hover:scale-110`}
      >
        {message}
      </div>
    </button>
  );
};

export default Button;
