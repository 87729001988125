/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ResolvedChats = ({
  loading,
  fetchChatData,
  filteredChats,
  pageSize,
  setLoading,
  selectedUser,
  setSelectedUser,
  setUserId,
  formatDateToMoscowTime,
}) => {
  const { t } = useTranslation("omnichannel");
  const [resolvedChatData, setResolvedChatData] = useState([]);
  const [resolvedPage, setResolvedPage] = useState(1);
  const [hasMoreResolvedChats, setHasMoreResolvedChats] = useState(false);

  const fetchResolvedChatData = useCallback(async () => {
    try {
      const resolvedChatData = await fetchChatData("resolved", 1);
      setResolvedChatData(resolvedChatData);
      setHasMoreResolvedChats(resolvedChatData.length > pageSize);
      setLoading(false);
    } catch (error) {
      console.error("fetch resolved chat data error:", error);
    }
  }, [fetchChatData, pageSize, setLoading]);

  const fetchNextPage = async () => {
    try {
      const nextPageChatData = await fetchChatData("resolved", resolvedPage + 1);
      setResolvedPage(prevPage => prevPage + 1);
      setResolvedChatData(nextPageChatData);
      setHasMoreResolvedChats(nextPageChatData.length > pageSize);
    } catch (error) {
      console.error("fetch next page error", { error });
    }
  };

  const fetchPreviousPage = async () => {
    try {
      const nextPageChatData = await fetchChatData("resolved", resolvedPage - 1);

      setResolvedPage(prevPage => prevPage - 1);
      setResolvedChatData(nextPageChatData);
      setHasMoreResolvedChats(nextPageChatData.length > pageSize);
    } catch (error) {
      console.error("fetch previous page error", { error });
    }
  };

  useEffect(() => {
    fetchResolvedChatData();
  }, [fetchResolvedChatData]);

  return (
    <div>
      {loading ? (
        <div className="p-3 rounded-lg bg-gray-100 animate-pulse">
          <div className="h-5 bg-gray-200 rounded" />
          <div className="mt-2 h-4 bg-gray-300 rounded w-3/4" />
        </div>
      ) : (
        <div className="flex flex-col">
          {filteredChats([...resolvedChatData])
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map(chat => (
              <div
                key={chat.frontend_user_id}
                className={`relative flex px-2 py-4 space-x-4 rounded-lg cursor-pointer border border-blue-200 ${
                  selectedUser === chat.backend_user_id ? "bg-gradient-to-r from-blue-100 to-blue-50" : "bg-transparent"
                } hover:bg-gradient-to-r hover:from-blue-100 hover:to-blue-50 transition ease-in-out duration-300 mb-4`}
                onClick={() => {
                  setSelectedUser(chat.backend_user_id);
                  setUserId(chat.frontend_user_id);
                }}
              >
                <div className="grid gap-4 grid-cols-12 w-full items-center">
                  <div className="flex flex-col col-span-4">
                    <div className="ml-4">
                      {chat.phone ? chat.phone : `${t("chatList.client")} ${chat.backend_user_id}`}
                    </div>
                  </div>

                  {!selectedUser && (
                    <div className="text-gray-400 text-sm mx-4 col-span-3 truncate whitespace-nowrap">
                      {formatDateToMoscowTime(chat.timestamp)}
                    </div>
                  )}

                  {!selectedUser && (
                    <div className="flex-end text-gray-600 text-right col-span-5 text-sm">
                      {t("chatList.topic")} {chat.main_topic ? chat.main_topic : "---"}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {resolvedPage > 1 && (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 shadow-md"
          onClick={fetchPreviousPage}
          disabled={false}
        >
          предыдущая
        </button>
      )}
      {hasMoreResolvedChats && (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 shadow-md"
          onClick={fetchNextPage}
          disabled={false}
        >
          следующая
        </button>
      )}
    </div>
  );
};

export default ResolvedChats;
