/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { FaChevronRight, FaCircle } from "react-icons/fa";
import { useWebSocket } from "contexts/WebsocketProvider";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { Modal, ElapsedTime } from "components";
import TeamMemberChatList from "./ChatList";

// eslint-disable-next-line react/prop-types
const MonitoringMain = ({ selectedUser, setSelectedUser, teamMembers, setTeamMembers }) => {
  const { t } = useTranslation("monitoring");
  const { companyId, userId } = useAppSelector(selectInitData);
  const ws = useWebSocket();

  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedTeamMemberEmail, setSelectedTeamMemberEmail] = useState("");
  const [adminRate, setAdminRate] = useState(10);
  const [showModal, setShowModal] = useState(false);

  const setAdminRateFromConfig = useCallback(async () => {
    const url = `${host}api/v1/config/load/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    const params = {
      object_name_list: ["admin_rate"],
    };
    try {
      const response = await axios.get(url, {
        params,
        headers,
      });

      setAdminRate(response.data.admin_rate);
    } catch (error) {
      console.error(error);
    }
  }, [companyId, userId]);

  const getAdminData = useCallback(async () => {
    try {
      const response = await axios.get(`${host}api/v1/dashboard/analytics/admin_info/`, {
        params: {},
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });

      const fetchedTeamMembers = response.data
        .filter(member => member.frontend_uuid !== null)
        .map(member => {
          if (member.frontend_uuid === userId && member.status === "offline") {
            return { ...member, status: "active" };
          }
          return member;
        });

      setTeamMembers(fetchedTeamMembers);
    } catch (error) {
      console.error(`Failed to fetch user count`, error);
    }
  }, [companyId, userId, setTeamMembers]);

  const getStatusIcon = status => {
    const size = 12;
    switch (status) {
      case "active":
        return <FaCircle color="green" size={size} />;
      case "busy":
        return <FaCircle color="orange" size={size} />;
      case "inactive":
        return <FaCircle color="red" size={size} />;
      case "windingDown":
        return <FaCircle color="blue" size={size} />;
      case "offline":
        return <FaCircle color="gray" size={size} />;
      default:
        return null;
    }
  };

  const updateAdminRate = async () => {
    setShowModal(false);
    const url = `${host}api/v1/team/set_admin_rate/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
      "Content-Type": "application/json",
    };
    const data = {
      admin_rate: parseInt(adminRate, 10),
    };

    try {
      await axios.put(url, data, { headers });
    } catch (error) {
      console.error(error);
    }
  };

  const sortedTeamMembers = teamMembers.sort((a, b) => {
    const statusOrder = ["active", "inactive", "offline"];
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  });

  useEffect(() => {
    setAdminRateFromConfig();
  }, [setAdminRateFromConfig]);

  useEffect(() => {
    if (ws) {
      const newAdminStatusListener = event => {
        const uint8Array = new Uint8Array(event);
        const stringData = new TextDecoder().decode(uint8Array);
        const newStatus = JSON.parse(stringData);

        const isStatusChanged = teamMembers.some(
          member => member.admin_id === newStatus.admin_id && member.status !== newStatus.status,
        );

        if (isStatusChanged) {
          const updatedTeamMembers = teamMembers.map(member => {
            if (member.admin_id === newStatus.admin_id) {
              return {
                ...member,
                status: newStatus.status,
                status_timestamp: Date.now(),
              };
            }
            return member;
          });

          setTeamMembers(updatedTeamMembers);
        }
      };

      ws.on("connect", getAdminData);
      ws.on("new_admin_status", newAdminStatusListener);

      return () => {
        ws.off("new_admin_status", newAdminStatusListener);
        ws.off("connect", getAdminData);
      };
    }

    return () => {};
  }, [ws, teamMembers, getAdminData, setTeamMembers]);

  useEffect(() => {
    getAdminData();
    const intervalId = setInterval(getAdminData, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getAdminData]);

  return (
    <div className={` h-screen overflow-y-auto`}>
      <div className="flex items-center mb-2">
        <div className="text-xl font-bold mr-3">{t("main.headerName")}</div>
      </div>
      <hr className="mb-4" />
      {!selectedUser && (
        <div className="flex items-center gap-2">
          <label>{t("main.maxChats")}</label>
          <input
            value={adminRate}
            onChange={event => {
              setAdminRate(event.target.value);
            }}
            onKeyPress={event => {
              if (event.key === "Enter") {
                setShowModal(true);
              }
            }}
          />
        </div>
      )}
      <div className="">
        {sortedTeamMembers.map(member => {
          if (!member) return;
          // eslint-disable-next-line consistent-return
          return (
            <div key={member.email}>
              <div className="flex  flex-col justify-between bg-white p-4 my-2 rounded-lg border border-blue-200 ">
                <div
                  onClick={() => {
                    if (selectedTeamMember === member.frontend_uuid) {
                      setSelectedTeamMember(null);
                      setSelectedTeamMemberEmail("");
                    } else {
                      setSelectedTeamMember(member.frontend_uuid);
                      setSelectedTeamMemberEmail(member.email);
                    }
                  }}
                  className="flex items-center justify-between cursor-pointer border-b pb-3"
                >
                  <div className="flex items-center mr-2">
                    <span className={`text-gray-700 font-semibold mr-2 ${selectedUser && "truncate w-3/4"} `}>
                      {member.first_name ? member.first_name : member.email} {member.last_name ? member.last_name : ""}
                    </span>
                    {getStatusIcon(member.status)}
                    {member.status !== "offline" && !selectedUser && member.status_timestamp !== null && (
                      <div className="ml-4">
                        <ElapsedTime
                          timestamp={member.status_timestamp}
                          color={member.status !== "inactive" ? "text-gray-500" : "text-red-400"}
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex items-center">
                    {!selectedUser && (
                      <div className="group relative">
                        <div
                          className="absolute -top-10 left-0 text-xs 
      opacity-0 transition-all duration-500 
      group-hover:-top-10 group-hover:-left-20 hidden group-hover:block group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                        >
                          {t("main.activeChats")}
                        </div>
                        <span className="text-blue-700 truncate bg-blue-200 rounded-xl px-2 py-0.5 mr-2">
                          {member.active_chats}
                        </span>
                      </div>
                    )}

                    {!selectedUser && (
                      <div className="group relative">
                        <div
                          className="absolute -top-10 left-0 text-xs 
      opacity-0 transition-all duration-500 
      group-hover:-top-10 group-hover:-left-20 hidden group-hover:block group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                        >
                          {t("main.assignedChats")}
                        </div>
                        <span className="text-red-700 truncate bg-red-200 rounded-xl px-2 py-0.5 mr-2">
                          {member.assigned_chats}
                        </span>
                      </div>
                    )}

                    {!selectedUser && (
                      <div className="group relative">
                        <div
                          className="absolute -top-10 left-0 text-xs 
      opacity-0 transition-all duration-500 
      group-hover:-top-10 group-hover:-left-20 hidden group-hover:block group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                        >
                          {t("main.resolvedChats")}
                        </div>
                        <span className="text-gray-700 truncate bg-gray-200 rounded-xl px-2 py-0.5 mr-2">
                          {member.resolved_chats_today}
                        </span>
                      </div>
                    )}

                    {!selectedUser && <span className="text-gray-500 ml-4 truncate ">{member.role}</span>}
                    <FaChevronRight
                      className={`ml-2 transition-transform duration-300 transform ${
                        selectedTeamMember === member.frontend_uuid ? "rotate-90" : ""
                      }`}
                    />
                  </div>
                </div>
                {selectedTeamMember === member.frontend_uuid && (
                  <TeamMemberChatList
                    selectedTeamMember={selectedTeamMember}
                    selectedTeamMemberEmail={selectedTeamMemberEmail}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        onConfirm={updateAdminRate}
        topMessage={t("main.topMessage")}
        action={t("main.action")}
      >
        {t("main.changeMaxChats")}
      </Modal>
    </div>
  );
};

export default MonitoringMain;
