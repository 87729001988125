import React from "react";

const Loading = () => (
  <div className="flex justify-center items-center h-screen w-full">
    <div style={{ width: "100px", height: "100px" }} className="animate-spin">
      <div
        className="h-full w-full border-4 border-t-blue-500
           border-b-blue-700 rounded-full"
      />
    </div>
  </div>
);

export default Loading;
