import { createReducer } from "@reduxjs/toolkit";
import { fetchKB, setKB } from "./actions";
import { StoreState } from "./types";

const initialState: StoreState = {
  object: {},
  mainTopics: [],
  topics: {},
  loading: false,
};

const companyReducer = createReducer(initialState, builder => {
  builder
    .addCase(fetchKB.pending, state => {
      state.loading = true;
    })
    .addCase(fetchKB.fulfilled, (state, action) => {
      const { object } = action.payload;

      state.mainTopics = Object.keys(object.Company);
      // @ts-ignore
      state.topics = object.Company;
      state.object = object;
      state.loading = false;
    })
    .addCase(fetchKB.rejected, state => {
      state.loading = false;
    })
    .addCase(setKB, (state, action) => {
      const { object } = action.payload;

      state.mainTopics = Object.keys(object.Company);
      // @ts-ignore
      state.topics = object.Company;
      state.object = object;
    });
});

export default companyReducer;
