/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill, BsFillArrowLeftSquareFill } from "react-icons/bs";
import { useWebSocket } from "contexts/WebsocketProvider";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { ElapsedTime } from "components";

// eslint-disable-next-line react/prop-types
const MonitoringChatQueue = ({ teamMembers, queueData, setQueueData }) => {
  const { t } = useTranslation("monitoring");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { companyId, userId } = useAppSelector(selectInitData);
  const ws = useWebSocket();

  const fetchChatData = useCallback(async () => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      },
    };

    const url = `${host}api/v1/dashboard/fetch_users_for_dashboard/?status=queue&page=1`;
    try {
      const response = await axios.get(url, config);

      const newQueueData = response.data.map(user => ({
        backend_user_id: user.backend_user_id,
        frontend_user_id: user.frontend_user_id,
        last_message: user.last_message,
        read: user.read,
        name: user.name,
        timestamp: user.timestamp,
        last_message_type: user.last_message_type,
        status: user.status,
        phone: user.phone,
      }));

      setQueueData(newQueueData);
    } catch (e) {
      console.error(e);
    }
  }, [companyId, setQueueData, userId]);

  console.log({ teamMembers });

  const getMemberWithLeastChats = () => {
    const filteredMembers = teamMembers.filter(member => member.role !== "admin" && member.status === "active");

    if (filteredMembers.length === 0) {
      return;
    }

    return filteredMembers.reduce(
      (acc, member) => (acc.active_chats < member.active_chats ? acc : member),
      filteredMembers[0],
    );
  };

  const transferChat = async (backend_user_id, frontend_uuid = getMemberWithLeastChats().frontend_uuid) => {
    if (frontend_uuid === null) return;
    const url = `${host}api/v1/dashboard/transfer_chat/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    const params = {
      backend_user_id,
      frontend_uuid,
    };

    try {
      await axios.put(url, {}, { headers, params });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (ws) {
      ws.on("connect", fetchChatData);

      return () => {
        ws.off("connect", fetchChatData);
      };
    }
  }, [fetchChatData, ws]);

  useEffect(() => {
    fetchChatData();

    const intervalId = setInterval(() => {
      fetchChatData();
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchChatData]);

  return (
    <div className="flex flex-col mt-5 mx-5 h-full">
      {queueData.length > 0 && (
        <button
          onClick={() => transferChat(queueData[queueData.length - 1].backend_user_id)}
          className="w-[90px] flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300  text-white py-1 px-2 rounded text-xs transition-all duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-1 mb-4"
        >
          <BsFillArrowLeftCircleFill />
          <span>{t("queue.transfer")}</span>
        </button>
      )}

      <div className="flex flex-col gap-4">
        {queueData.map(chat => (
          <div
            key={chat.backend_user_id}
            onClick={() =>
              activeDropdown === chat.backend_user_id
                ? setActiveDropdown(null)
                : setActiveDropdown(chat.backend_user_id)
            }
            className="border flex flex-col border-blue-300 px-4 py-2 rounded-lg  gap-4 relative cursor-pointer oveflow-y-hidden hover:overflow-y-visible "
          >
            {/* <div
              className="absolute top-10 left-0 text-xs group-hover:block
                opacity-0 transition-all duration-500 ease-in-out
                group-hover:top-10  group-hover:opacity-100 bg-main-blue p-2 rounded text-white z-[100]"
            >
              <span>Последнее сообщение чата: {chat.last_message}</span>
            </div> */}
            <div className="flex items-center justify-between">
              <span className="font-semibold text-sm ">
                {chat.phone ? chat.phone : `${t("client")} ${chat.backend_user_id}`}
              </span>
              <ElapsedTime timestamp={chat.timestamp} />
            </div>
            <span className="text-xs">
              <span className="font-semibold text-gray-700">Последнее сообщение: </span>
              {chat.last_message.length > 75 ? `${chat.last_message.substring(0, 75)}...` : chat.last_message}
            </span>
            {activeDropdown === chat.backend_user_id && (
              <div className="absolute z-[100] mt-20 w-48 rounded-md shadow-lg bg-white">
                <div
                  className="px-4 py-2 space-y-4"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <span className="text-xs font-bold text-gray-700 mb-7 ">Передать чат оператору:</span>
                  {teamMembers
                    .filter(member => member.status === "active")
                    .map(member => (
                      <button
                        key={member.frontend_uuid}
                        onClick={() => transferChat(chat.backend_user_id, member.frontend_uuid)}
                        className="p-2 rounded-xl text-sm  hover:bg-gray-100 w-full text-left flex items-center justify-between group transition-all duration-300 ease-in-out"
                      >
                        <BsFillArrowLeftSquareFill className="group-hover:text-main-blue transition-all duration-300 ease-in-out" />
                        <span>
                          {member.first_name} {member.last_name}
                        </span>
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonitoringChatQueue;
