/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

const TransferChat = ({ openCards, toggleCard, teamMembers, selectedUser }) => {
  const { t } = useTranslation("omnichannel");
  const { companyId, userId } = useAppSelector(selectInitData);

  const [disabledMembers, setDisabledMembers] = useState({});

  const transferChat = async frontend_uuid => {
    const url = `${host}api/v1/dashboard/transfer_chat/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    const params = {
      backend_user_id: selectedUser,
      frontend_uuid,
    };
    try {
      await axios.put(url, {}, { headers, params });
      setDisabledMembers(prev => ({ ...prev, [frontend_uuid]: true }));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="flex flex-col p-3 rounded-lg  border border-blue-200  transition ease-in-out duration-150 group">
      {openCards.includes(5) ? (
        <div className="flex flex-col space-y-3 py-3">
          <div className="flex items-center space-x-2">
            <div onClick={() => toggleCard(5)}>
              <FaCaretUp className="cursor-pointer" />
            </div>
            <BiTransferAlt className="group-hover:text-main-blue transition duration-300 ease-in-out" />
            <div>{t("chatInfo.transfer")}</div>
          </div>
          {teamMembers
            .filter(user => user.status === "active" && user.role !== "admin")
            .map(user => (
              <div
                className={`p-2 rounded-lg cursor-pointer mb-2 bg-gray-100 border border-main-blue group ${disabledMembers[user.frontend_uuid] ? "bg-gray-300" : ""}`}
                onClick={disabledMembers[user.frontend_uuid] ? null : () => transferChat(user.frontend_uuid)}
              >
                <div className="flex items-center justify-between ">
                  <div className="">{user.email}</div>
                  <div className="group-hover:text-main-blue transition ease-in-out duration-150">
                    <AiOutlineArrowRight />
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex items-center py-3 space-x-2 cursor-pointer">
          <div onClick={() => toggleCard(5)}>
            <FaCaretDown className="cursor-pointer" />
          </div>
          <BiTransferAlt className="group-hover:text-main-blue transition duration-300 ease-in-out" />
          <div>{t("chatInfo.transfer")}</div>
        </div>
      )}
    </div>
  );
};

export default TransferChat;
