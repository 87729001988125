import axios from "utils/axios";

const updateTelegramApiKey = async (apiKey: string) => {
  const data = {
    integration: "telegram",
    apiKey,
  };

  return axios.put(`api/v1/config/integration/`, data);
};

export default updateTelegramApiKey;
