/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { setKB } from "store/knowledgeBase/actions";
import { Tooltip, Alert, Button } from "components";

const KBFile = ({ selectedFile, selectedFileId, setSelectedFileId, data, setData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("knowledgeBase");
  const formattedPath = selectedFile && selectedFile.split("-").filter(Boolean).join(" / ");
  const [, company, domain, subdomain, file] = selectedFile.split("-");
  const [information, setInformation] = useState("");
  const [instructions, setInstructions] = useState("");
  const [createdItem, setCreatedItem] = useState(false);
  const [alert, setAlert] = useState(false);
  const { companyId, userId } = useAppSelector(selectInitData);

  const getItem = useCallback(async () => {
    if (selectedFileId === null) return;
    try {
      const url = `${host}api/v1/dashboard/information/advanced/dsl_item/?item_id=${selectedFileId}`;
      const config = {
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      };

      const response = await axios.get(url, config);

      setInformation(response.data.information);
      setInstructions(response.data.instructions);
    } catch (error) {
      console.error(error);
    }
  }, [companyId, selectedFileId, userId]);

  useEffect(() => {
    setInformation("");
    setInstructions("");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selectedFileId === null ? setCreatedItem(false) : setCreatedItem(true);
    getItem();
  }, [getItem, selectedFile, selectedFileId]);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!information || !instructions) {
      return;
    }

    const url = createdItem
      ? `${host}api/v1/dashboard/information/advanced/update_dsl_item/`
      : `${host}api/v1/dashboard/information/advanced/dsl_item/`;

    const sendData = createdItem
      ? {
          item_id: selectedFileId,
          label: file,
          information,
          instructions,
        }
      : {
          domain,
          subdomain,
          label: file,
          information,
          instructions,
        };

    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
      "Content-Type": "application/json",
    };

    console.log("sending: ", sendData);

    try {
      const response = await axios.put(url, JSON.stringify(sendData), {
        headers,
      });
      setAlert(true);
      if (createdItem === false) {
        const updatedData = JSON.parse(JSON.stringify(data));

        if (!updatedData[company][domain][subdomain]) {
          updatedData[company][domain][subdomain] = {};
        }

        updatedData[company][domain][subdomain][file] = response.data;

        setData(updatedData);
        dispatch(setKB({ object: updatedData }));

        setSelectedFileId(response.data);
        setCreatedItem(true);
      }
    } catch (error) {
      console.error("Error making PUT request:", error);
      throw error;
    }
  };

  console.log(selectedFileId);

  return (
    <div className="w-full h-full mx-4 mt-4">
      <div className="text-xs font-semibold mb-6 border-b pb-2 mr-4">{formattedPath}</div>

      <div className="mb-6 w-3/5">
        <label htmlFor="info" className="mb-2 text-sm font-semibold">
          <Tooltip message={t("KBFile.infoTooltipTitle")} description={t("KBFile.infoTooltipDescription")} />
        </label>
        <textarea
          className="w-full p-3 rounded-lg border text-xs focus:border-blue-500 transition duration-300 ease-in-out"
          placeholder={t("KBFile.infoPlaceholder")}
          rows={6}
          value={information}
          onChange={e => setInformation(e.target.value)}
        />
      </div>

      <hr className="my-6" />

      <div className="mb-6 w-3/5">
        <label htmlFor="instructions" className="mb-2 text-xs font-semibold">
          <Tooltip
            message={t("KBFile.instructionsTooltipTitle")}
            description={t("KBFile.instructionsTooltipDescription")}
          />
        </label>
        <textarea
          className="w-full p-3 rounded-lg border text-sm focus:border-blue-500 transition duration-300 ease-in-out"
          placeholder={t("KBFile.instructionsPlaceholder")}
          rows={6}
          value={instructions}
          onChange={e => setInstructions(e.target.value)}
        />
      </div>
      <Button
        onClick={handleSubmit}
        message={createdItem ? t("KBFile.save") : t("KBFile.create")}
        disabled={!information || !instructions}
      />

      {alert && <Alert message={t("KBFile.fileCreated")} alert={alert} setAlert={setAlert} />}
    </div>
  );
};

export default KBFile;
