import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store/root";

const authState = (state: RootState) => state.auth;

const selectAuthState = createSelector([authState], state => state);

const selectIsAuthorized = createSelector([authState], state => state.isAuthorized);

const selectAuthLoading = createSelector([authState], state => state.loading);

const selectSession = createSelector([authState], state => state.session);

// Todo: remove it after backend refactoring
const selectInitData = createSelector([authState], state => ({ userId: state.userId, companyId: state.companyId }));

const selectIsCompanyValid = createSelector([authState], state => state.isCompanyValid);

export { selectAuthLoading, selectIsAuthorized, selectSession, selectAuthState, selectInitData, selectIsCompanyValid };
