/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUnorderedList, AiFillRobot } from "react-icons/ai";
import { TopList } from "components";
import TeamChatQueue from "./ChatQueue";
import TeamBotChats from "./BotChats";

const MonitoringSidebar = ({ selectedUser, setSelectedUser, teamMembers, setTeamMembers, queueData, setQueueData }) => {
  const { t } = useTranslation("monitoring");
  const [activeTab, setActiveTab] = useState(t("sidebar.queue"));

  const filters = [
    {
      filterName: t("sidebar.queue"),
      icon: AiOutlineUnorderedList,
      onClick: () => setActiveTab(t("sidebar.queue")),
    },
    {
      filterName: t("sidebar.botChats"),
      icon: AiFillRobot,
      onClick: () => setActiveTab(t("sidebar.botChats")),
    },
  ];

  return (
    <div className="flex flex-col ml-5 shadow-md h-full transition-all duration-300 ease-in-out mb-10 overflow-y-hidden">
      <div className="relative flex flex-col items-center justify-center ml-5">
        <TopList filters={filters} activeFilter={activeTab} />
        <span className="bg-gray-200 rounded-xl py-0.5 px-1 text-xs left-2 top-0 absolute">{queueData.length}</span>
      </div>
      <div className="transition-all duration-300 ease-in-out h-full">
        {activeTab === t("sidebar.queue") ? (
          <TeamChatQueue
            teamMembers={teamMembers}
            setTeamMembers={setTeamMembers}
            queueData={queueData}
            setQueueData={setQueueData}
          />
        ) : (
          <TeamBotChats setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
        )}
      </div>
    </div>
  );
};

export default MonitoringSidebar;
