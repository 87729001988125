import React, { useState, useEffect, useCallback } from "react";
import { FaCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { UserActivityStatus, UserRole } from "entities/user";
import { useWebSocket } from "contexts/WebsocketProvider";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectSession } from "store/auth/selectors";
import { selectUser } from "store/user/selectors";
import { setUserActivityStatus, updateUserActivityStatus } from "store/user/actions";

const StatusPicker = () => {
  const dispatch = useAppDispatch();
  const ws = useWebSocket();
  const { t } = useTranslation("helper");
  const { status, role } = useAppSelector(selectUser);
  const session = useAppSelector(selectSession);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let inactivityTimer;

  useEffect(() => {
    if (ws) {
      const newAdminStatusListener = event => {
        const uint8Array = new Uint8Array(event);
        const stringData = new TextDecoder().decode(uint8Array);
        const newStatus = JSON.parse(stringData);

        if (newStatus.admin_id === session.user.id && newStatus.status !== status) {
          dispatch(setUserActivityStatus(newStatus.status));
        }
      };

      ws.on("new_admin_status", newAdminStatusListener);

      return () => {
        ws.off("new_admin_status", newAdminStatusListener);
      };
    }

    return () => {};
  }, [dispatch, session, status, ws]);

  const getStatusIcon = status => {
    const size = 12;

    // TODO: move it to config

    switch (status) {
      case UserActivityStatus.ACTIVE:
        return <FaCircle color="green" size={size} />;
      case UserActivityStatus.BUSY:
        return <FaCircle color="orange" size={size} />;
      case UserActivityStatus.INACTIVE:
        return <FaCircle color="red" size={size} />;
      case UserActivityStatus.AWAY:
        return <FaCircle color="blue" size={size} />;
      case UserActivityStatus.OFFLINE:
        return <FaCircle color="gray" size={size} />;
      default:
        return null;
    }
  };

  const handleStatusChange = newStatus => {
    dispatch(updateUserActivityStatus(newStatus));
    setIsDropdownOpen(false);
  };

  const startTimer = useCallback(() => {
    if (role === UserRole.OPERATOR) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inactivityTimer = setTimeout(() => {
        if (status === UserActivityStatus.ACTIVE) {
          dispatch(updateUserActivityStatus(UserActivityStatus.INACTIVE));
        }
      }, 300000);
    }
  }, []);

  const resetTimer = useCallback(() => {
    clearTimeout(inactivityTimer);
    startTimer();
  }, [inactivityTimer, startTimer]);

  useEffect(() => {
    if (role === UserRole.OPERATOR) {
      startTimer();

      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);

      return () => {
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
      };
    }

    return () => {};
  }, [resetTimer, role, startTimer]);

  return (
    <div className="relative inline-block text-left group z-[1000]">
      <button
        type="button"
        className="inline-flex items-center px-2 py-2  text-sm font-medium rounded-full  bg-white mr-1"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {getStatusIcon(status)}
        <span className="ml-2">{t(`statusPicker.${status}`)}</span>
        <svg
          className="ml-2 h-5 w-5 "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            <button
              onClick={() => handleStatusChange("active")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-green-500 hover:text-white rounded-t-md"
            >
              {t("statusPicker.activeOption")}
            </button>
            <button
              onClick={() => handleStatusChange("busy")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-yellow-500 hover:text-white"
            >
              {t("statusPicker.busyOption")}
            </button>
            <button
              onClick={() => handleStatusChange("windingDown")}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white rounded-b-md"
            >
              {t("statusPicker.windingDownOption")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusPicker;
