/* eslint-disable react/prop-types */
import React from "react";
import { MdMarkChatRead, MdMarkChatUnread } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { BiTransferAlt, BiBlock } from "react-icons/bi";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { selectUser } from "store/user/selectors";

const ChatInterfaceIcons = ({
  config,
  setRead,
  read,
  selectedUser,
  setAgentChat,
  setResolved,
  resolved,
  agentChat,
  muted,
  setMuted,
}) => {
  const { t } = useTranslation("omnichannel");
  const { companyId, userId } = useAppSelector(selectInitData);
  const { role } = useAppSelector(selectUser);

  const handleMarkAsRead = async () => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      },
      params: {
        timestamp: Date.now(),
        backend_user_id: selectedUser,
      },
    };
    try {
      await axios.put(`${host}api/v1/dashboard/update_read_status/`, {}, config);
      setRead(true);
    } catch (e) {
      console.error(e);
    }
  };

  const resolveChat = async () => {
    // if (!activeChat || !activeChat.sub_topic) {
    //   return;
    // }

    try {
      const url = `${host}api/v1/dashboard/resolve_user_chat/`;
      const headers = {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      };
      const params = {
        backend_user_id: selectedUser,
        frontend_uuid: userId,
      };
      await axios.put(url, {}, { headers, params });
      setResolved(true);
    } catch (e) {
      console.error(e);
    }
  };

  const transferToBot = async () => {
    const config = {
      headers: {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      },
    };
    const url = `${host}api/v1/dashboard/transfer_to_bot/`;
    const data = {
      backend_user_id: selectedUser,
    };

    try {
      await axios.post(url, data, config);
      setAgentChat(false);
    } catch (e) {
      console.error(e);
    }
  };

  const muteUser = async () => {
    try {
      const url = `${host}api/v1/dashboard/mute_user/`;
      const headers = {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      };
      const params = {
        backend_user_id: selectedUser,
      };
      await axios.put(url, {}, { headers, params });
      setMuted(true);
    } catch (e) {
      console.error(e);
    }
  };

  const unmuteUser = async () => {
    try {
      const url = `${host}api/v1/dashboard/unmute_user/`;
      const headers = {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      };
      const params = {
        backend_user_id: selectedUser,
      };
      await axios.put(url, {}, { headers, params });
      setMuted(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="">
      {config.map((icon, index) => {
        switch (icon.type) {
          case "resolve":
            return (
              <button
                onClick={resolveChat}
                disabled={resolved || (!agentChat && role === "operator")}
                title={t("chatInterface.resolveChat")}
                className="group relative"
                key={`${icon.type}-${index}`}
              >
                <div
                  className="absolute -top-10 left-0 text-xs 
    opacity-0 transition-all duration-500 hidden group-hover:block
    group-hover:-top-10 group-hover:-left-20 group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                >
                  {t("chatInterface.resolveChat")}
                </div>
                <FaCheck
                  className={`text-${resolved || (!agentChat && role === "operator") ? "gray" : "green"}-500 mr-4`}
                />
              </button>
            );
          case "transfer":
            return (
              <button
                onClick={transferToBot}
                disabled={!agentChat}
                key={`${icon.type}-${index}`}
                className="group relative"
              >
                <div
                  className="absolute -top-10 left-0 text-xs 
    opacity-0 transition-all duration-500  hidden group-hover:block
    group-hover:-top-10 group-hover:-left-20 group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                >
                  {t("chatInterface.transferChatToBot")}
                </div>

                <BiTransferAlt size={20} className={`text-${agentChat ? "purple" : "gray"}-500 mr-4`} />
              </button>
            );
          case "mute":
            return (
              <button
                onClick={muted ? unmuteUser : muteUser}
                disabled={false}
                title={t("chatInterface.muteUser")}
                key={`${icon.type}-${index}`}
                className="group relative"
              >
                <div
                  className="absolute -top-10 left-0 text-xs 
    opacity-0 transition-all duration-500  hidden group-hover:block
    group-hover:-top-10 group-hover:-left-20 group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                >
                  {t("chatInterface.muteUser")}
                </div>
                <BiBlock size={18} className={`text-${muted ? "green" : "red"}-500 mr-4`} />
              </button>
            );
          case "read":
            return (
              <button
                onClick={handleMarkAsRead}
                disabled={read}
                title={t("chatInterface.readChat")}
                className="group relative"
                key={`${icon.type}-${index}`}
              >
                <div
                  className="absolute -top-10 left-0 text-xs 
    opacity-0 transition-all duration-500  hidden group-hover:block
    group-hover:-top-10 group-hover:-left-20 group-hover:opacity-60 bg-main-blue p-2 rounded text-white whitespace-nowrap"
                >
                  {t("chatInterface.readChat")}
                </div>
                {read ? (
                  <MdMarkChatRead className="text-green-500 mr-4" />
                ) : (
                  <MdMarkChatUnread className="text-red-500 mr-4" />
                )}
              </button>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default ChatInterfaceIcons;
