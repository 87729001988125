import { createReducer } from "@reduxjs/toolkit";
import { InitialState } from "./types";
import { checkAuth, validateCompany } from "./actions";

const initialState: InitialState = {
  isAuthorized: undefined,
  session: null,
  loading: false,

  // Todo: remove it after backend refactoring
  isCompanyValid: undefined,
};

const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(checkAuth.pending, state => {
      state.loading = true;
    })
    .addCase(checkAuth.fulfilled, (state, action) => {
      state.isAuthorized = action.payload.isAuthorized;
      state.session = action.payload.session;
      state.loading = false;
      state.companyId = action.payload.companyId;
      state.userId = action.payload.userId;
    })
    .addCase(checkAuth.rejected, state => {
      state.isAuthorized = false;
      state.loading = false;
    })

    // Todo: remove it after backend refactoring
    .addCase(validateCompany.pending, state => {
      state.loading = true;
    })
    .addCase(validateCompany.fulfilled, (state, action) => {
      state.isCompanyValid = action.payload;
      state.loading = false;
    })
    .addCase(validateCompany.rejected, state => {
      state.isCompanyValid = false;
      state.loading = false;
    });
});

export default authReducer;
