import { UserActivityStatus } from "entities/user";
import axios from "utils/axios";

export type UpdateUserStatusRequest = {
  status: UserActivityStatus;
  userId: string;
};

const updateUserStatus = async ({ userId, status }: UpdateUserStatusRequest) => {
  const params = {
    frontend_uuid: userId,
    status,
  };

  await axios.put(`api/v1/team/change_admin_status/`, params);
};

export default updateUserStatus;
