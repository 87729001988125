/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { BiMessageAdd } from "react-icons/bi";
import { AiOutlineSend } from "react-icons/ai";

const NiceTextArea = ({ handleSubmit, message, setMessage, stopAgentChat, frequentAnswers }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleAnswerSelect = answer => {
    setMessage(answer);
    setIsDropdownVisible(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
        <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
          <div onClick={toggleDropdown} className="relative cursor-pointer">
            {isDropdownVisible && (
              <div className="absolute bottom-full mb-2 w-64 bg-white border border-gray-300 rounded-md shadow-md overflow-hidden transition-transform transform scale-95">
                {frequentAnswers.map(answer => (
                  <div
                    key={answer}
                    className="p-3 border-b border-gray-100 last:border-b-0 hover:bg-gray-100 transition-colors duration-200 cursor-pointer font-semibold"
                    onClick={() => handleAnswerSelect(answer)}
                  >
                    {answer}
                  </div>
                ))}
              </div>
            )}
            <BiMessageAdd size={20} className="text-gray-600 hover:text-gray-800 transition-colors duration-200" />
          </div>

          <button
            type="button"
            data-tooltip-target="tooltip-fullscreen"
            onClick={stopAgentChat}
            className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 19 19"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 1h5m0 0v5m0-5-5 5M1.979 6V1H7m0 16.042H1.979V12M18 12v5.042h-5M13 12l5 5M2 1l5 5m0 6-5 5"
              />
            </svg>
            <span className="sr-only">Full screen</span>
          </button>
        </div>
        <div className="relative px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800">
          <textarea
            id="editor"
            rows="4"
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleSubmit(e);
                setMessage("");
              }
            }}
            className="block w-full mb-6 px-0 text-sm text-gray-800 bg-white border-0 dark:bg-gray-800 focus:outline-none dark:text-white dark:placeholder-gray-400"
            placeholder="Напишите сообщение"
            required
          />
          <button type="submit" className="absolute bottom-2 right-2">
            <AiOutlineSend size={20} />
          </button>
        </div>
      </div>
    </form>
  );
};

export default NiceTextArea;
