import React from "react";
import { useTranslation } from "react-i18next";
import HistoryChats from "./HistoryChats";

const HistoryPage = () => {
  const { t } = useTranslation("history");

  return (
    <div>
      <div className="p-4 h-screen overflow-y-auto">
        <div className="flex items-center mb-2">
          <div className="text-xl font-bold mr-3">{t("headerName")}</div>
        </div>
        <hr className="mb-4" />
        <HistoryChats />
      </div>
    </div>
  );
};

export default HistoryPage;
