import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaEye, FaEyeSlash, FaCopy } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "utils/hooks";
import { selectInitData, selectSession } from "store/auth/selectors";

const SecretKeys = () => {
  const { t } = useTranslation("profile");
  const [isCompanyIdVisible, setCompanyIdVisible] = useState(false);
  const session = useAppSelector(selectSession);
  const { companyId } = useAppSelector(selectInitData);

  const toggleCompanyIdVisible = () => {
    setCompanyIdVisible(!isCompanyIdVisible);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-white shadow-md rounded-3xl p-6 m-4 w-full max-w-screen-lg">
      <div className="w-full">
        <h2 className="text-left font-semibold text-lg mb-5">{t("secretKeys.secretKeys")}</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center gap-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Company ID</label>
          </div>
          <div className="flex justify-between items-center border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <span onClick={toggleCompanyIdVisible} className={isCompanyIdVisible ? "" : "blur"}>
              {`${session ? companyId : ""}`}
            </span>
            <div className="flex items-center">
              <button className="mr-2" onClick={toggleCompanyIdVisible}>
                {isCompanyIdVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
              <CopyToClipboard text={`${session ? companyId : ""}`}>
                <button>
                  <FaCopy />
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecretKeys;
