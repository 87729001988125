import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store/root";

const userState = (state: RootState) => state.layout;

const selectScreenSize = createSelector([userState], ({ screenSize }) => screenSize);

const selectActiveMenu = createSelector([userState], ({ activeMenu }) => activeMenu);

export { selectScreenSize, selectActiveMenu };
