import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { useAppSelector } from "utils/hooks";
import { selectInitData, selectIsAuthorized, selectIsCompanyValid } from "store/auth/selectors";

export const WebSocketContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const WebSocketProvider = ({ children }) => {
  const { companyId, userId } = useAppSelector(selectInitData);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const [socket, setSocket] = useState(null);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const MAX_RECONNECT_ATTEMPTS = 5;

  const [visibility, setVisibility] = useState(document.visibilityState);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibility(document.visibilityState);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleReload = useCallback(() => {
    if (visibility === "visible" && socket && !socket.connected && isCompanyValid) {
      window.location.reload();
    }
  }, [isCompanyValid, socket, visibility]);

  useEffect(() => {
    const timeoutId = setTimeout(() => handleReload(), 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleReload, visibility]);

  useEffect(() => {
    // Just for make sure that company is loaded, I guess for now it's not necessary

    const status = Cookies.get("status");

    if (!isAuthorized || !isCompanyValid || (socket && socket?.connected) || !status) return;

    const socketConnection = io(`${process.env.REACT_APP_SOCKET_HOST}${companyId}`, {
      path: "/websocket",
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
    });

    socketConnection.on("connect", () => {
      setSocket(socketConnection);
      setReconnectAttempts(0);

      socketConnection.emit("join", {
        user_id: "admins",
        frontend_uuid: userId,
        company_name: companyId,
        status,
      });
    });

    socketConnection.on("connect_error", error => {
      console.log("socket connection error:", { error });
      setReconnectAttempts(prev => prev + 1);
      setSocket(null);
    });

    socketConnection.on("disconnect", () => {
      console.log("disconnected from ws");
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (socketConnection) {
        socketConnection.off("connect");
        socketConnection.off("reconnect_failed");
      }
    };
  }, [companyId, isAuthorized, isCompanyValid, socket, userId]);

  return (
    <WebSocketContext.Provider value={socket}>
      {reconnectAttempts >= 5 && (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
          <div className="absolute inset-0 bg-black opacity-50" />
          <div className="relative z-10 p-5 bg-white rounded shadow-lg max-w-2xl w-full">
            <h2 className="text-2xl font-bold mb-4">Соединение потеряно</h2>
            <div className="flex flex-col">
              <p className="text-lg mb-4">Пожалуйста, перезагрузите страницу!</p>
              <span className="text-md mb-4 whitespace-normal">
                Возможно, есть проблемы с вашим соединением: пожалуйста, проверьте подключение к интернету
              </span>
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
              onClick={() => window.location.reload()}
            >
              перезагрузить
            </button>
          </div>
        </div>
      )}
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
