import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import KBSidebar from "./KBSidebar";
import KBInterFace from "./KBInterface";

const KnowledgeBase = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [description, setDescription] = useState(null);
  const [data, setData] = useState(null);
  const { companyId, userId } = useAppSelector(selectInitData);

  const fetchKB = useCallback(async () => {
    const url = `${host}api/v1/config/load/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    const params = {
      object_name_list: ["kb_object"],
    };
    try {
      const response = await axios.get(url, {
        params,
        headers,
      });

      setData(response.data.kb_object);
    } catch (error) {
      console.error("fetch kb error", { error });
    }
  }, [companyId, userId]);

  useEffect(() => {
    fetchKB();
  }, [fetchKB]);

  return (
    <div className="flex -ml-4">
      <div className="w-2/5">
        <KBSidebar
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFileId={selectedFileId}
          setSelectedFileId={setSelectedFileId}
          setDescription={setDescription}
          data={data}
          setData={setData}
        />
      </div>
      {(selectedFile || description) && (
        <div className="w-3/5">
          <KBInterFace
            selectedFile={selectedFile}
            selectedFileId={selectedFileId}
            setSelectedFileId={setSelectedFileId}
            description={description}
            data={data}
            setData={setData}
          />
        </div>
      )}
    </div>
  );
};

export default KnowledgeBase;
