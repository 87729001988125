import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import axios from "axios";
import FileDownload from "js-file-download";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { DatePicker } from "components";

const ExportReport = () => {
  const { companyId, userId } = useAppSelector(selectInitData);

  const { t } = useTranslation("analytics");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [errorMsg, setErrorMsg] = useState("");

  const getReport = async (startDate, endDate) => {
    try {
      console.log("this is the end date: ", Date.parse(endDate));

      const response = await axios.get(`${host}api/v1/dashboard/analytics/get_report/`, {
        params: {
          from_time: Date.parse(startDate),
          to_time: Date.parse(endDate),
        },
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
        responseType: "blob",
      });

      FileDownload(response.data, "report.xlsx");
    } catch (e) {
      console.error(e);
    }
  };

  const handleExport = () => {
    if (!startDate || !endDate) {
      setErrorMsg(t("exportReport.dateError"));
      return;
    }

    getReport(startDate, endDate);
    setIsModalOpen(false);
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    const endDateWithTime = new Date(date);
    endDateWithTime.setHours(23, 59, 59, 999);
    setEndDate(endDateWithTime);
  };

  return (
    <div className="px-4">
      <button
        className="bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300  text-white py-1 px-2 rounded text-xs transition duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-1"
        onClick={() => setIsModalOpen(true)}
      >
        {t("exportReport.button")}
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Select Date Range"
        className="bg-white rounded-xl px-20 py-10 max-w-6xl mx-auto my-20 shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-xl font-semibold mb-6 text-center">Экспорт данных в файл xlsx</h2>
        <div className="text-red-500 mt-4">{errorMsg}</div>

        <div className="flex space-x-6 mt-4 justify-between">
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-600">Промежуток:</label>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={dates => {
                handleStartDateChange(dates[0]);
                handleEndDateChange(dates[1]);
              }}
            />
          </div>
        </div>

        <button className="bg-green-500 text-white px-4 py-2 mt-4 rounded" onClick={handleExport}>
          Экспорт
        </button>
      </Modal>
    </div>
  );
};

export default ExportReport;
