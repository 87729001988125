import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

export const NotificationContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(notification => {
    setNotifications(prev => [...prev, notification]);
  }, []);

  const removeNotification = useCallback(id => {
    setNotifications(prev => prev.filter(notification => notification !== id));
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (notifications.length > 0) {
      favicon.href = "/rtno-circle.ico";
    } else {
      favicon.href = "/favicon.ico";
    }
  }, [notifications]);

  const values = useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
    }),
    [notifications, addNotification, removeNotification],
  );

  return <NotificationContext.Provider value={values}>{children}</NotificationContext.Provider>;
};

export const useNotification = () => useContext(NotificationContext);
