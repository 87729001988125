import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "store/root";

const companyState = (state: RootState) => state.company;

const selectCompany = createSelector([companyState], ({ name, description, settings }) => ({
  name,
  description,
  settings,
}));

const selectCompanyLoading = createSelector([companyState], state => state.loading);

export { selectCompany, selectCompanyLoading };
