import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/reducer";
import companyReducer from "./company/reducer";
import userReducer from "./user/reducer";
import knowledgeBaseReducer from "./knowledgeBase/reducer";
import layoutReducer from "./layout/reducer";

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  company: companyReducer,
  kb: knowledgeBaseReducer,
  layout: layoutReducer,
});

export default reducer;
