import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { supabase } from "supabase/supabase";
import { host } from "constants/index";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

const TeamInvite = () => {
  const { t } = useTranslation("team");
  const { companyId, userId } = useAppSelector(selectInitData);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("supervisor");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const checkIfUserExists = async () => {
    const { data, error } = await supabase.from("users").select("email").eq("email", email);

    if (error) return console.error(error);
    if (data.length > 0) return true;
    return false;
  };

  const sendInvite = async () => {
    setLoading(true);
    setError(null);

    try {
      if (await checkIfUserExists()) {
        setError("User already exists.");
        return;
      }

      const url = `${host}api/v1/team/invite/`;
      const id = uuidv4();

      await supabase.from("users").insert({
        user_id: id,
        company_id: userId,
        role,
        company_name: companyId,
        email,
        first_name: "blank",
        last_name: "blank",
      });

      await axios.post(
        url,
        {
          email,
          role,
          master_uuid: userId,
        },
        {
          headers: {
            "RTNO-COMPANY-ID": companyId,
            "RTNO-ADMIN-UUID": userId,
          },
        },
      );

      setModalIsOpen(false);
      setEmail("");
    } catch (e) {
      console.error(e);
      setError(t("tryAgainError"));
    } finally {
      setLoading(false);
    }
  };

  const roles = ["supervisor", "operator"];

  return (
    <div>
      <button
        className="bg-gradient-to-r from-blue-500 via-blue-400 to-blue-300  text-white py-1 px-2 rounded text-xs transition duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-1"
        onClick={() => setModalIsOpen(true)}
      >
        {t("invite")}
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Add Team Member"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        className="bg-white rounded-lg p-8 max-w-xl mx-auto shadow-2xl"
      >
        <div className="flex flex-col items-start gap-4">
          <h2 className="text-xl font-bold mb-4">{t("invite")}</h2>
          <div className="relative w-full mb-4">
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-300 transition duration-300 ease-in-out"
            />
            <select
              value={role}
              onChange={e => setRole(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-300 mt-2 transition duration-300 ease-in-out"
            >
              {roles.map(role => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between items-center mt-4 w-full">
            <button
              onClick={() => sendInvite(email, role)}
              disabled={loading || email === ""}
              className={`bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-1 ${
                loading || email === "" ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? (
                <div className="flex items-center justify-center space-x-2">
                  {t("wait")}
                  <div className="loader ease-linear rounded-full ml-2 border-2 border-t-2 border-gray-200 h-4 w-4" />
                </div>
              ) : (
                t("invite")
              )}
            </button>
            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TeamInvite;
