import axios from "utils/axios";
import { host } from "../../constants";

type AssignAdminToCompany = {
  userId: string;
  email: string;
};

const assignAdminToCompany = async ({ userId, email }: AssignAdminToCompany) => {
  const url = `${host}api/v1/team/add_master/`;

  const params = {
    master_uuid: userId,
    email,
  };

  return axios.put(url, params);
};

export default assignAdminToCompany;
