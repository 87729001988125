import Cookies from "js-cookie";

const accessTokenPath = "access_token";
const refreshTokenPath = "refresh_token";

export const getAccessToken = (): string | undefined => Cookies.get(accessTokenPath);
export const getRefreshToken = (): string | undefined => Cookies.get(refreshTokenPath);

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
  Cookies.set(accessTokenPath, accessToken, { expires: 7 });
  Cookies.set(refreshTokenPath, refreshToken, { expires: 7 });
};

export const clearAuthTokens = () => {
  Cookies.remove(accessTokenPath);
  Cookies.remove(refreshTokenPath);
};

export const logoutRedirect = () => {
  clearAuthTokens();
  window.location.href = "/";
};

// TODO: need to remove userId && companyId from state and actions after backend refactoring

export const setRequestData = (companyId: string, userId: string) => {
  Cookies.set("companyId", companyId);
  Cookies.set("userId", userId);
};

export const getRequestData = () => {
  const companyId = Cookies.get("companyId");
  const userId = Cookies.get("userId");

  return { companyId, userId };
};

export const clearRequestData = () => {
  Cookies.remove("companyId");
  Cookies.remove("userId");
};
