import React from "react";
import { useNotification } from "contexts/NotificationContext";
import Notification from "./Notification";

const Notifications = () => {
  const { notifications } = useNotification();

  return (
    <div className="notifications">
      {notifications.map(notification => (
        <Notification key={notification} data={notification} />
      ))}
    </div>
  );
};

export default Notifications;
