import React, { useState } from "react";
import { MdFolderOpen } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { TopList } from "components";
import KnowledgeBase from "./KnowledgeBase";

const KBPage = () => {
  const { t } = useTranslation("knowledgeBase");
  const [activeTab, setActiveTab] = useState(t("KB.knowledgeTree"));

  const filters = [
    {
      filterName: t("KB.knowledgeTree"),
      icon: MdFolderOpen,
      onClick: () => setActiveTab(t("KB.knowledgeTree")),
    },
  ];

  return (
    <div className="p-4 h-screen overflow-y-hidden">
      <div className="text-xl font-bold mr-3 mb-2">{t("KB.knowledgeBase")}</div>
      <TopList filters={filters} activeFilter={activeTab} />
      <hr className="mb-4" />

      {activeTab === t("KB.knowledgeTree") && <KnowledgeBase />}
    </div>
  );
};

export default KBPage;
