/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DatePicker = ({ singleDateMode = false, onDateChange }) => {
  const { t } = useTranslation("helper");
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date(today.getFullYear(), today.getMonth(), 1));

  const daysOfWeek = [
    t("datePicker.days.mon"),
    t("datePicker.days.tue"),
    t("datePicker.days.wed"),
    t("datePicker.days.thu"),
    t("datePicker.days.fri"),
    t("datePicker.days.sat"),
    t("datePicker.days.sun"),
  ];

  const generateDays = date => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay() || 7;
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const days = Array(firstDayOfMonth - 1)
      .fill(null)
      .concat([...Array(daysInMonth).keys()].map(i => i + 1));
    return days;
  };

  const isFutureDate = date => {
    const todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
    return date > todayEnd;
  };

  const isDateSelected = date => {
    const formatDate = d => (d ? `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}` : null);

    if (singleDateMode) {
      return formatDate(date) === formatDate(selectedDate);
    }
    return formatDate(date) === formatDate(startDate) || formatDate(date) === formatDate(endDate);
  };

  const handleDateClick = day => {
    if (!day) return;

    const clickedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);

    if (isFutureDate(clickedDate)) return;

    if (singleDateMode) {
      setSelectedDate(clickedDate);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onDateChange && onDateChange(clickedDate);
      return;
    }

    if (!startDate || (startDate && endDate)) {
      setStartDate(clickedDate);
      setEndDate(null);
    } else {
      const endDateTime = new Date(clickedDate);
      endDateTime.setHours(23, 59, 59, 999);
      setEndDate(endDateTime);
      if (clickedDate < startDate) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onDateChange && onDateChange([clickedDate, startDate]);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onDateChange && onDateChange([startDate, clickedDate]);
      }
    }
  };

  console.log("date picker end date:", Date.parse(endDate));

  const isNextMonthDisabled =
    currentMonth.getFullYear() === today.getFullYear() && currentMonth.getMonth() === today.getMonth();

  return (
    <div className="date-picker bg-white p-4 rounded-md shadow-lg">
      <div className="header flex justify-between mb-4">
        <button onClick={() => setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1))}>
          &lt;
        </button>
        <span className="flex-grow text-center font-bold">{`${currentMonth.toLocaleString("ru-RU", {
          month: "long",
        })} ${currentMonth.getFullYear()}`}</span>
        <button
          onClick={() => setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1))}
          disabled={isNextMonthDisabled}
        >
          &gt;
        </button>
      </div>
      <div className="days-header grid grid-cols-7 gap-2 mb-2 ml-2">
        {daysOfWeek.map(day => (
          <div key={day} className="day-name text-sm font-medium text-gray-500">
            {day}
          </div>
        ))}
      </div>
      <div className="days-grid grid grid-cols-7 gap-2">
        {generateDays(currentMonth).map((day, idx) => (
          <div
            key={idx}
            className={`day text-center p-2 rounded-md ${day ? "hover:bg-gray-200 cursor-pointer" : "bg-gray-100"} ${
              day && isDateSelected(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day))
                ? "bg-blue-500 text-white"
                : ""
            } ${
              isFutureDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day))
                ? "text-gray-400 cursor-not-allowed disabled"
                : ""
            }`}
            onClick={() => handleDateClick(day)}
          >
            {day || ""}
          </div>
        ))}
      </div>
      <div className="selected-dates mt-4">
        {singleDateMode ? (
          <span>
            {t("datePicker.selectedDate")}{" "}
            {selectedDate?.toLocaleDateString("ru-RU", {
              timeZone: "Europe/Moscow",
            })}
          </span>
        ) : (
          <div className="flex flex-col">
            <span>{t("datePicker.selectedRange")}</span>
            <span>
              {startDate?.toLocaleDateString("ru-RU", {
                timeZone: "Europe/Moscow",
              })}{" "}
              -{" "}
              {endDate?.toLocaleDateString("ru-RU", {
                timeZone: "Europe/Moscow",
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
