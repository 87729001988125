import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "utils/hooks";
import { selectUser } from "store/user/selectors";

type Props = {
  children: JSX.Element;
  allowedRoles: string[];
};

const RequireRole = ({ children, allowedRoles }: Props) => {
  const { role } = useAppSelector(selectUser);

  return allowedRoles.includes(role) ? children : <Navigate to="/dashboard" />;
};

export default RequireRole;
