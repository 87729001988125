import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";

const ToggleSwitch = () => {
  const { companyId, userId } = useAppSelector(selectInitData);

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = `${host}api/v1/chat/change_automatic/`;

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${host}api/v1/chat/check_automatic/`, {
        params: {},
        headers: {
          "RTNO-COMPANY-ID": companyId,
          "RTNO-ADMIN-UUID": userId,
        },
      });
      setIsActive(response.data.status);
    } catch (error) {
      console.error("toggle switch error", { error });
    }
  }, [companyId, userId]);

  const handleToggle = async () => {
    setIsLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const headers = {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
      };
      // Отправляем запрос на эндпоинт для изменения состояния
      await axios.post(
        apiUrl,
        { isActive: !isActive },
        {
          params: {},
          headers: {
            "RTNO-COMPANY-ID": companyId,
            "RTNO-ADMIN-UUID": userId,
          },
        },
      );

      setIsActive(!isActive); // Переключаем состояние
    } catch (error) {
      console.error("Ошибка при переключении:", error);
      alert("Failed to toggle.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" className="sr-only peer" checked={isActive} onChange={handleToggle} disabled={isLoading} />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
    </label>
  );
};
export default ToggleSwitch;
