/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { ChatContextProvider } from "../ChatContext";
import Message from "./Message";
import Topic from "./Topic";

// eslint-disable-next-line react/prop-types
const ChatInterfaceMessages = ({ loading, messages, agentChat, activeChatId, userInfo }) => {
  const { t } = useTranslation("omnichannel");
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    const scroll = messagesContainerRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, agentChat, activeChatId, loading]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  console.log({ messages });

  return (
    <ChatContextProvider>
      <div ref={messagesContainerRef} className="flex flex-col flex-grow mt-4 overflow-y-auto gap-4">
        {loading ? (
          <div className="bg-gray-100 p-4 rounded-lg animate-pulse">
            <div className="bg-gray-300 h-4 w-1/4 mb-2" />
            <div className="bg-gray-300 h-4 w-3/4 mb-2" />
            <div className="bg-gray-300 h-4 w-1/2" />
          </div>
        ) : (
          Object.entries(
            messages.reduce((acc, message) => {
              const moscowDate = moment.tz(message.timestamp, "Europe/Moscow").format("YYYY-MM-DD");

              if (!acc[moscowDate]) {
                acc[moscowDate] = [];
              }

              acc[moscowDate].push(message);
              return acc;
            }, {}),
          ).map(([date, messages]) => (
            <div key={date}>
              <div className="text-gray-600 text-center mb-2">{date}</div>
              {messages.map((message, index) => {
                const isBotMessage = message.type === "bot";
                const previousMessage = messages[index - 2];

                const isFirstBotMessage =
                  isBotMessage &&
                  index === 1 &&
                  (message.topic !== previousMessage?.topic || message.subtopic !== previousMessage?.subtopic);

                const isTopicChanged =
                  isBotMessage &&
                  previousMessage &&
                  previousMessage.type === "bot" &&
                  (message.topic !== previousMessage?.topic || message.subtopic !== previousMessage?.subtopic);

                const name =
                  // eslint-disable-next-line no-nested-ternary
                  message.type === "user"
                    ? userInfo.phone
                      ? userInfo.phone
                      : `${t("chatInterface.client")} ${message.backend_user_id}`
                    : message.type === "admin"
                      ? `${t("chatInterface.admin")}`
                      : `${t("chatInterface.bot")}`;

                return (
                  <div key={message.message_id}>
                    {(isTopicChanged || isFirstBotMessage) && (
                      <Topic topic={message.topic} subtopic={message.subtopic} />
                    )}
                    <Message message={message} name={name} />
                  </div>
                );
              })}
            </div>
          ))
        )}
      </div>
    </ChatContextProvider>
  );
};

export default ChatInterfaceMessages;
