import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector, useAppThunkDispatch } from "utils/hooks";
import { Loading } from "components";
import { validateCompany } from "store/auth/actions";
import { selectIsCompanyValid } from "store/auth/selectors";
import { fetchCompany } from "store/company/actions";
import { fetchUser } from "store/user/actions";
import { selectUserLoading } from "store/user/selectors";
import { selectCompanyLoading } from "store/company/selectors";
import { fetchKB } from "store/knowledgeBase/actions";

const InitApp = () => {
  const isCompanyValid = useAppSelector(selectIsCompanyValid);
  const dispatch = useAppThunkDispatch();
  const isUserLoading = useAppSelector(selectUserLoading);
  const isCompanyLoading = useAppSelector(selectCompanyLoading);
  // TODO: we need to user role and user status from token, for now we need to wait when user will be loaded
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    if (isCompanyValid === undefined) {
      dispatch(validateCompany());
    }

    if (isCompanyValid) {
      dispatch(fetchCompany());
      dispatch(fetchKB());
    }
  }, [dispatch, isCompanyValid]);

  useEffect(() => {
    dispatch(fetchUser())
      .unwrap()
      .then(() => setIsUserLoaded(true));
  }, [dispatch]);

  if (isUserLoading || isCompanyLoading || !isUserLoaded) {
    return <Loading />;
  }

  return <Outlet />;
};

export default InitApp;
