/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { host } from "constants";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { Alert, Button, Tooltip } from "components";

const KBDescription = ({ description, data }) => {
  const { t } = useTranslation("knowledgeBase");
  const [inputValue, setInputValue] = useState("");
  const [alert, setAlert] = useState(false);
  const { companyId, userId } = useAppSelector(selectInitData);

  const names = description.split("-");
  const level = description.split("-").length;

  const formattedPath = description && description.split("-").filter(Boolean).join(" / ");

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSend = async () => {
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    let url = "";
    let data = {};

    if (level === 3) {
      data = {
        high_level_name: "Company",
        domain: names[2],
        description: inputValue,
      };
      url = `${host}api/v1/dashboard/information/advanced/set_description_for_domain/`;
    } else if (level === 4) {
      data = {
        high_level_name: "Company",
        domain: names[2],
        subdomain: names[3],
        description: inputValue,
      };
      url = `${host}api/v1/dashboard/information/advanced/set_description_for_subdomain/`;
    }

    try {
      await axios.put(url, data, { headers });
      setAlert(true);
    } catch (error) {
      console.error("Failed to add new description: ", error);
    }
  };

  useEffect(() => {
    if (level === 3) {
      // eslint-disable-next-line no-underscore-dangle
      setInputValue(data.Company[names[2]]._description);
    } else if (level === 4) {
      // eslint-disable-next-line no-underscore-dangle
      setInputValue(data.Company[names[2]][names[3]]._description);
    }
  }, [data, description, level, names]);

  return (
    <div className="p-4 ">
      <div className="text-xs font-semibold mb-6 border-b pb-2 mr-4">{formattedPath}</div>
      <label htmlFor="instructions" className="mb-2 text-xs font-semibold relative">
        <Tooltip
          message={t("kbDescription.tooltipTitle")}
          description={t("kbDescription.tooltipDesc", { level, domain: names[2], subdomain: names[3] })}
        />
      </label>
      <textarea
        value={inputValue}
        onChange={handleInputChange}
        className="p-2 mb-2 border rounded w-full max-w-xl text-sm"
        rows="5"
      />
      <Button message={t("kbDescription.sendButton")} onClick={handleSend} />
      {alert && <Alert message={t("kbDescription.alertMessage")} alert={alert} setAlert={setAlert} />}
    </div>
  );
};

export default KBDescription;
