import React, { useCallback, useEffect } from "react";

// eslint-disable-next-line react/prop-types
const Alert = ({ message, alert, setAlert, color = "bg-green-200" }) => {
  const handleDismiss = useCallback(() => {
    setAlert(false);
  }, [setAlert]);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        handleDismiss();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }

    return () => {};
  }, [alert, handleDismiss]);

  if (!alert) {
    return null;
  }

  return (
    <div className={`fixed top-4 left-4 ${color} p-4 rounded-md shadow-md`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={handleDismiss}
          >
            <path
              fillRule="evenodd"
              d="M11.414 10l4.293-4.293a1 1 0 0 0-1.414-1.414L10 8.586 5.707 4.293a1 1 0 0 0-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 1 0 1.414 1.414L10 11.414l4.293 4.293a1 1 0 0 0 1.414-1.414L11.414 10z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-2 ">{message}</div>
      </div>
    </div>
  );
};

export default Alert;
