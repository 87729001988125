import React, { memo, useCallback, useEffect } from "react";
import { Loading } from "components";
import { useAppSelector, useAppThunkDispatch } from "utils/hooks";
import { checkAuth as checkAuthAction } from "store/auth/actions";
import { selectAuthLoading, selectIsAuthorized } from "store/auth/selectors";

type Props = {
  children: JSX.Element;
};

const CheckAuth = ({ children }: Props) => {
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const isAuthLoading = useAppSelector(selectAuthLoading);
  const dispatch = useAppThunkDispatch();

  const checkAuthHandler = useCallback(async () => {
    await dispatch(checkAuthAction());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthorized === undefined) {
      checkAuthHandler();
    }
  }, [checkAuthHandler, dispatch, isAuthorized]);

  if (isAuthLoading || isAuthorized === undefined) return <Loading />;

  return children;
};

export default memo(CheckAuth);
