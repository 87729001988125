/* eslint-disable react/prop-types */
import React from "react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { BiConversation } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const UserChats = ({ openCards, toggleCard, chats, setActiveChatId, activeChatId }) => {
  const { t } = useTranslation("omnichannel");
  const handleChatChange = id => {
    setActiveChatId(id);
  };

  return (
    <div className="flex flex-col p-3 rounded-lg  border border-blue-200  transition ease-in-out duration-150 group">
      {openCards.includes(1) ? (
        <div className="flex flex-col space-y-3 py-3">
          <div className="flex items-center space-x-2">
            <div onClick={() => toggleCard(1)}>
              <FaCaretUp className="cursor-pointer" />
            </div>
            <BiConversation className="group-hover:text-main-blue transition duration-300 ease-in-out" />
            <div>{t("chatInfo.dialogues.dialogues")}</div>
          </div>
          {chats.map((chat, index) => (
            <div
              key={index}
              className={`p-2 rounded-lg cursor-pointer mb-2 ${
                activeChatId === chat.user_chat_id ? "bg-blue-100 hover:bg-blue-200" : "bg-gray-100 hover:bg-gray-200"
              } transition ease-in-out duration-150`}
              onClick={() => handleChatChange(chat.user_chat_id)}
            >
              <div className="flex flex-col justify-between text-sm font-medium">
                <div className="flex flex-col gap-1">
                  <div className="text-gray-800 text-sm">
                    {t("chatInfo.dialogues.admin")}
                    {chat.responsible_for_closure_email}
                  </div>
                  <div>
                    {t("chatInfo.dialogues.rating")}
                    {chat.rating ? chat.rating : "нет"}
                  </div>
                  <div className="text-gray-600">Тема: {chat.main_topic}</div>
                  <div className="text-xs">{chat.timestamp}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div onClick={() => toggleCard(1)} className="flex items-center py-3 space-x-2 cursor-pointer">
          <div>
            <FaCaretDown className="cursor-pointer" />
          </div>
          <BiConversation className="group-hover:text-main-blue transition duration-300 ease-in-out" />
          <div>{t("chatInfo.dialogues.dialogues")}</div>
        </div>
      )}
    </div>
  );
};

export default UserChats;
