import { useEffect } from "react";
import { useWebSocket } from "../../../contexts/WebsocketProvider";

const useChatInterfaceWebsocket = (handlers, resolved, selectedUser) => {
  const ws = useWebSocket();

  useEffect(() => {
    if (!ws) return () => {};

    Object.entries(handlers).forEach(([event, handler]) => {
      ws.on(event, handler);
    });

    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        ws.off(event, handler);
      });
    };
  }, [ws, handlers, resolved, selectedUser]);
};

export default useChatInterfaceWebsocket;
