/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { FaUser, FaRobot } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { BiDownload } from "react-icons/bi";
import { useChatContext } from "../ChatContext";
import AudioPlayer from "../AudioPlayer";

const Message = ({ message, name }) => {
  const { t } = useTranslation("helper");
  const { attachments, onLoadAttachment } = useChatContext();
  // eslint-disable-next-line react/prop-types
  const moscowTime = moment.tz(message.timestamp, "Europe/Moscow").format("HH:mm:ss");

  const img = useMemo(() => {
    if (message.type === "user") {
      return <FaUser size={15} className="text-green-500" />;
    }
    if (message.type === "bot") {
      return <FaRobot size={15} className="text-blue-500" />;
    }
    if (message.type === "admin") {
      return <MdSupportAgent size={15} className="text-blue-500" />;
    }
    return null;
  }, [message.type]);

  return (
    <div>
      <div className="flex flex-col items-start mb-5">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            {img}
            <div className="text-black text-sm font-semibold flex-grow">{name}</div>
          </div>
          <div className="flex items-center">
            <div className="text-gray-500 text-sm">{moscowTime}</div>
          </div>
        </div>

        <div className="ml-6 mt-2 w-full flex flex-col gap-2">
          {message.photo &&
            message.photo.map(photoID =>
              attachments[photoID] ? (
                <div className="relative w-full px-5 pt-4" key={photoID}>
                  <img src={attachments[photoID]} alt="Loaded Chat" className="w-full object-cover" />
                  <a
                    href={attachments[photoID]}
                    download
                    className="absolute top-1 left-1 bg-gray-700 text-white p-2 rounded-full transition duration-300 ease-in-out hover:bg-gray-800 hover:scale-110"
                  >
                    <BiDownload />
                  </a>
                </div>
              ) : (
                <div
                  className="w-24 h-24 bg-gray-300 rounded-2xl cursor-pointer flex items-center justify-center mx-auto "
                  onClick={() => onLoadAttachment(photoID)}
                  key={photoID}
                >
                  {`${t("button.load")}`}
                </div>
              ),
            )}

          {message.voice &&
            message.voice.map(voiceId => {
              if (attachments[voiceId]) {
                return (
                  <div className="w-4/5" key={voiceId}>
                    <AudioPlayer id={voiceId} src={attachments[voiceId]} />
                  </div>
                );
              }

              if (attachments[voiceId] === "error") {
                return (
                  <div className="flex-col flex columns items-start justify-center gap-1" key={voiceId}>
                    <span className="text-red-500 text-xs"> {`${t("errors.load")}`}</span>

                    <button
                      className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-xs px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      onClick={() => onLoadAttachment(voiceId)}
                    >
                      {`${t("button.tryAgain")}`}
                    </button>
                  </div>
                );
              }

              return (
                <div
                  className="w-24 h-24 bg-gray-300 rounded-2xl cursor-pointer flex items-center justify-center mx-auto "
                  onClick={() => onLoadAttachment(voiceId)}
                  key={voiceId}
                >
                  {`${t("button.load")}`}
                </div>
              );
            })}
        </div>

        <p className="text-black text-sm ml-6 mt-2">{message.data}</p>
      </div>
    </div>
  );
};

export default Message;
