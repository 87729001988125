import { createReducer } from "@reduxjs/toolkit";
import { UserActivityStatus } from "entities/user";
import { fetchCompany, updateCompany } from "./actions";
import { StoreState } from "./types";

const initialState: StoreState = {
  name: "",
  description: "",
  settings: {
    operatorFirstMessage: "",
    initUserStatus: UserActivityStatus.ACTIVE,
    version: 3,
  },
  loading: false,
};

const companyReducer = createReducer(initialState, builder => {
  builder
    .addCase(fetchCompany.pending, state => {
      state.loading = true;
    })
    .addCase(fetchCompany.fulfilled, (state, action) => {
      const { name, description, settings } = action.payload;
      state.name = name;
      state.description = description;
      state.settings = settings;
      state.loading = false;
    })
    .addCase(fetchCompany.rejected, state => {
      state.loading = false;
    })
    .addCase(updateCompany.fulfilled, (state, action) => {
      const { name, description, settings } = action.payload;
      state.name = name;
      state.description = description;
      state.settings = settings;
    });
});

export default companyReducer;
