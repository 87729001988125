/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWebSocket } from "contexts/WebsocketProvider";
import { uint8ArrayToJson } from "utils/utils";
import { useAppSelector } from "utils/hooks";
import { selectSession } from "store/auth/selectors";
import { ElapsedTime } from "components";

const AdminChats = ({
  fetchChatData,
  filteredChats,
  pageSize,
  setLoading,
  selectedUser,
  setSelectedUser,
  setUserId,
  formatDateToMoscowTime,
  loading,
}) => {
  const { t } = useTranslation("omnichannel");
  const [adminChatData, setAdminChatData] = useState([]);
  const [adminPage, setAdminPage] = useState(1);
  const [hasMoreAdminChats, setHasMoreAdminChats] = useState(false);
  const session = useAppSelector(selectSession);
  const ws = useWebSocket();

  const fetchAdminChatData = useCallback(async () => {
    try {
      const adminChatData = await fetchChatData("assigned", 1);
      setAdminChatData(adminChatData);
      setHasMoreAdminChats(adminChatData.length > pageSize);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching admin chat data:", error);
    }
  }, [fetchChatData, pageSize, setLoading]);

  const fetchNextPage = async () => {
    try {
      const nextPageChatData = await fetchChatData("assigned", adminPage + 1);
      setAdminPage(prevPage => prevPage + 1);
      setAdminChatData(nextPageChatData);
      setHasMoreAdminChats(nextPageChatData.length > pageSize);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPreviousPage = async () => {
    try {
      const nextPageChatData = await fetchChatData("assigned", adminPage - 1);
      setAdminPage(prevPage => prevPage - 1);
      setAdminChatData(nextPageChatData);
      setHasMoreAdminChats(nextPageChatData.length > pageSize);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (ws) {
      const newUserChatStatusListener = event => {
        const newChatStatus = uint8ArrayToJson(event);

        if (newChatStatus.email === session.user.email && newChatStatus.status === "resolved") {
          const newChatData = adminChatData.filter(
            item => item.backend_user_id !== parseInt(newChatStatus.backend_user_id, 10),
          );
          setAdminChatData(newChatData);
        }

        if (newChatStatus.email === session.user.email && newChatStatus.status === "assigned") {
          const exists = adminChatData.find(item => item.backend_user_id === newChatStatus.backend_user_id);

          if (!exists) {
            setAdminChatData(prevChatData => [
              ...prevChatData,
              {
                backend_user_id: newChatStatus.backend_user_id,
                frontend_user_id: newChatStatus.frontend_user_id,
                last_message: newChatStatus.reason_for_admin,
                phone: newChatStatus.phone,
                main_topic: null,
                sub_topic: null,
                timestamp: Date.now(),
                last_message_type: "bot",
                status: "bot",
              },
            ]);
          }
        }
      };

      const newMessageListener = event => {
        const newMessage = uint8ArrayToJson(event);

        const updatedChatData = adminChatData.map(user => {
          if (user.backend_user_id === newMessage.backend_user_id) {
            return {
              ...user,
              last_message: newMessage.data,
              timestamp: newMessage.timestamp,
              type: newMessage.type,
              last_message_type: newMessage.type,
            };
          }

          return user;
        });

        setAdminChatData(updatedChatData);
      };

      const newUserPhoneListener = event => {
        const newPhone = uint8ArrayToJson(event);

        setAdminChatData(prevChatData =>
          prevChatData.map(chat =>
            String(chat.backend_user_id) === newPhone.backend_user_id ? { ...chat, phone: newPhone.phone } : chat,
          ),
        );
      };

      const userChatReassignListener = event => {
        const newChat = uint8ArrayToJson(event);

        if (newChat.assigned_admin === session.user.id) {
          const exists = adminChatData.find(item => item.backend_user_id === newChat.backend_user_id);

          if (!exists) {
            setAdminChatData(prevChatData => [
              ...prevChatData,
              {
                backend_user_id: newChat.backend_user_id,
                frontend_user_id: newChat.frontend_user_id,
                last_message: newChat.reason_for_admin,
                phone: newChat.phone,
                main_topic: null,
                sub_topic: null,
                timestamp: Date.now(),
                last_message_type: "bot",
                status: "bot",
              },
            ]);
          }
        }
      };

      ws.on("new_user_chat_status", newUserChatStatusListener);
      ws.on("new_message", newMessageListener);
      ws.on("new_user_phone", newUserPhoneListener);
      ws.on("user_chat_reassign", userChatReassignListener);

      return () => {
        ws.off("new_user_chat_status", newUserChatStatusListener);
        ws.off("new_message", newMessageListener);
        ws.off("new_user_phone", newUserPhoneListener);
        ws.off("user_chat_reassign", userChatReassignListener);
      };
    }

    return () => {};
  }, [ws, adminChatData, session]);

  useEffect(() => {
    fetchAdminChatData();

    const intervalId = setInterval(() => {
      fetchAdminChatData();
    }, 15000);

    return () => clearInterval(intervalId);
  }, [fetchAdminChatData]);

  return (
    <div>
      {loading ? (
        <div className="p-3 rounded-lg bg-gray-100 animate-pulse">
          <div className="h-5 bg-gray-200 rounded" />
          <div className="mt-2 h-4 bg-gray-300 rounded w-3/4" />
        </div>
      ) : (
        <div className="flex flex-col">
          {filteredChats([...adminChatData])
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map(chat => (
              <div
                key={chat.frontend_user_id}
                className={`relative flex px-2 py-4 space-x-4 rounded-lg cursor-pointer border border-blue-200 ${
                  selectedUser === chat.backend_user_id ? "bg-gradient-to-r from-blue-100 to-blue-50" : "bg-transparent"
                } hover:bg-gradient-to-r hover:from-blue-100 hover:to-blue-50 transition ease-in-out duration-300 mb-4`}
                onClick={() => {
                  setSelectedUser(chat.backend_user_id);
                  setUserId(chat.frontend_user_id);
                }}
              >
                <div className="grid gap-4 grid-cols-12 w-full items-center">
                  <div className="flex flex-col col-span-4">
                    <div className="ml-4">
                      {chat.phone ? chat.phone : `${t("chatList.client")} ${chat.backend_user_id}`}
                    </div>
                    {(chat.last_message_type === "user" || chat.last_message_type === "bot") && (
                      <div className="ml-4">
                        <ElapsedTime timestamp={chat.timestamp} />
                      </div>
                    )}
                  </div>

                  {!selectedUser && (
                    <div className="text-gray-400 text-sm mx-4 col-span-3 truncate whitespace-nowrap">
                      {formatDateToMoscowTime(chat.timestamp)}
                    </div>
                  )}

                  {!selectedUser && (
                    <div className="flex-end text-gray-600 text-right col-span-5 text-sm">
                      {t("chatList.topic")} {chat.main_topic ? chat.main_topic : "---"}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {adminPage > 1 && (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 shadow-md"
          onClick={fetchPreviousPage}
          disabled={false}
        >
          предыдущая
        </button>
      )}
      {hasMoreAdminChats && (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 shadow-md"
          onClick={fetchNextPage}
          disabled={false}
        >
          следующая
        </button>
      )}
    </div>
  );
};

export default AdminChats;
