import React from "react";
import KBFile from "./KBFile";
import KBDescription from "./KBDescription";

// eslint-disable-next-line react/prop-types
const KBInterface = ({ selectedFile, selectedFileId, description, data, setData, setSelectedFileId }) => (
  <div>
    {selectedFile && (
      <KBFile
        selectedFile={selectedFile}
        selectedFileId={selectedFileId}
        data={data}
        setData={setData}
        setSelectedFileId={setSelectedFileId}
      />
    )}
    {description && <KBDescription description={description} data={data} />}
  </div>
);

export default KBInterface;
