import { createClient } from "@supabase/supabase-js";
import { host } from "../constants";

const URL =
  host === "https://api.rtno.ru/" ? process.env.REACT_APP_SUPABASE_URL : process.env.REACT_APP_SUPABASE_URL_TEST;
const ANON_KEY =
  host === "https://api.rtno.ru/"
    ? process.env.REACT_APP_SUPABASE_ANON_KEY
    : process.env.REACT_APP_SUPABASE_ANON_KEY_TEST;

// eslint-disable-next-line import/prefer-default-export
export const supabase = createClient(URL, ANON_KEY);
