import axios from "utils/axios";

export type UpdateUserInfoRequest = {
  name: string;
  lastName: string;
  userId: string;
};

const updateUserInfo = async ({ userId, name, lastName }: UpdateUserInfoRequest) => {
  const params = {
    frontend_uuid: userId,
    first_name: name,
    last_name: lastName,
  };

  await axios.put(`api/v1/team/change_operator_name/`, params);
};

export default updateUserInfo;
