/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Header = ({ setRange }) => {
  const { t } = useTranslation("analytics");

  const dateRanges = [
    { label: t("header.24hours"), value: "1" },
    { label: t("header.1week"), value: "7" },
    { label: t("header.2weeks"), value: "14" },
    { label: t("header.1month"), value: "30" },
  ];

  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[1].value);

  const handleDateRangeChange = event => {
    setRange(event.target.value);
    setSelectedDateRange(event.target.value);
  };

  return (
    <div>
      <div className="flex flex-col justify-start mb-2 w-1/2">
        <h2 className="text-xl ml-4 mt-4 font-bold">{t("header.headerName")}</h2>
        <div className="relative ml-4 mt-4 w-56 flex items-center">
          <select
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            value={selectedDateRange}
            onChange={handleDateRangeChange}
          >
            {dateRanges.map(range => (
              <option key={range.value} value={range.value}>
                {t(range.label)}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <hr className="mb-4 px-4" />
    </div>
  );
};

export default Header;
