import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Session, User } from "@supabase/supabase-js";
import Cookies from "js-cookie";
import { getAccessToken, setAuthTokens, clearAuthTokens, setRequestData } from "utils/auth";
import { supabase } from "supabase/supabase";
import { AuthRequestData } from "entities/auth";
import createCompanyId from "utils/createCompanyId";
import { UserRole } from "entities/user";
import { AsyncThunkConfig } from "utils/redux";
import { host } from "../../constants";
import { selectInitData } from "./selectors";

type CheckAuthPayload = {
  isAuthorized: boolean;
  session: Session | null;
  userId?: string;
  companyId?: string;
};

const validateCompany = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  "auth/validateCompany",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { userId, companyId } = selectInitData(getState());

      if (!userId || !companyId) {
        return rejectWithValue(new Error("Пользователь или компания не найдены"));
      }

      const url = `${host}api/v1/config/validate_company/`;

      const headers = {
        "RTNO-COMPANY-ID": companyId,
        "RTNO-ADMIN-UUID": userId,
        "Content-Type": "application/json",
      };

      const response = await axios.get(url, { headers });
      const { valid } = response.data;

      return valid;
    } catch (e) {
      const error = new Error("Упс! Произошла ошибка во время загрузки пользователя. Попробуйте позднее.");
      return rejectWithValue(error);
    }
  },
);

const checkAuth = createAsyncThunk<
  CheckAuthPayload,
  undefined,
  {
    rejectValue?: string;
  }
>("auth/checkAuth", async (_, { rejectWithValue }) => {
  try {
    const { data } = await supabase.auth.getSession();
    const { session } = data;

    // ! TODO: need to remove userId && companyId from state and actions after backend refactoring
    // This is not only about check auth, but also about company. It is will be moved to company actions.
    // I would not separate it from checkAuth now, cause we need to make sure that companyId exists before we validate the company

    if (session) {
      const currentToken = getAccessToken();

      if (session.access_token !== currentToken) {
        setAuthTokens(session.access_token, session.refresh_token);
      }

      const result = await supabase.from("users").select("company_name").eq("user_id", session.user.id);

      if (!result.data || !result.data.length) {
        return rejectWithValue(new Error("Компания не найдена"));
      }
      const companyId = result.data[0].company_name;

      setRequestData(companyId, session.user.id);

      return { isAuthorized: true, session, userId: session.user.id, companyId };
    }

    clearAuthTokens();
    return { isAuthorized: false, session: null };
  } catch (e) {
    const error = new Error("Упс! Произошла ошибка во время проверки авторизации. Попробуйте позднее.");
    return rejectWithValue(error);
  }
});

const register = createAsyncThunk<
  undefined,
  AuthRequestData,
  {
    rejectValue?: string;
  }
>("auth/register", async (userData, { dispatch, rejectWithValue }) => {
  try {
    const { error, data } = await supabase.auth.signUp(userData);

    if (error) {
      return rejectWithValue(error.message);
    }

    const { email, id } = data.user as User;
    const companyId = createCompanyId(id);

    await supabase.from("companies").insert({
      id,
      version: 3,
      company_name: companyId,
    });

    const { error: supabaseError } = await supabase.from("users").insert({
      user_id: id,
      company_id: id,
      role: UserRole.ADMIN,
      company_name: companyId,
      email,
      first_name: "Поменяй",
      last_name: "Меня",
    });

    if (supabaseError) {
      return rejectWithValue(new Error(`Error inserting into users table: ${supabaseError.message}`));
    }

    await dispatch(checkAuth());
    return undefined;
  } catch (e) {
    const error = new Error("Произошла ошибка при регистрации. Попробуйте позднее или обратитесь к сотруднику RTNO.");
    return rejectWithValue(error);
  }
});

type RegisterThroughLinkRequestData = {
  role: UserRole;
  name: string;
  lastName: string;
  email: string;
  password: string;
  companyId: string;
  masterId: string;
};

// ! Where is setting role???

const registerThroughLink = createAsyncThunk<
  undefined,
  RegisterThroughLinkRequestData,
  {
    rejectValue?: string;
  }
>("auth/registerThroughLink", async (userData, { dispatch, rejectWithValue }) => {
  try {
    const { error, data } = await supabase.auth.signUp(userData);

    if (error) {
      return rejectWithValue(error.message);
    }

    const newUserId = data?.user?.id;

    if (!newUserId) {
      return rejectWithValue("Пользователь не найден");
    }

    await supabase
      .from("users")
      .update({
        user_id: newUserId,
        first_name: "Поменяй",
        last_name: "Меня",
      })
      .eq("email", userData.email);

    const url = `${host}api/v1/team/set_admin_uuid/`;

    const params = {
      frontend_uuid: newUserId,
      email: userData.email,
      first_name: userData.name,
      last_name: userData.lastName,
    };

    const config = {
      headers: {
        "RTNO-COMPANY-ID": userData.companyId,
      },
    };

    await axios.put(url, params, config);

    await dispatch(checkAuth());
    return undefined;
  } catch (e) {
    const error = new Error("Произошла ошибка при регистрации. Попробуйте позднее или обратитесь к сотруднику RTNO.");
    return rejectWithValue(error);
  }
});

const login = createAsyncThunk<
  undefined,
  AuthRequestData,
  {
    rejectValue?: string;
  }
>("auth/login", async (userData, { dispatch, rejectWithValue }) => {
  try {
    const { error } = await supabase.auth.signInWithPassword(userData);

    if (error) {
      return rejectWithValue(error?.message);
    }

    await dispatch(checkAuth());
    return undefined;
  } catch (e) {
    const error = new Error("Неправильный логин или пароль");
    return rejectWithValue(error);
  }
});

const logout = createAsyncThunk("auth/logout", async () => {
  clearAuthTokens();
  Cookies.remove("status");
  Cookies.remove("companyId");
  Cookies.remove("userId");

  window.localStorage.clear();
  window.location.href = "/signin";
  await supabase.auth.signOut();
});

export { checkAuth, logout, login, register, validateCompany, registerThroughLink };
