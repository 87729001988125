import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "utils/hooks";
import { selectCompanyLoading } from "store/company/selectors";
import { selectIsCompanyValid } from "store/auth/selectors";
import { selectUser } from "store/user/selectors";
import { Loading } from "components";

type Props = {
  children: JSX.Element;
};

const RequireAvailableCompany = ({ children }: Props) => {
  const isCompanyLoading = useAppSelector(selectCompanyLoading);
  const isCompanyValid = useAppSelector(selectIsCompanyValid);
  const { role } = useAppSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (isCompanyValid || isCompanyValid === undefined) return;

    if (role === "admin") navigate("/settings");
    else navigate("/profile");
  }, [isCompanyValid, navigate, role]);

  // Todo: move company check to the InitApp component after backend refactoring

  if (isCompanyLoading) {
    return <Loading />;
  }

  // Todo: remove isCompanyValid from the condition after backend refactoring

  if (!isCompanyValid) {
    return null;
  }

  return children;
};

export default RequireAvailableCompany;
