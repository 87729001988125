import axios from "utils/axios";

type UpdateExtraSettingsRequest = {
  companyName: string;
  needPhone: boolean;
  memoryTimeout: number;
  needResolveVerification: boolean;
  resolveVerificationTimeout: number | null;
};

const updateExtraSettings = async (data: UpdateExtraSettingsRequest) => {
  const url = `api/v1/config/update/`;

  return axios.put(url, data);
};

export default updateExtraSettings;
