import React, { useCallback, useEffect, useState } from "react";
import MonitoringMain from "./MonitoringMain";
import MonitoringChatInterface from "./ChatInterface";
import MonitoringChatInfo from "./ChatInfo";
import MonitoringSideBar from "./Sidebar";
import { useWebSocket } from "../../contexts/WebsocketProvider";

const MonitoringPage = () => {
  const ws = useWebSocket();
  const [selectedUser, setSelectedUser] = useState(null);
  const [chats, setChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    description: "",
  });
  const [keys, setKeys] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [queueData, setQueueData] = useState([]);

  const handleTeamChangeOnReassign = useCallback(
    event => {
      const uint8Array = new Uint8Array(event);
      const stringData = new TextDecoder().decode(uint8Array);
      const newStatus = JSON.parse(stringData);

      const memberIndex = teamMembers.findIndex(member => member.frontend_uuid === newStatus.assigned_admin);

      if (memberIndex !== -1) {
        const updatedMembers = [...teamMembers];
        updatedMembers[memberIndex].active_chats += 1;
        updatedMembers[memberIndex].assigned_chats += 1;

        setTeamMembers(updatedMembers);
      }
    },
    [teamMembers],
  );

  const handleQueueChangeOnReassign = useCallback(
    event => {
      const uint8Array = new Uint8Array(event);
      const stringData = new TextDecoder().decode(uint8Array);
      const newStatus = JSON.parse(stringData);

      const newQueueData = queueData.filter(chat => newStatus.backend_user_id !== String(chat.backend_user_id));

      setQueueData(newQueueData);
    },
    [queueData],
  );

  const handleTeamChangeOnNewStatus = useCallback(
    event => {
      const uint8Array = new Uint8Array(event);
      const stringData = new TextDecoder().decode(uint8Array);
      const newStatus = JSON.parse(stringData);

      const memberIndex = teamMembers.findIndex(member => member.email === newStatus.email);
      if (memberIndex !== -1) {
        const updatedMembers = [...teamMembers];

        if (newStatus.status === "assigned") {
          updatedMembers[memberIndex].active_chats += 1;
          updatedMembers[memberIndex].assigned_chats += 1;
        } else if (newStatus.status === "resolved") {
          updatedMembers[memberIndex].active_chats = Math.max(0, updatedMembers[memberIndex].active_chats - 1);
          updatedMembers[memberIndex].assigned_chats = Math.max(0, updatedMembers[memberIndex].assigned_chats - 1);
        }

        setTeamMembers(updatedMembers);
      }
    },
    [teamMembers],
  );

  const handleQueueChangeOnNewStatus = useCallback(
    event => {
      const uint8Array = new Uint8Array(event);
      const stringData = new TextDecoder().decode(uint8Array);
      const newStatus = JSON.parse(stringData);

      if (newStatus.status === "assigned") {
        const userAlreadyInQueue = queueData.some(user => user.backend_user_id === newStatus.backend_user_id);

        if (!userAlreadyInQueue && newStatus.email === null) {
          setQueueData(prevQueue => [{ ...newStatus, timestamp: Date.now() }, ...prevQueue]);
        }
      }
    },
    [queueData],
  );

  const handleNewStatus = useCallback(
    event => {
      handleQueueChangeOnNewStatus(event);
      handleTeamChangeOnNewStatus(event);
    },
    [handleQueueChangeOnNewStatus, handleTeamChangeOnNewStatus],
  );

  const handleReassign = useCallback(
    event => {
      handleTeamChangeOnReassign(event);
      handleQueueChangeOnReassign(event);
    },
    [handleQueueChangeOnReassign, handleTeamChangeOnReassign],
  );

  useEffect(() => {
    if (ws) {
      ws.on("new_user_chat_status", handleNewStatus);
      ws.on("user_chat_reassign", handleReassign);

      return () => {
        ws.off("new_user_chat_status", handleNewStatus);
        ws.off("user_chat_reassign", handleReassign);
      };
    }

    return () => {};
  }, [handleNewStatus, handleReassign, ws]);

  return (
    <div className="flex flex-col w-full p-4 rounded-lg shadow-sm">
      <div className="flex">
        <div className={selectedUser ? "w-1/4 mr-4" : "w-[70%]"}>
          <MonitoringMain
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            teamMembers={teamMembers}
            setTeamMembers={setTeamMembers}
          />
        </div>
        {!selectedUser && (
          <div className="w-[30%]">
            <MonitoringSideBar
              teamMembers={teamMembers}
              setTeamMembers={setTeamMembers}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              queueData={queueData}
              setQueueData={setQueueData}
            />
          </div>
        )}
        {selectedUser && (
          <div className="w-2/4">
            <MonitoringChatInterface
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              chats={chats}
              setChats={setChats}
              setActiveChatId={setActiveChatId}
              activeChatId={activeChatId}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setKeys={setKeys}
            />
          </div>
        )}
        {selectedUser ? (
          <div className="w-1/4">
            <MonitoringChatInfo
              selectedUser={selectedUser}
              keys={keys}
              chats={chats}
              setUserInfo={setUserInfo}
              setActiveChatId={setActiveChatId}
              activeChatId={activeChatId}
              userInfo={userInfo}
              teamMembers={teamMembers}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MonitoringPage;
