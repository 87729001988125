import axios from "utils/axios";

const getTelegramKey = async () => {
  const params = {
    object_name_list: ["tg_api_key"],
  };

  return axios.get(`api/v1/config/load/`, { params });
};

export default getTelegramKey;
