/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar } from "components";
import { MdCreate, MdHome, MdLogin } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { selectIsAuthorized, selectSession } from "store/auth/selectors";
import { logout } from "store/auth/actions";
import { selectActiveMenu } from "store/layout/selectors";
import Sidebar from "./Sidebar";

const PublicLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const session = useAppSelector(selectSession);
  const { t } = useTranslation();
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const activeMenu = useAppSelector(selectActiveMenu);

  const sidebarRoutes = useMemo(
    () => [
      {
        to: "https://chat.rtno.ru",
        icon: MdHome,
        label: t("sidebar:label.home"),
        access: true,
      },
      {
        to: "/signin",
        icon: MdLogin,
        label: t("sidebar:label.signin"),
        access: true,
      },
      {
        to: "/signup",
        icon: MdCreate,
        label: t("sidebar:label.signup"),
        access: true,
      },
    ],
    [t],
  );

  useEffect(() => {
    if (isAuthorized) {
      console.log("ya tut");
      navigate("/dashboard");
    }
  }, [isAuthorized, navigate]);

  const handleLogout = useCallback(async () => {
    dispatch(logout());
  }, [dispatch]);

  if (session) return null;

  return (
    <div className="flex dark:bg-main-dark-bg">
      <div
        className={`fixed sidebar dark:bg-secondary-dark-bg bg-main-bg w-60 transform transition-transform ease-in-out ${
          activeMenu ? "translate-x-0" : "-translate-x-full"
        } duration-300`}
      >
        <Sidebar routes={sidebarRoutes} />
      </div>
      <div
        className={`dark:bg-main-bg bg-main-bg min-h-screen ${
          activeMenu ? "w-[calc(100%-1rem)] md:ml-60 overflow-x-hidden " : "w-full "
        }`}
      >
        <div className="fixed md:static bg-main-bg dark:-bg-main-dark-bg navbar w-full">
          <Navbar handleLogout={handleLogout} />
        </div>

        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
