import React from "react";
import TeamInvite from "./TeamInvite";
import TeamBottom from "./TeamBottom";

const TeamPage = () => (
  <div className="flex flex-col h-full w-full p-4">
    <div className="flex">
      <h2 className="text-xl mr-2 mb-2 font-bold">Команда</h2>
      <TeamInvite />
    </div>
    <hr className="mb-4" />

    <TeamBottom />
  </div>
);

export default TeamPage;
