/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { MdTopic } from "react-icons/md";
import axios from "axios";
import { host } from "constants";
import { useWebSocket } from "contexts/WebsocketProvider";
import { useAppSelector } from "utils/hooks";
import { selectInitData } from "store/auth/selectors";
import { selectKB } from "store/knowledgeBase/selectors";

const UserTopic = ({ openCards, toggleCard, activeChatId, chats }) => {
  const { t } = useTranslation("omnichannel");
  const ws = useWebSocket();
  const { companyId, userId } = useAppSelector(selectInitData);
  const [mainTopic, setMainTopic] = useState("");
  const [subTopic, setSubTopic] = useState("");
  const { topics } = useAppSelector(selectKB);

  const updateChatTopic = async () => {
    const url = `${host}api/v1/dashboard/input_user_chat_topic/`;
    const headers = {
      "RTNO-COMPANY-ID": companyId,
      "RTNO-ADMIN-UUID": userId,
    };
    const params = {
      user_chat_id: activeChatId,
      main_topic: mainTopic,
      sub_topic: subTopic,
    };
    try {
      // TODO: Should we finish this task?
      await axios.put(url, {}, { headers, params });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (ws) {
      const newUserChatTopicListener = event => {
        const uint8Array = new Uint8Array(event);
        const stringData = new TextDecoder().decode(uint8Array);
        const newTopic = JSON.parse(stringData);

        if (activeChatId === newTopic.user_chat_id) {
          setMainTopic(newTopic.main_topic);
          setSubTopic(newTopic.sub_topic);
        }
      };

      ws.on("new_user_chat_topic", newUserChatTopicListener);

      return () => {
        ws.off("new_user_chat_topic", newUserChatTopicListener);
      };
    }

    return () => {};
  }, [activeChatId, ws]);

  useEffect(() => {
    if (chats) {
      const activeChat = chats.find(chat => chat.user_chat_id === activeChatId);

      setMainTopic(activeChat?.mainTopic || "");
      setSubTopic(activeChat?.sub_topic || "");
    }
  }, [activeChatId, chats]);

  return (
    <div className="flex flex-col p-3 rounded-lg  border border-blue-200  transition ease-in-out duration-150 group">
      {openCards.includes(4) ? (
        <div className="flex flex-col space-y-3 py-3">
          <div className="flex items-center space-x-2">
            <div onClick={() => toggleCard(4)}>
              <FaCaretUp className="cursor-pointer" />
            </div>
            <MdTopic className="group-hover:text-main-blue transition duration-300 ease-in-out" />
            <div>{t("chatInfo.topics.topic")}</div>
          </div>
          <select
            className="border text-sm border-blue-100 hover:bg-gray-200 focus:bg-blue-100 mb-3 rounded p-1 bg-transparent focus:outline-none transition duration-150 ease-in-out"
            value={mainTopic}
            onChange={e => setMainTopic(e.target.value)}
          >
            <option value="" disabled>
              {t("chatInfo.topics.selectMainTopic")}
            </option>
            {Object.keys(topics).map(mainTopic => (
              <option key={mainTopic} value={mainTopic}>
                {mainTopic}
              </option>
            ))}
          </select>
          {mainTopic && (
            <select
              className="border text-sm border-blue-100 hover:bg-gray-200 focus:bg-blue-100 mb-3 rounded p-1 bg-transparent focus:outline-none transition duration-150 ease-in-out"
              value={subTopic}
              onChange={e => setSubTopic(e.target.value)}
            >
              <option value="" disabled>
                {t("chatInfo.topics.selectSubTopic")}
              </option>
              {topics[mainTopic] &&
                Object.keys(topics[mainTopic]).map(subTopic => {
                  if (subTopic === "_description") return null;

                  return (
                    <option key={subTopic} value={subTopic}>
                      {subTopic}
                    </option>
                  );
                })}
            </select>
          )}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={updateChatTopic}
            disabled={!mainTopic || !subTopic}
          >
            {t("chatInfo.topics.send")}
          </button>
        </div>
      ) : (
        <div onClick={() => toggleCard(4)} className="flex items-center py-3 space-x-2 cursor-pointer">
          <div>
            <FaCaretDown className="cursor-pointer" />
          </div>
          <MdTopic className="group-hover:text-main-blue transition duration-300 ease-in-out" />
          <div>{t("chatInfo.topics.topic")}</div>
        </div>
      )}
    </div>
  );
};

export default UserTopic;
