import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons/lib";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { toggleActiveMenu } from "store/layout/actions";
import { selectActiveMenu, selectScreenSize } from "store/layout/selectors";

type Route = {
  to: string;
  label: string;
  icon: IconType;
  access: boolean;
};

type Props = {
  routes: Route[];
};

const Sidebar = ({ routes }: Props) => {
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(selectActiveMenu);
  const screenSize = useAppSelector(selectScreenSize);
  const { t } = useTranslation("sidebar");
  const location = useLocation();

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize && screenSize <= 900) {
      dispatch(toggleActiveMenu(false));
    }
  };

  return (
    <div className=" h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 flex flex-col justify-between shadow-md ease-in-out duration-600 ">
      {activeMenu && (
        <div className="ml-3 mt-4 z-[6000]">
          <div className="flex justify-between items-center ">
            <NavLink
              to="/dashboard"
              onClick={handleCloseSideBar}
              className="items-center ml-3  flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <span className="text-blue-light-gradient mt-1">RTNO</span>
            </NavLink>
            <div
              className="cursor-pointer text-blue-500 -mt-0.5 -ml-4 hover:text-white hover:rounded hover:bg-blue-500 mr-4 transition ease-in-out duration-300"
              onClick={() => dispatch(toggleActiveMenu(!activeMenu))}
            >
              <BsFillArrowLeftSquareFill size={25} />
            </div>
          </div>

          <div className="mt-10">
            {routes.map(link => {
              if (!link.access) return null;

              return (
                <NavLink
                  key={link.to}
                  to={link.to}
                  onClick={handleCloseSideBar}
                  className="text-xl font-medium text-gray-700 hover:text-gray-900 py-2 px-4 block flex items-center group"
                >
                  <link.icon
                    className={`mr-2 ease-in-out duration-300 group-hover:text-main-blue ${
                      location.pathname === link.to ? "text-main-blue" : ""
                    }`}
                  />
                  {t(link.label)}
                </NavLink>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
